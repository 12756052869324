import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { lighten } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import TotemIcon from './icon';

const TotemSearchBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.primaryColor};
  padding: 36px;
`;

const SearchTitleLabel = styled.h4`
  font-size: ${(props) => props.theme.fontSize.h4};
  color: white;
`;

const SearchInputContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

const SearchIconWrapper = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;

  cursor: pointer;
  color: white;
  -webkit-text-fill-color: white;
`;

const SearchInputItem = styled.input`
  flex: 1;
  font-size: 32px;
  padding: 22px 24px;

  &:focus-visible {
    outline-color: ${(props) => lighten(props.theme.primaryColor, 0.4)};
    outline-style: auto;
    outline-width: 1px;
  }
`;

const TotemSearchBar = ({
  className,
  searchTitle,
  onSearchInputChangeHandler,
  onPerformSearchClickHandler,
  searchValue,
  onInputFocusHandler,
}) => {
  const { t } = useTranslation('common');

  const onKeyUp = useCallback(
    (ev) => {
      if (ev.key === 'Enter') {
        onPerformSearchClickHandler(ev);
      }
    },
    [onPerformSearchClickHandler],
  );

  return (
    <TotemSearchBarContainer className={className}>
      <SearchTitleLabel>{searchTitle}</SearchTitleLabel>
      <SearchInputContainer>
        <SearchInputItem
          type="text"
          onKeyPress={onKeyUp}
          placeholder={t('pages.searchPlaceHolder')}
          onChange={onSearchInputChangeHandler}
          value={searchValue}
          onClick={onInputFocusHandler}
        />
        <SearchIconWrapper
          onClick={onPerformSearchClickHandler}
        >
          <TotemIcon icon="Cerca" size="50px" />
        </SearchIconWrapper>
      </SearchInputContainer>
    </TotemSearchBarContainer>
  );
};

TotemSearchBar.propTypes = {
  searchTitle: PropTypes.string,
  onSearchInputChangeHandler: PropTypes.func,
  onPerformSearchClickHandler: PropTypes.func,
  onInputFocusHandler: PropTypes.func,
  searchValue: PropTypes.string,
  className: PropTypes.string,
};

TotemSearchBar.defaultProps = {
  searchTitle: '',
  onSearchInputChangeHandler: null,
  onPerformSearchClickHandler: null,
  onInputFocusHandler: null,
  searchValue: '',
  className: '',
};

export default TotemSearchBar;
