import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TotemIcon from './icon';
import { H3 } from './headings';
import { configStateSelector, updateUtilsApiError } from '../store/totem-config-slice';
import getTotemWeather from '../common/weather-api';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 600px;
  filter: ${(props) => (props.$screenSaverActive ? 'brightness(0.1)' : 'unset')};
`;

const ContainerFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FirstRowContainer = styled.div`
  display: flex;
`;

const HeaderLeftColumn = styled(ContainerFlexColumn)`
  width: 1560px;
`;

const HeaderRightColumn = styled(ContainerFlexColumn)`
  width: 100%;
`;

const WeatherContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  background-color: #F8F8F8;

  & > div{
    display: flex;
    flex-direction:column;
    align-items: center;
    row-gap: .5rem;
    height: 140px;

    &.opacity{
      opacity: .4;
    }
  }
`;

const WeatherItem = styled.div`
  opacity: ${(props) => props.$disabled && '.4'}
`;

const TotemSubtitle = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${(props) => props.theme.primaryBarColor};
  color: white;
  height: 100%;
  padding: 0 50px;
`;

const CapitalizedDate = styled(H3)`
  text-transform: capitalize;
  flex: 1;
`;

const MeteoItem = styled(H3)`
  flex: 1;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const icons = {
  '01d': 'Giorno-sereno',
  '02d': 'Giorno-Nuvoloso',
  '03d': 'Nuvoloso',
  '04d': 'Nuvoloso',
  '09d': 'Acquazzone',
  '10d': 'Acquazzone',
  '11d': 'Temporale',
  '13d': 'Giorno-neve',
  '50d': 'Nebbia',
  '01n': 'Notte-sereno',
  '02n': 'Notte-Nuvoloso',
  '03n': 'Nuvoloso',
  '04n': 'Notte-Nuvoloso',
  '09n': 'Acquazzone',
  '10n': 'Acquazzone',
  '11n': 'Temporale',
  '13n': 'Giorno-neve',
  '50n': 'Nebbia',
};

const hours = [8, 14, 20];

const fnc = async () => {
  const nowDt = Math.round(new Date().getTime() / 1000) - 1000;
  try {
    const [prev, curr] = await getTotemWeather(nowDt);
    const parsedData = { ...curr, hourly: [...prev.hourly, ...curr.hourly] };
    let weatherObject = null;
    parsedData.hourly.forEach((item) => {
      const fromUnixToDate = new Date(item.dt * 1000);
      const currentHour = fromUnixToDate.getHours();

      if (new Date().getHours() === currentHour) {
        weatherObject = item;
      }

      if (fromUnixToDate.toLocaleDateString() === new Date().toLocaleDateString()
        && hours.includes(currentHour)) {
        localStorage.setItem(currentHour, JSON.stringify(item));
      }
    });
    return weatherObject;
  } catch (err) {
    throw new Error('Meteo Server Error');
  }
};

const getFromStorage = (index) => {
  const item = JSON.parse(localStorage.getItem(index));
  return item ? item.weather[0].icon : undefined;
};

const getIconFromWeather = (itemWeather) => (
  itemWeather ? icons[itemWeather.weather[0].icon] : undefined
);

const getTempFromWeather = (itemWeather) => (
  itemWeather ? Math.round(itemWeather.temp) : NaN
);

const passedHours = (index) => {
  const item = JSON.parse(localStorage.getItem(index));
  if (!item) return true;
  const fromUnixToDate = new Date(item.dt * 1000);
  const currentHour = fromUnixToDate.getHours();
  return new Date().getHours() >= currentHour;
};

const TotemHeader = ({ screenSaverActive }) => {
  const totemConfig = useSelector(configStateSelector);
  const dispatch = useDispatch();
  const { banners } = totemConfig.editionTotemConfig;
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentWeather, setCurrentWeather] = useState(null);
  const { i18n } = useTranslation('common');
  const dateFormatter = {
    weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit',
  };
  const timeFormatter = { timeStyle: 'short' };

  useEffect(() => {
    const timerId = setInterval(
      () => {
        setCurrentTime(new Date());
      },
      60000,
    );
    return () => clearInterval(timerId);
  }, [currentTime, dateFormatter, i18n.language]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const localStorageTimer = setInterval(
      async () => {
        try {
          const res = await fnc();
          setCurrentWeather(res);
          dispatch(updateUtilsApiError(null));
        } catch (err) {
          console.log(err);
          dispatch(updateUtilsApiError('generic'));
        }
      },
      36 * 1000 * 100,
    );
    try {
      const res = await fnc();
      setCurrentWeather(res);
      dispatch(updateUtilsApiError(null));
    } catch (err) {
      console.log(err);
      dispatch(updateUtilsApiError('generic'));
    }

    return () => clearInterval(localStorageTimer);
  },
  [dispatch]);

  return (
    <HeaderContainer $screenSaverActive={screenSaverActive}>
      {banners && (
        <>
          <FirstRowContainer>
            <HeaderLeftColumn>
              <img src={banners.coverEditionImage} alt="cover edition img" />
            </HeaderLeftColumn>
            <HeaderRightColumn>
              <img src={banners.organizerImage} alt="organizer img" />
              <WeatherContainer>
                <WeatherItem $disabled={passedHours(8)}>
                  08:00
                  <TotemIcon icon={icons[getFromStorage('8')]} size="84px" />
                </WeatherItem>

                <WeatherItem $disabled={passedHours(14)}>
                  14:00
                  <TotemIcon icon={icons[getFromStorage('14')]} size="84px" />
                </WeatherItem>

                <WeatherItem>
                  20:00
                  <TotemIcon icon={icons[getFromStorage('20')]} size="84px" />
                </WeatherItem>
              </WeatherContainer>
            </HeaderRightColumn>
          </FirstRowContainer>
          <TotemSubtitle>
            <CapitalizedDate>
              {currentTime.toLocaleDateString(i18n.language, dateFormatter)}
            </CapitalizedDate>
            <H3>
              {currentTime.toLocaleTimeString(i18n.language, timeFormatter)}
            </H3>
            {
              currentWeather && (
                <MeteoItem>
                  {`${getTempFromWeather(currentWeather)}° `}
                  <TotemIcon size="84px" icon={getIconFromWeather(currentWeather)} />
                </MeteoItem>
              )
            }
          </TotemSubtitle>
        </>
      )}
    </HeaderContainer>
  );
};

TotemHeader.propTypes = {
  screenSaverActive: PropTypes.bool.isRequired,
};

TotemHeader.defaultProps = {};

export default TotemHeader;
