import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { configurationStateSelector } from '../store/totem-configuration-slice';
import useTotemCustomSelector from '../common/use-totem-selector';
import InfoCardButton from '../components/info-card-button';
import { H2 } from '../components/headings';
import TotemLeftPanel from '../components/totem-left-panel';
import TotemRightPanel from '../components/totem-right-panel';

const HomeContainer = styled.div`
  display: flex;
`;

const Home = ({ accessibilityActive }) => {
  const { t, i18n } = useTranslation('home-pages');
  const history = useHistory();
  const [exhibitorQuery, setExhibitorQuery] = useState('');

  // const cardButtonHandler = useCallback((index) => {
  //   history.push(`${history.location.pathname}/detail/${index}`);
  // }, [history]);

  return (
    <HomeContainer>
      <TotemLeftPanel accessibilityActive={accessibilityActive}>
        <H2 />
      </TotemLeftPanel>
      <TotemRightPanel
        accessibilityActive={accessibilityActive}
        showSearchBar
        searchTitle="Ricerca qui"
        onSearchInputChangeHandler={(ev) => setExhibitorQuery(ev.target.value)}
        onPerformSearchClickHandler={console.log}
        searchValue={exhibitorQuery}
      >
        <H2 />
      </TotemRightPanel>
    </HomeContainer>
  );
};

Home.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
};

Home.defaultProps = {};

export default Home;
