/* global NODE_ENV */

import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: NODE_ENV === 'development' || NODE_ENV === 'staging',
    lng: 'it',
    fallbackLng: 'en', // use en if detected lng is not available
    ns: 'common',
    defaultNS: 'common',
    saveMissing: false, // send not translated keys to endpoint https://github.com/i18next/i18next-http-middleware#add-routes
    // keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: `${NODE_ENV === 'staging' ? '/frontend-framework-team/boilerplate-react' : ''}/locales/{{lng}}/{{ns}}.json`,
    },
  });

export default i18n;
