import { point } from '@turf/turf';

const getNewNavigationRoute = async (startPoint, endPoint, editionCode) => {
  const url = `${process.env.WAYFINDING_SERVER}/wayfinding/findway`;
  const timeout = 1000 * 5;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const body = {
    from: point([startPoint.longitude, startPoint.latitude], { floor: startPoint.floor }),
    to: point([endPoint.longitude, endPoint.latitude], { floor: endPoint.floor }),
  };

  const header = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-exhibition-id': editionCode,
    },
    signal: controller.signal,
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, header);
    clearTimeout(id);

    if (response.ok !== true) {
      console.log({ status: response.status, statusText: response.statusText });
      throw new Error({ status: response.status, statusText: response.statusText });
    }

    const parsedResponse = await response.json();

    return parsedResponse;
  } catch (err) {
    throw new Error(err);
  }
};

export default getNewNavigationRoute;
