import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import styled from 'styled-components';

import '../style/custom-swiper.scss';

SwiperCore.use([Autoplay, Navigation, Pagination]);

const SwiperStyled = styled(Swiper)`
  height: ${(props) => props.$contentHeight};
`;

const TotemSlider = ({
  slideComponent,
  slideItems,
  onSlideClick,
  navigationArrowVisible,
  delayAutoplay,
  allowTouchMove,
  sliderContentHeight,
}) => {
  const InsideComponent = slideComponent;
  const swiperInstance = useRef(null);

  const autoPlayConfig = {
    delay: delayAutoplay,
    disableOnInteraction: false,
  };

  useEffect(() => {
    if (swiperInstance.current) {
      swiperInstance.current.allowTouchMove = allowTouchMove;
      swiperInstance.current.update();
    }
  }, [allowTouchMove]);

  return (
    <SwiperStyled
      $contentHeight={sliderContentHeight}
      slidesPerView={1}
      loop
      navigation={navigationArrowVisible}
      pagination
      autoplay={slideItems.length === 1 ? false : autoPlayConfig}
      onInit={(swiper) => {
        swiperInstance.current = swiper;
      }}
      onClick={(swiper) => onSlideClick(slideItems[swiper.realIndex])}
    >
      {slideItems.length > 0
        && slideItems.map((item, arrKey) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlide style={{ textAlign: 'center' }} key={arrKey}>
            <InsideComponent item={item} />
          </SwiperSlide>
        ))}
    </SwiperStyled>
  );
};

TotemSlider.propTypes = {
  slideComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.elementType,
  ]).isRequired,
  slideItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  onSlideClick: PropTypes.func.isRequired,
  navigationArrowVisible: PropTypes.bool,
  delayAutoplay: PropTypes.number,
  allowTouchMove: PropTypes.bool,
  sliderContentHeight: PropTypes.string,
};

TotemSlider.defaultProps = {
  delayAutoplay: 5000,
  navigationArrowVisible: false,
  allowTouchMove: true,
  sliderContentHeight: 'auto',
};

export default TotemSlider;
