import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TotemIcon from './icon';
import theme from '../theme';

const TotemFilterCategoryButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-bottom: 40px;
  height: 125px;
  border: 2px solid ${theme.secondaryLineColor};
  border-radius: 4px;
  box-sizing: border-box;

  opacity: ${(props) => (props.$opacityActive ? '1' : '0.4')};
`;

const CssImageItem = styled.div`
  width: 125px;
  height: 125px;
  background-position: 50%;
  background-size: cover;
  background-image: url(${(props) => props.$imageUrl});
`;

const InfoFakeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 125px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  font-size: ${theme.fontSize.h2};
`;

const BoldParagraph = styled.p`
  flex: 1px;
  padding: 0 32px;
  font-weight: bold;
`;

const TotemFilterCategoryButton = ({
  categoryImg,
  title,
  onClickCategoryHandler,
  isActive,
  itemIndex,
}) => (
  <TotemFilterCategoryButtonContainer
    $opacityActive={isActive}
    onClick={() => onClickCategoryHandler(itemIndex)}
  >
    {!categoryImg && (
    <InfoFakeButton>
      <TotemIcon icon="Eventi" size="50px" />
    </InfoFakeButton>
    )}
    {categoryImg && <CssImageItem $imageUrl={categoryImg} />}
    <BoldParagraph>
      {title}
    </BoldParagraph>
  </TotemFilterCategoryButtonContainer>
);

TotemFilterCategoryButton.propTypes = {
  categoryImg: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClickCategoryHandler: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  itemIndex: PropTypes.string.isRequired,
};

TotemFilterCategoryButton.defaultProps = {
  categoryImg: null,
  isActive: true,
};

export default TotemFilterCategoryButton;
