import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import TotemLeftPanel from '../components/totem-left-panel';
import TotemRightPanel from '../components/totem-right-panel';
import HomepageExhibitorSelected from '../components/homepage-exhibitor-selected';
import TotemMaps from '../components/totem-maps';
import { configNotFilteredExhibitorsStateSelector } from '../store/totem-config-slice';

const HomeExhibitorContainer = styled.div`
  display: flex;
`;

const HomeExhibitor = ({ accessibilityActive }) => {
  const { exhibitorId } = useParams();
  const fullExhibitorsList = useSelector(configNotFilteredExhibitorsStateSelector, shallowEqual);

  const selectedExhibitor = useMemo(() => {
    const foundExhib = fullExhibitorsList.find(
      (item) => (
        item.id === exhibitorId || item.id === decodeURIComponent(exhibitorId)
      ),
    );
    if (foundExhib?.type === 'brand' && foundExhib?.associatedExhibitorId) {
      return foundExhib?.associatedExhibitorId;
    }
    return decodeURIComponent(exhibitorId);
  }, [exhibitorId, fullExhibitorsList]);

  return (
    <HomeExhibitorContainer>
      <TotemLeftPanel accessibilityActive={accessibilityActive}>
        <TotemMaps
          accessibilityActive={accessibilityActive}
          exhibitorDetail={selectedExhibitor}
          isServicesPage={false}
        />
      </TotemLeftPanel>
      <TotemRightPanel
        accessibilityActive={accessibilityActive}
        showSearchBar={false}
      >
        <HomepageExhibitorSelected />
      </TotemRightPanel>
    </HomeExhibitorContainer>
  );
};

HomeExhibitor.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
};

HomeExhibitor.defaultProps = {};

export default HomeExhibitor;
