import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TotemIcon from './icon';
import { H4 } from './headings';

const IconDetailHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  & > :nth-child(2) {
    margin-left: 15px;
  }
`;

const IconDetailHeader = ({ iconName, title }) => (
  <IconDetailHeaderContainer>
    <TotemIcon icon={iconName} size="50px" />
    <H4>{title}</H4>
  </IconDetailHeaderContainer>
);

IconDetailHeader.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

IconDetailHeader.defaultProps = {};

export default IconDetailHeader;
