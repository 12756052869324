import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const FilterHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const FakeLink = styled.span`
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
`;

const FilterHeader = ({ removeFilterClickHandler }) => {
  const { t } = useTranslation('common');

  return (
    <FilterHeaderContainer>
      <BoldText>{t('pages.filterLabel')}</BoldText>
      <FakeLink onClick={removeFilterClickHandler}>{t('pages.removeFilter')}</FakeLink>
    </FilterHeaderContainer>
  );
};

FilterHeader.propTypes = {
  removeFilterClickHandler: PropTypes.func.isRequired,
};

FilterHeader.defaultProps = {};

export default FilterHeader;
