import React, {
  useCallback, useState, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory, Switch, Route, useParams, useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';
import i18n from 'i18next';
import TotemFooter from '../components/totem-footer';
import TotemHeader from '../components/totem-header';
import {
  configurationStateSelector,
  getTotemConfigurationAction,
} from '../store/totem-configuration-slice';
import TotemActiveNavigation from '../components/totem-active-navigation';
import AdvScreenSaverPage from './adv-screen-saver-page';

const BodyContainer = styled.div`
  height: 2800px;
`;

const LandingPage = (props) => {
  const { routerProps } = props;
  const totemConfig = useSelector(configurationStateSelector);
  const dispatch = useDispatch();
  const [isScreenSaveActive, setIsScreenSaveActive] = useState(true);
  const [isAccessibilityActive, setIsAccessibilityActive] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language ?? 'en');
  const history = useHistory();
  const clickTimeout = useRef(null);
  const { totemId } = useParams();
  const shouldShowAccessibility = useRouteMatch(`${routerProps.match.url}/totemActive`);

  useEffect(() => {
    dispatch(getTotemConfigurationAction({ totemId }));
  }, [dispatch, totemId]);

  useEffect(() => {
    const idleTime = 1115;
    if (!isScreenSaveActive) {
      clickTimeout.current = setTimeout(() => {
        history.push(routerProps.match.url);
        setIsScreenSaveActive(true);
      }, 1000 * idleTime);
      // console.log('New TimeOut', clickTimeout.current);
    }
    // let clickTimeout;
    const handleWindowClick = () => {
      clearTimeout(clickTimeout.current);
      // console.log('Clear TimeOut', clickTimeout.current);
      clickTimeout.current = setTimeout(() => {
        history.push(routerProps.match.url);
        setIsScreenSaveActive(true);
      }, 1000 * idleTime);
      // console.log('New TimeOut', clickTimeout.current);
    };

    document.addEventListener('click', handleWindowClick);
    return () => {
      clearTimeout(clickTimeout.current);
      // console.log('Clear TimeOut', clickTimeout.current);
      document.removeEventListener('click', handleWindowClick);
    };
  }, [isScreenSaveActive, setIsScreenSaveActive, history, routerProps.match.url]);

  const updateLanguage = useCallback((lng) => {
    if (i18n.language !== lng) {
      i18n
        .changeLanguage(lng)
        .then((t) => {
        // console.log('Lingua cambiata');
        })
        .catch((err) => {
        // console.log('something went wrong loading', err);
        });
      setCurrentLanguage(lng);
    }
  }, [setCurrentLanguage]);

  const updateAccessibilityStatus = useCallback(() => {
    // console.log('accessibilità cliccata');
    setIsAccessibilityActive(!isAccessibilityActive);
  }, [setIsAccessibilityActive, isAccessibilityActive]);

  const advPageButtonHandler = useCallback(() => {
    setIsScreenSaveActive(false);
    history.push(`${routerProps.match.url}/totemActive/home`);
  }, [setIsScreenSaveActive, history, routerProps.match.url]);

  return (
    <>
      <TotemHeader screenSaverActive={false} />
      <BodyContainer>
        <Switch>
          <Route exact path={`${routerProps.match.url}`}>
            <AdvScreenSaverPage
              screenSaverButtonHandler={advPageButtonHandler}
            />
          </Route>
          <Route path={`${routerProps.match.url}/totemActive`}>
            <TotemActiveNavigation accessibilityActive={isAccessibilityActive} />
          </Route>
        </Switch>
      </BodyContainer>
      <TotemFooter
        screenSaverActive={false}
        onLanguageChange={updateLanguage}
        onAccessibilityButtonClick={updateAccessibilityStatus}
        currentSelectedLanguage={currentLanguage}
        accessibilityStatus={isAccessibilityActive}
        accessibilityVisible={!!shouldShowAccessibility}
      />
    </>
  );
};

export default LandingPage;
