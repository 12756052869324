import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { configurationStateSelector } from '../store/totem-configuration-slice';
import useTotemCustomSelector from '../common/use-totem-selector';
import InfoCardButton from '../components/info-card-button';

const InformationPageContainer = styled.div`
  padding: 50px;
`;

const Information = () => {
  const [loader, totemConfig] = useTotemCustomSelector(
    configurationStateSelector,
    'configurationLoading',
    'configuration',
  );
  const { t, i18n } = useTranslation('info-detail-page');
  const history = useHistory();

  const cardButtonHandler = useCallback((index) => {
    history.push(`${history.location.pathname}/detail/${index}`);
  }, [history]);

  return (
    <InformationPageContainer>
      {
        totemConfig.editionTotemConfig?.informations?.length > 0 && (
          totemConfig.editionTotemConfig.informations.map((item, index) => (
            <InfoCardButton
              key={item.informationTitle[i18n.language]}
              title={item.informationTitle[i18n.language]}
              imageSrc={item.informationButtonImage}
              imageAlt={`${t('buttonImage')} ${index}`}
              cardClickHandler={() => cardButtonHandler(index)}
              gutter={
                index % 2 === 0 && index !== totemConfig.editionTotemConfig.informations.length - 1
              }
              fullWidth={
                index === totemConfig.editionTotemConfig.informations.length - 1 && index % 2 === 0
              }
            />
          ))
        )
      }
    </InformationPageContainer>
  );
};

Information.propTypes = {};

Information.defaultProps = {};

export default Information;
