import React, { useMemo } from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import theme from '../theme';
import { H4 } from './headings';

const MapLegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 65%);
`;

const LegendTitle = styled(H4)`
  white-space: break-spaces;
  padding-left: 52px;
`;

const MapLegendRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const LegendColoredSquare = styled.div`
  height: ${theme.bodyFont.fontSize};
  width: ${theme.bodyFont.fontSize};
  border: ${(props) => props.$color && `2px solid ${theme.secondaryLineColor}`};
  background-color: ${(props) => props.$color};
`;

const LegendItem = styled.span`
  font-style: ${(props) => props.$italic && 'italic'};
  font-weight: ${(props) => props.$bold && theme.h4.fontWeight};
  font-size: ${(props) => props.$bold && theme.h4.fontSize};
`;

const legendData = [
  {
    color: '#007bc1',
    label: {
      it: 'design',
      en: 'design',
    },
  },
  {
    color: '#9cc8ed',
    label: {
      it: 'xLux',
      en: 'xLux',
    },
  },
  {
    color: '#104094',
    format: 'b',
    label: {
      it: 'S.Project/FTK',
      en: 'S.Project/FTK',
    },
  },
  {
    color: '#ffe272',
    format: 'b',
    label: {
      it: 'EuroCucina/FTK',
      en: 'EuroCucina/FTK',
    },
  },
  {
    color: '#c3dc9b',
    format: 'b',
    label: {
      it: 'Salone Internazionale del Bagno',
      en: 'Salone Internazionale del Bagno',
    },
  },
  {
    color: null,
    format: 'i',
    label: {
      it: 'SaloneSatellite (padd/pav.1/3)',
      en: 'SaloneSatellite (padd/pav.1/3)',
    },
  },
];

const MapLegend = () => {
  const { t, i18n } = useTranslation('common');

  const renderLegend = useMemo(() => map(
    legendData,
    (item, index) => (
      <MapLegendRow key={index}>
        <LegendColoredSquare $color={item.color ?? null} />
        <LegendItem
          $italic={item.format === 'i'}
          $bold={item.format === 'b'}
        >
          {item.label[i18n.language]}
        </LegendItem>
      </MapLegendRow>
    ),
  ), [i18n.language]);

  return (
    <MapLegendContainer>
      <LegendTitle>{t('map.legend')}</LegendTitle>
      {renderLegend}
    </MapLegendContainer>
  );
};

MapLegend.propTypes = {};

MapLegend.defaultProps = {};

export default MapLegend;
