import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Portal } from '@material-ui/core';
import Draggable from 'react-draggable';
import { map } from 'lodash';
import TotemModal from './totem-modal';
import theme from '../theme';

const KeyBoardContainer = styled.div`
  position: absolute;
  bottom: 68.525%;
  left: 12.5%;
  display: flex;
  flex-direction: column;
  width: 1800px;
  padding: 50px 25px;
  background-color: ${(props) => props.theme.primaryColor};
  opacity: 0.85;
  font-family: ${theme.fontFamily};
  font-size: ${theme.bodyFont.fontSize};
  gap: 3px;
  z-index: 1300;
`;

const KeyboardRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 1px;
  
  & span:nth-child(11) {
    width: 240px;
    font-size: ${theme.fontSize.h4};
  }
`;

const KeyboardLastRow = styled.div`
  display: flex;
  gap: 1px;
`;

const KeyButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  text-transform: uppercase;
  background-color: black;
  color: white;
  cursor: pointer;
`;

const SpaceButton = styled(KeyButton)`
  width: 1200px;
`;

const ZeroContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  width: 480px;
`;

const rows = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '⇦', '1', '2', '3'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', '@', 'enter', '4', '5', '6'],
  ['z', 'x', 'c', 'v', 'b', 'n', 'm', '_', '-', '.', 'cancel', '7', '8', '9'],
];

const TotemKeyboard = ({
  isKeyboardOpen,
  onKeyboardCloseHandler,
  onKeyboardButtonPressed,
}) => {
  const keyboardRef = useRef(null);
  const timerRef = useRef(null);

  const onKeyboardKeyPress = useCallback(
    (ev) => {
      onKeyboardButtonPressed(ev.target.textContent);
    },
    [onKeyboardButtonPressed],
  );

  const renderFirstRowsKeyboard = useMemo(
    () => map(rows, (row, index) => (
      <KeyboardRow className="not-draggable" key={index}>
        {map(row, (keyButton) => (
          <KeyButton key={keyButton} onClick={onKeyboardKeyPress}>
            {keyButton}
          </KeyButton>
        ))}
      </KeyboardRow>
    )),
    [onKeyboardKeyPress],
  );

  useEffect(() => {
    if (isKeyboardOpen) {
      timerRef.current = setTimeout(() => {
        timerRef.current = undefined;
      }, 500);
    }
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [isKeyboardOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        keyboardRef.current
        && !keyboardRef.current.contains(event.target)
        && !timerRef.current
      ) {
        onKeyboardCloseHandler();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onKeyboardCloseHandler]);

  if (!isKeyboardOpen) {
    return null;
  }

  return (
    <Portal>
      <Draggable
        handle=".handle"
        cancel=".not-draggable"
      >
        <KeyBoardContainer className="handle" ref={keyboardRef}>
          {renderFirstRowsKeyboard}
          <KeyboardLastRow className="not-draggable">
            <SpaceButton onClick={onKeyboardKeyPress}> </SpaceButton>
            <ZeroContainer>
              <KeyButton onClick={onKeyboardKeyPress}>
                0
              </KeyButton>
            </ZeroContainer>
          </KeyboardLastRow>
        </KeyBoardContainer>
      </Draggable>
    </Portal>
  );
};

TotemModal.propTypes = {
  isKeyboardOpen: PropTypes.bool,
  onKeyboardCloseHandler: PropTypes.func,
  onKeyboardButtonPressed: PropTypes.func,
};

TotemModal.defaultProps = {
  isKeyboardOpen: false,
  onKeyboardCloseHandler: null,
  onKeyboardButtonPressed: null,
};

export default TotemKeyboard;
