import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { H3 } from './headings';

const MapFloorControllerContainer = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ZoomButton = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
  width: 85px;
  background-color: ${(props) => props.theme.secondaryMediumColor};
  margin-top: ${(props) => props.$margin && '4px'};

  cursor: pointer;
  color: white;
  -webkit-text-fill-color: white;

  opacity: ${(props) => !props.$active && '0.6'};
`;

const CustomH3 = styled(H3)`
  text-transform: uppercase;
  font-weight: normal;
`;

const MapFloorController = ({ floorChange, activeFloor, floorNumber }) => (
  <MapFloorControllerContainer>
    {
      new Array(floorNumber).fill('').map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ZoomButton key={`floorIndex-${index}`} $active={activeFloor === index} onClick={() => floorChange(index)}>
          <CustomH3>{`p${index}`}</CustomH3>
        </ZoomButton>
      ))
    }
  </MapFloorControllerContainer>
);

MapFloorController.propTypes = {
  floorChange: PropTypes.func.isRequired,
  floorNumber: PropTypes.number.isRequired,
  activeFloor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

MapFloorController.defaultProps = {};

export default MapFloorController;
