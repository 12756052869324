import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TotemIcon from './icon';
import theme from '../theme';

const IconWrapper = styled.button`
  background-color: ${(props) => {
    if (props.$activeLightColor) {
      return 'white';
    }
    if (props.$noBgColor) {
      return props.theme.primaryColor;
    }
    return 'transparent';
  }};
  border: none;
  padding: 0;
  cursor: pointer;
  /* color: ${(props) => props.theme.primaryColor}; */
  color: ${(props) => (props.$activeLightColor ? props.theme.primaryColor : 'white')};

  filter: ${(props) => (props.$unactive ? 'brightness(0.5)' : 'unset')};

  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
`;

const IconButton = ({
  buttonClick,
  size,
  value,
  iconName,
  showBackground,
  isUnactive,
  activeLightColor,
}) => (
  <IconWrapper
    onClick={() => buttonClick(value)}
    value={value}
    $noBgColor={showBackground}
    $unactive={isUnactive}
    $activeLightColor={activeLightColor}
  >
    <TotemIcon size={size} icon={iconName} />
  </IconWrapper>
);

IconButton.propTypes = {
  buttonClick: PropTypes.func.isRequired,
  iconName: PropTypes.string.isRequired,
  size: PropTypes.string,
  value: PropTypes.string.isRequired,
  showBackground: PropTypes.bool,
  isUnactive: PropTypes.bool,
  activeLightColor: PropTypes.bool,
};

IconButton.defaultProps = {
  size: theme.fontSize.h2,
  showBackground: true,
  isUnactive: false,
  activeLightColor: false,
};

export default IconButton;
