import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Backdrop } from '@material-ui/core';
import theme from '../theme';
import { BodyText, H3 } from './headings';
import Button from './button';
import {
  totemConfigErrorsSelector,
  updateUtilsApiError,
  utilsApiErrorSelector,
} from '../store/totem-config-slice';

const ModalBodyContainer = styled.div`
  // TODO: uncomment this with TOTEM 4K
  position: absolute;
  //top: 50%;
  top: 100%;
  left: 50%;
  //-ms-transform: translate(-50%, -100%);
  //transform: translate(-50%, -100%);
  transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  height: 600px;
  width: 1000px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  border: 2px solid ${theme.secondaryLineColor};
  background-color: ${theme.secondaryLightColor};
`;

const ErrorDescription = styled(BodyText)`
  margin-top: 35px;
`;

const ErrorBackButton = styled(Button)`
  margin-top: 150px;
`;

const CustomBackdrop = styled(Backdrop)`
  // TODO: to be removed with TOTEM 4K
  transform: scale(3);
`;

const TotemModal = () => {
  const [t] = useTranslation('common');
  const { totemId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [errorType, setErrorType] = useState('generic');

  const configError = useSelector(totemConfigErrorsSelector);
  const utilsApiError = useSelector(utilsApiErrorSelector);

  useEffect(() => {
    if (configError) {
      setModalOpen(true);
      setErrorType('generic');
    }
    if (utilsApiError) {
      setModalOpen(true);
      setErrorType(utilsApiError);
    }
    if (!configError && !utilsApiError) {
      setModalOpen(false);
    }
  }, [configError, utilsApiError]);

  const modalCloseHandler = useCallback((event, reason) => {
    console.log(event, reason);
  }, []);

  const backButtonHandler = useCallback(() => {
    setModalOpen(false);
    switch (errorType) {
      case 'maps_path':
        history.goBack();
        break;
      default:
        history.push(`/${totemId}/adv/`);
        document.location.reload();
    }
    dispatch(updateUtilsApiError(null));
  }, [dispatch, errorType, history, totemId]);

  return (
    <Modal
      open={modalOpen}
      onClose={modalCloseHandler}
      BackdropComponent={CustomBackdrop}
      disableEscapeKeyDown
    >
      <ModalBodyContainer>
        <H3>{t('error.title')}</H3>
        <ErrorDescription>{t(`error.messages.${errorType}`)}</ErrorDescription>

        <ErrorBackButton variant="contained" onClick={backButtonHandler}>
          {t('error.button')}
        </ErrorBackButton>
      </ModalBodyContainer>
    </Modal>
  );
};

TotemModal.propTypes = {};

TotemModal.defaultProps = {};

export default TotemModal;
