import { PayloadAction } from '@reduxjs/toolkit';
import { flatten, uniq } from 'lodash';
import { TotemConfigurationState, TotemExhibitorsFilters } from '../totem-config-slice';

const checkAndReplace = (source: Array<string>, newItem: string): Array<string> => {
  if (source.includes(newItem)) {
    return source.filter(
      (item) => item !== newItem,
    );
  }
  return [...source, newItem];
};

const sectorFilterFunc = (exhibitors: Array<any>, sector: any): Array<any> => {
  if (sector) {
    return exhibitors.filter(
      (v) => v.commodities
        && v.commodities.some(
          (c: any) => c.product === sector.product
            || c.product.startsWith(`${sector.product}.`),
        ),
    );
  }
  return exhibitors;
};

const stringFilter = (exhibitors: Array<any>, filter: string): Array<any> => exhibitors
  .filter((exhibitor: any) => exhibitor.companyName && exhibitor.companyName.toLowerCase()
    .includes(filter));

const categoryFilter = (
  exhibitors: Array<any>,
  filters: Array<string>,
  exhibCategories: Array<any>,
  isBrandsAsExhibitors: boolean,
): Array<any> => {
  if (filters.length === 0) {
    return exhibitors;
  }

  let exhibitorsInCategory = filters.reduce(
    (acc: Array<string>, categoryId: string) => {
      const found = exhibCategories.find(
        (category) => category.id === categoryId,
      );
      if (found) {
        if (isBrandsAsExhibitors) {
          acc.push(found.brands);
        } else {
          acc.push(found.exhibitors);
        }
      }
      return acc;
    },
    [],
  );

  exhibitorsInCategory = uniq(flatten(exhibitorsInCategory));

  return exhibitors.filter(
    (exhibitor) => exhibitorsInCategory.includes(exhibitor.id),
  );
};

const padsFilter = (
  exhibitors: Array<any>,
  filters: Array<string>,
): Array<any> => {
  if (filters.length === 0) {
    return exhibitors;
  }
  return exhibitors.filter(
    (exhibitor) => exhibitor.pads?.some(
      (pad: any) => filters.includes(pad.id),
    ),
  );
};

const countryFilter = (
  exhibitors: Array<any>,
  filters: Array<string>,
): Array<any> => {
  if (filters.length === 0) {
    return exhibitors;
  }
  return exhibitors.filter((exhibitor) => filters.includes(exhibitor.country));
};

const applyAllFilters = (
  exhibitors: Array<any>,
  filters: TotemExhibitorsFilters,
  categories: Array<any>,
  isBrandsAsExhibitors: boolean,
): Array<any> => {
  const {
    searchExhibitorsFilter,
    categoriesFilter,
    padsExhibitorsFilter,
    countryExhibitorsFilter,
    sectorFilter,
  } = filters;

  let exhibitorsFiltered = sectorFilterFunc(exhibitors, sectorFilter);
  exhibitorsFiltered = stringFilter(exhibitorsFiltered, searchExhibitorsFilter);
  exhibitorsFiltered = categoryFilter(
    exhibitorsFiltered,
    categoriesFilter,
    categories,
    isBrandsAsExhibitors,
  );
  exhibitorsFiltered = padsFilter(
    exhibitorsFiltered,
    padsExhibitorsFilter,
  );
  exhibitorsFiltered = countryFilter(
    exhibitorsFiltered,
    countryExhibitorsFilter,
  );
  return exhibitorsFiltered;
};

export const applySectorFilter = (
  state: TotemConfigurationState,
  action: PayloadAction<any | null>,
): void => {
  state.exhibitorsPageFilters.sectorFilter = action.payload;
  state.filteredExhibitors = sectorFilterFunc(
    state.exhibitors,
    action.payload,
  );
};

export const applyExhibitorsStringFilter = (
  state: TotemConfigurationState,
  action: PayloadAction<string>,
): void => {
  state.exhibitorsPageFilters.searchExhibitorsFilter = action.payload;
  state.filteredExhibitors = applyAllFilters(
    state.exhibitors,
    state.exhibitorsPageFilters,
    state.exhibitorsCategories,
    !!state.configuration.editionTotemConfig.useBrandsAsExhibitors,
  );
};

export const applyExhibitorsCategoryFilter = (
  state: TotemConfigurationState,
  action: PayloadAction<string>,
): void => {
  state.exhibitorsPageFilters.categoriesFilter = checkAndReplace(
    state.exhibitorsPageFilters.categoriesFilter,
    action.payload,
  );

  state.filteredExhibitors = applyAllFilters(
    state.exhibitors,
    state.exhibitorsPageFilters,
    state.exhibitorsCategories,
    !!state.configuration.editionTotemConfig.useBrandsAsExhibitors,
  );
};

export const applyExhibitorsPadsFilter = (
  state: TotemConfigurationState,
  action: PayloadAction<string>,
): void => {
  state.exhibitorsPageFilters.padsExhibitorsFilter = checkAndReplace(
    state.exhibitorsPageFilters.padsExhibitorsFilter,
    action.payload,
  );

  state.filteredExhibitors = applyAllFilters(
    state.exhibitors,
    state.exhibitorsPageFilters,
    state.exhibitorsCategories,
    !!state.configuration.editionTotemConfig.useBrandsAsExhibitors,
  );
};

export const applyExhibitorsCountryFilter = (
  state: TotemConfigurationState,
  action: PayloadAction<string>,
): void => {
  state.exhibitorsPageFilters.countryExhibitorsFilter = checkAndReplace(
    state.exhibitorsPageFilters.countryExhibitorsFilter,
    action.payload,
  );

  state.filteredExhibitors = applyAllFilters(
    state.exhibitors,
    state.exhibitorsPageFilters,
    state.exhibitorsCategories,
    !!state.configuration.editionTotemConfig.useBrandsAsExhibitors,
  );
};
