import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { debounce, map } from 'lodash';
import {
  getTotemHomeExhibitorDetailsAction,
  homeStateSelector,
  setExhibitorDetailsFrom,
} from '../store/totem-home-slice';
import {
  configNotFilteredExhibitorsStateSelector,
  configStateSelector,
  configurationBrandsAsExhibitor,
  configurationWayFindingEnabled, logAnalytic,
  showRouteToExhibitor,
} from '../store/totem-config-slice';
import theme from '../theme';
import { H3, H4 } from './headings';
import ExhibitorLocationDetail from './exhibitor-location-detail';
import Button from './button';
import TotemDivider from './divider';
import IconDetailHeader from './icon-detail-header';
import LabelValueDetail from './label-value-detail';
import TotemFilterCategoryButton from './totem-filter-category-button';
import BrandCard from './brand-card';
import { analyticsEvents, eventsDescriptions } from '../common/analytics-constants';
import HomepageExhibitorButton from './homepage-exhibitor-button';
import { changeHomeState } from '../store/scroll-restoration-slice';

const HomepageSelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  position: relative;
  height: 325px;
`;

const HeroImage = styled.div`
  height: 260px;
  background-image: url(${(props) => props.$imageUrl});
  background-position: 50%;
  background-size: cover;
  z-index: 0;
`;

const EmptyHeroImage = styled.div`
  height: 260px;
  background-color: transparent;
  z-index: 0;
`;

const ExhibitorLogo = styled.div`
  height: 130px;
  width: 130px;
  position: absolute;
  border: 2px solid ${theme.secondaryLineColor};
  background-color: white;
  background-image: url(${(props) => props.$imageUrl});
  background-position: 50%;
  background-size: cover;
  z-index: 1;
  top: 195px;
  left: 65px;
  box-sizing: border-box;
`;

const EmptyExhibitorLogo = styled.div`
  height: 130px;
  width: 130px;
  position: absolute;
  border: 2px solid ${theme.secondaryLineColor};
  background-image: url(${(props) => props.$imageUrl});
  background-position: 50%;
  background-size: cover;
  z-index: 1;
  top: 195px;
  left: 50px;
  box-sizing: border-box;
`;

const ExhibitorDetailsWrapper = styled(HomepageSelectedContainer)`
  padding: 36px;
  
  & > div,
  & > h3,
  & > button {
    margin-top: 35px;
  }
  
  & > hr {
    margin-top: 70px;
    margin-bottom: 35px;
  }
`;

const H3CustomColor = styled(H3)`
  color: ${(props) => props.theme.primaryColor};
`;

const H4CustomMargin = styled(H4)`
  margin-top: 10px;
`;

const LocationDetails = styled.div`
  padding-left: 65px;
`;

const HomepageExhibitorSelected = (props) => {
  const totemConfig = useSelector(configStateSelector);
  const fullExhibitorsList = useSelector(configNotFilteredExhibitorsStateSelector, shallowEqual);
  const brandsAsExhibitor = useSelector(configurationBrandsAsExhibitor);
  const showWayFinding = useSelector(configurationWayFindingEnabled, shallowEqual);
  const { exhibitorId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('home-pages');
  const [nt] = useTranslation('common');

  const {
    exhibitorDetailsLoading,
    exhibitorDetails,
    exhibitorCluster,
  } = useSelector(homeStateSelector);

  const selectedExhibitor = useMemo(() => fullExhibitorsList.find(
    (item) => (
      item.id === exhibitorId || item.id === decodeURIComponent(exhibitorId)
    ),
  ), [exhibitorId, fullExhibitorsList]);

  const homeClickHandler = useCallback(
    (index, { id }) => {
      const newPath = history.location.pathname.replace(exhibitorId, encodeURIComponent(id));
      history.push(newPath.replace('//', '/'));
      dispatch(changeHomeState(id));
    },
    [dispatch, history, exhibitorId],
  );

  const realExhibitorId = useMemo(() => {
    let nextExhibitorId = decodeURIComponent(exhibitorId);
    if (brandsAsExhibitor) {
      const exhibitor = fullExhibitorsList.find(
        (item) => item.exhibitorId === exhibitorId,
      );
      nextExhibitorId = exhibitor.id;
    }
    return nextExhibitorId;
  }, [brandsAsExhibitor, exhibitorId, fullExhibitorsList]);

  const representedHouseExhibitors = useMemo(() => {
    if (brandsAsExhibitor || !exhibitorDetails) {
      return null;
    }
    let exhibitorsList = null;
    if (exhibitorDetails.type && exhibitorDetails.type === 'representedHouse') {
      exhibitorsList = fullExhibitorsList
        .filter((exhibitor) => exhibitor
          .brands?.some((brand) => brand.id === exhibitorDetails.id));
    }
    if (!exhibitorsList || !exhibitorsList.length) {
      return null;
    }
    return (
      <>
        <IconDetailHeader
          iconName="Espositori"
          title={nt('navigationMenu.exhibitors')}
        />
        {exhibitorsList.map((exhibitor, index) => (
          <HomepageExhibitorButton
            key={`rhe_${exhibitor.id}`}
            itemIndex={index}
            icon={exhibitor.logoUrl}
            exhibitorName={exhibitor.companyName}
            padText={t('pavillion', { pads: exhibitor.pads[0].id })}
            standText={t('stand', { stands: exhibitor.pads[0].stand.join(' ') })}
            buttonClickHandler={homeClickHandler}
            exhibitor={exhibitor}
          />
        ))}
        <TotemDivider variant="fullWidth" />
      </>
    );
  }, [brandsAsExhibitor, exhibitorDetails, fullExhibitorsList, homeClickHandler, nt, t]);

  // eslint-disable-next-line
  useEffect(() => {
    return () => {
      dispatch(showRouteToExhibitor(false));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(logAnalytic({
      eventName: analyticsEvents.CLICK_EXHIBITOR_BUTTON,
      eventParams: {
        [eventsDescriptions.CLICK_EXHIBITOR_BUTTON.exhibitorId]: realExhibitorId,
      },
    }));
  }, [dispatch, realExhibitorId]);

  useEffect(() => {
    if (
      exhibitorId
      && fullExhibitorsList
      && fullExhibitorsList.length
      && (!exhibitorDetails || exhibitorDetails.id !== realExhibitorId)
    ) {
      if (selectedExhibitor && selectedExhibitor.type === 'brand') {
        dispatch(
          setExhibitorDetailsFrom({
            exhibitorItem: selectedExhibitor,
          }),
        );
        return;
      }
      dispatch(
        getTotemHomeExhibitorDetailsAction({
          editionCode: totemConfig?.appTotemConfig?.editionCode ?? '',
          eboothId: realExhibitorId,
          isBrandsAsExhibitors: brandsAsExhibitor,
        }),
      );
    }
  }, [
    brandsAsExhibitor,
    dispatch,
    exhibitorDetails,
    exhibitorId,
    fullExhibitorsList,
    realExhibitorId,
    selectedExhibitor,
    totemConfig.appTotemConfig.editionCode,
    totemConfig.editionTotemConfig,
  ]);

  const exhibitorDetailClickHandler = useCallback(
    () => {
      history.push(`${history.location.pathname}/detail`);
      dispatch(logAnalytic({
        eventName: analyticsEvents.CLICK_FULL_EXHIBITOR_DETAIL_BUTTON,
        eventParams: {
          [eventsDescriptions.CLICK_FULL_EXHIBITOR_DETAIL_BUTTON.exhibitorId]: realExhibitorId,
        },
      }));
    },
    [dispatch, history, realExhibitorId],
  );

  const calculatePathHandler = useCallback(() => {
    dispatch(showRouteToExhibitor(true));
  }, [dispatch]);

  const debounced = useCallback(
    debounce(
      calculatePathHandler,
      2000,
      { leading: true, trailing: false },
    ),
    [],
  );

  const renderClusterList = useMemo(() => map(exhibitorCluster, (item) => (
    <React.Fragment key={`totem_${item.oldId}`}>
      <IconDetailHeader
        iconName="Categorie-speciali"
        title={item.name[i18n.language]}
      />
      {item.data.map((subItem) => (
        <TotemFilterCategoryButton
          key={`cls_${subItem.id}`}
          categoryImg={subItem.logoUrl?.length > 0 ? subItem.logoUrl : null}
          title={subItem.name[i18n.language]}
          itemIndex={subItem.id}
          isActive
        />
      ))}
    </React.Fragment>
  )), [exhibitorCluster, i18n.language]);

  const renderBrandsList = useMemo(
    () => exhibitorDetails
      && map(exhibitorDetails.brands, (item) => (
        <BrandCard key={item.name.replace(/\s/g, '')} title={item.name} />
      )),
    [exhibitorDetails],
  );

  const checkExhibitorId = useMemo(
    () => exhibitorDetails && realExhibitorId === exhibitorDetails.id,
    [exhibitorDetails, realExhibitorId],
  );

  return (
    <HomepageSelectedContainer>
      {checkExhibitorId && (
        <>
          <HeaderWrapper>
            {exhibitorDetails.coverPictureUrl && (
              <HeroImage $imageUrl={exhibitorDetails.coverPictureUrl} />
            )}
            {!exhibitorDetails.coverPictureUrl && <EmptyHeroImage />}
            {exhibitorDetails.logoUrl && (
              <ExhibitorLogo $imageUrl={exhibitorDetails.logoUrl} />
            )}
            {!exhibitorDetails.logoUrl && <EmptyExhibitorLogo />}
          </HeaderWrapper>
          <ExhibitorDetailsWrapper>
            <H3CustomColor>{exhibitorDetails.companyName}</H3CustomColor>
            {
              exhibitorDetails.type
              && exhibitorDetails.type === 'representedHouse'
              && (<H4CustomMargin>{t('representedHouse')}</H4CustomMargin>)
            }
            {!exhibitorDetails.type && (<H4CustomMargin>{t('exhibitor')}</H4CustomMargin>)}
            {exhibitorDetails.pads && (
              <ExhibitorLocationDetail
                padText={t('pavillion', { pads: exhibitorDetails.pads[0].id })}
                standText={t('stand', {
                  stands: exhibitorDetails.pads[0].stand.join(' '),
                })}
                iconSize="60px"
              />
            )}
            {
              showWayFinding
              && (!exhibitorDetails.type || exhibitorDetails.associatedExhibitorId)
              && (
                <Button variant="contained" onClick={debounced}>
                  {t('calculate_path')}
                </Button>
              )
            }
            <TotemDivider variant="fullWidth" />
            <IconDetailHeader
              iconName="Contatti"
              title={t('exhibitor_contacts')}
            />
            <LocationDetails>
              <LabelValueDetail
                labelText={t('address')}
                valueText={exhibitorDetails.address}
              />
              <LabelValueDetail
                labelText={t('city')}
                valueText={exhibitorDetails.city}
              />
              <LabelValueDetail
                labelText={t('zip')}
                valueText={exhibitorDetails.zip}
              />
              <LabelValueDetail
                labelText={t('country')}
                valueText={exhibitorDetails.country}
              />
            </LocationDetails>
            <TotemDivider variant="fullWidth" />
            {representedHouseExhibitors}
            <Button variant="outlined" onClick={exhibitorDetailClickHandler}>
              {t('full_detail')}
            </Button>
            {(renderClusterList.length > 0 || renderBrandsList.length > 0) && (
              <TotemDivider variant="fullWidth" />
            )}
            {renderClusterList}
            {renderBrandsList.length > 0 && (
              <>
                <IconDetailHeader
                  iconName="Categorie-merceologiche"
                  title={t('brands')}
                />
                {renderBrandsList}
              </>
            ) }
          </ExhibitorDetailsWrapper>
        </>
      )}
    </HomepageSelectedContainer>
  );
};

HomepageExhibitorSelected.propTypes = {};

export default HomepageExhibitorSelected;
