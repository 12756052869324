import styled from 'styled-components';
import { FormControlLabel } from '@material-ui/core';
import theme from '../theme';

const TotemFormControlLabel = styled(FormControlLabel)`
  display: flex;

  & .MuiTypography-body1 {
    font-size: ${theme.bodyBoldFont.fontSize};
    font-weight: ${theme.bodyBoldFont.fontWeight};
    font-family: ${theme.fontFamily};
  }
`;

export default TotemFormControlLabel;
