import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TotemIcon from './icon';
import theme from '../theme';
import { H4 } from './headings';
import ExhibitorLocationDetail from './exhibitor-location-detail';

const ExhibitorCardContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  width: 1460px;
  margin: 50px 0;
`;

const ExhibitorLogo = styled.div.attrs((props) => ({
  style: {
    backgroundImage: `url(${props.$imageUrl})`,
  },
}))`
  height: 120px;
  width: 120px;
  background-position: 50%;
  background-size: cover;
  border: 2px solid ${theme.secondaryLineColor};
  border-right-width: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const ExhibitorEmptyLogo = styled.div`
  height: 120px;
  width: 120px;
  background-color: white;
  border: 2px solid ${theme.secondaryLineColor};
  border-right-width: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const ExhibitorTextualDataContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  border: 2px solid ${theme.secondaryLineColor};
  border-right-width: 0;
`;

const ExhibitorName = styled.div`
  flex: 2;
  padding: 0 20px;
`;

const CustomExhibitorLocationDetail = styled(ExhibitorLocationDetail)`
  flex: 1;
`;

const RepresentedHouse = styled(H4)`
  flex: 1;
`;

const ExhibitorDetailButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 124px;
  width: 124px;
  color: white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${(props) => props.theme.primaryColor};
`;

const ExhibitorCardButton = (props) => {
  const {
    cardClickHandler,
    exhibitorItem,
    logo,
    name,
    padText,
    standText,
    style,
    itemIndex,
    exhibitorType,
  } = props;

  const buttonHandler = useCallback(() => {
    cardClickHandler(itemIndex, exhibitorItem);
  }, [cardClickHandler, exhibitorItem, itemIndex]);

  return (
    <ExhibitorCardContainer style={style} onClick={buttonHandler}>
      {logo && <ExhibitorLogo $imageUrl={logo} />}
      {!logo && <ExhibitorEmptyLogo />}
      <ExhibitorTextualDataContainer>
        <ExhibitorName>
          <H4>{name}</H4>
        </ExhibitorName>
        {padText
          && standText
          && (
            <CustomExhibitorLocationDetail padText={padText} standText={standText} />
          )}
        {
          !padText
          && exhibitorType
          && (<RepresentedHouse>{exhibitorType}</RepresentedHouse>)
        }
      </ExhibitorTextualDataContainer>
      <ExhibitorDetailButton>
        <TotemIcon icon="Chevron-Right" size="50px" />
      </ExhibitorDetailButton>
    </ExhibitorCardContainer>
  );
};

ExhibitorCardButton.propTypes = {
  cardClickHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  exhibitorItem: PropTypes.object.isRequired,
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  padText: PropTypes.string,
  standText: PropTypes.string,
  itemIndex: PropTypes.number.isRequired,
  exhibitorType: PropTypes.string,
};

ExhibitorCardButton.defaultProps = {
  logo: null,
  exhibitorType: null,
  padText: null,
  standText: null,
};

export default ExhibitorCardButton;
