import Information from '../features/information';
import InformationDetail from '../features/information-detail';
import CategoryExhibitor from '../features/category-exhibitor';
import Exhibitors from '../features/exhibitors';
import Exhibitor from '../features/exhibitor';
import ExhibitorDetail from '../features/exhibitor-detail';
import Events from '../features/events';
import EventDetail from '../features/event-detail';
import Home from '../features/home';
import HomeExhibitor from '../features/home-exhibitor';
import Services from '../features/services';
import Infomobility from '../features/infomobility';

const routesConfiguration = [
  {
    path: '/information',
    icon: 'Informazioni',
    title: 'navigationMenu.information',
    children: [
      {
        path: '',
        exact: true,
        title: 'navigationMenu.information',
        breadcrumbKey: 'pagesBreadcrumbs.information.home',
        showBackButton: false,
        component: Information,
      },
      {
        path: '/detail/:infoId',
        exact: true,
        title: 'navigationMenu.information',
        breadcrumbKey: 'pagesBreadcrumbs.information.detail',
        showBackButton: true,
        component: InformationDetail,
      },
    ],
  },
  {
    path: '/exhibitor',
    icon: 'Espositori',
    title: 'navigationMenu.exhibitors',
    children: [
      {
        path: '',
        exact: true,
        title: 'navigationMenu.exhibitors',
        breadcrumbKey: 'pagesBreadcrumbs.exhibitor.categories',
        showBackButton: false,
        component: CategoryExhibitor,
      },
      {
        path: '/exhibitors',
        exact: true,
        title: 'navigationMenu.exhibitors',
        breadcrumbKey: 'pagesBreadcrumbs.exhibitor.exhibitors',
        showBackButton: true,
        component: Exhibitors,
      },
      {
        path: '/exhibitors/:exhibitorId',
        exact: true,
        title: 'navigationMenu.exhibitors',
        breadcrumbKey: 'pagesBreadcrumbs.exhibitor.exhibitor',
        showBackButton: true,
        component: Exhibitor,
      },
      {
        path: '/exhibitors/:exhibitorId/detail',
        exact: true,
        title: 'navigationMenu.exhibitors',
        breadcrumbKey: 'pagesBreadcrumbs.exhibitor.detail',
        showBackButton: true,
        component: ExhibitorDetail,
      },
    ],
  },
  {
    path: '/events',
    icon: 'Eventi',
    title: 'navigationMenu.events',
    children: [
      {
        path: '',
        exact: true,
        title: 'navigationMenu.events',
        breadcrumbKey: 'pagesBreadcrumbs.events.events',
        showBackButton: false,
        component: Events,
      },
      {
        path: '/detail/:eventId',
        exact: true,
        title: 'navigationMenu.events',
        breadcrumbKey: 'pagesBreadcrumbs.events.detail',
        showBackButton: true,
        component: EventDetail,
      },
    ],
  },
  {
    path: '/home',
    icon: 'Home',
    title: 'navigationMenu.home',
    children: [
      {
        path: '',
        exact: true,
        title: 'navigationMenu.home',
        breadcrumbKey: 'pagesBreadcrumbs.home.home',
        showBackButton: false,
        component: Home,
      },
      {
        path: '/exhibitor/:exhibitorId',
        exact: true,
        title: 'navigationMenu.home',
        breadcrumbKey: 'pagesBreadcrumbs.home.exhibitor',
        showBackButton: true,
        component: HomeExhibitor,
      },
    ],
  },
  {
    path: '/services',
    icon: 'Servizi-fiera',
    title: 'navigationMenu.services',
    children: [
      {
        path: '',
        exact: true,
        title: 'navigationMenu.services',
        breadcrumbKey: 'pagesBreadcrumbs.services.services',
        showBackButton: false,
        component: Services,
      },
    ],
  },
  {
    path: '/infomobility',
    icon: 'Info-mobilita',
    title: 'navigationMenu.infomobility',
    children: [
      {
        path: '',
        exact: true,
        title: 'navigationMenu.infomobility',
        breadcrumbKey: 'pagesBreadcrumbs.infomobility.infomobility',
        showBackButton: false,
        component: Infomobility,
      },
    ],
  },
];

export default routesConfiguration;
