import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { configurationStateSelector } from '../store/totem-configuration-slice';
import useTotemCustomSelector from '../common/use-totem-selector';
import InfoCardButton from '../components/info-card-button';
import { H2 } from '../components/headings';

const ExhibitorDetailContainer = styled.div`
  display: flex;
`;

const ExhibitorDetail = ({ accessibilityActive }) => {
  const { t, i18n } = useTranslation('exhibitor-pages');
  const history = useHistory();

  // const cardButtonHandler = useCallback((index) => {
  //   history.push(`${history.location.pathname}/detail/${index}`);
  // }, [history]);

  return (
    <ExhibitorDetailContainer>
      <H2 />
    </ExhibitorDetailContainer>
  );
};

ExhibitorDetail.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
};

ExhibitorDetail.defaultProps = {};

export default ExhibitorDetail;
