import { reduce } from 'lodash';
import Information from '../features/information';
import InformationDetail from '../features/information-detail';
import ExhibitorCategoryList from '../features/exhibitor-category-list';
import Exhibitors from '../features/exhibitors';
import Exhibitor from '../features/exhibitor';
import ExhibitorDetail from '../features/exhibitor-detail';
import Events from '../features/events';
import EventDetail from '../features/event-detail';
import Home from '../features/home';
import HomeExhibitor from '../features/home-exhibitor';
import Services from '../features/services';
import Infomobility from '../features/infomobility';
// import HomepageExhibitorSelected from '../components/homepage-exhibitor-selected';

const routesConfiguration = [
  {
    mainRouteKeyName: 'information',
    path: '/information',
    icon: 'Informazioni',
    title: 'navigationMenu.information',
    children: [
      {
        path: '',
        exact: true,
        title: 'navigationMenu.information',
        breadcrumbKey: 'pagesBreadcrumbs.information.home',
        showBackButton: false,
        showRestoreButton: true,
        component: Information,
      },
      {
        path: '/detail/:infoId',
        exact: true,
        title: 'navigationMenu.information',
        breadcrumbKey: 'pagesBreadcrumbs.information.detail',
        showBackButton: true,
        showRestoreButton: true,
        component: InformationDetail,
      },
    ],
  },
  {
    mainRouteKeyName: 'exhibitor',
    path: '/exhibitor',
    icon: 'Espositori',
    title: 'navigationMenu.exhibitors',
    children: [
      {
        path: '',
        exact: true,
        title: 'navigationMenu.exhibitors',
        breadcrumbKey: 'pagesBreadcrumbs.exhibitor.categories',
        showBackButton: false,
        showRestoreButton: true,
        component: ExhibitorCategoryList,
      },
      {
        path: '/exhibitors/:exhibitorCategory',
        exact: true,
        title: 'navigationMenu.exhibitors',
        breadcrumbKey: 'pagesBreadcrumbs.exhibitor.exhibitors',
        showBackButton: true,
        showRestoreButton: true,
        component: Exhibitors,
      },
      {
        path: '/exhibitors/:exhibitorCategory/exhibitor/:exhibitorId',
        exact: true,
        title: 'navigationMenu.exhibitors',
        breadcrumbKey: 'pagesBreadcrumbs.exhibitor.exhibitor',
        showBackButton: true,
        showRestoreButton: true,
        component: Exhibitor,
      },
      {
        path: '/exhibitors/:exhibitorCategory/exhibitor/:exhibitorId/detail',
        exact: true,
        title: 'navigationMenu.exhibitors',
        breadcrumbKey: 'pagesBreadcrumbs.exhibitor.detail',
        showBackButton: true,
        showRestoreButton: true,
        component: ExhibitorDetail,
      },
    ],
  },
  {
    mainRouteKeyName: 'event',
    path: '/events',
    icon: 'Eventi',
    title: 'navigationMenu.events',
    children: [
      {
        path: '',
        exact: true,
        title: 'navigationMenu.events',
        breadcrumbKey: 'pagesBreadcrumbs.events.events',
        showBackButton: false,
        showRestoreButton: true,
        component: Events,
      },
      {
        path: '/detail/:eventId',
        exact: true,
        title: 'navigationMenu.events',
        breadcrumbKey: 'pagesBreadcrumbs.events.detail',
        showBackButton: true,
        showRestoreButton: true,
        component: EventDetail,
      },
    ],
  },
  {
    mainRouteKeyName: 'home',
    path: '/home',
    icon: 'Home',
    title: 'navigationMenu.home',
    children: [
      {
        path: '',
        exact: true,
        title: 'navigationMenu.home',
        breadcrumbKey: 'pagesBreadcrumbs.home.home',
        showBackButton: false,
        showRestoreButton: false,
        component: Home,
      },
      {
        path: '/detail/:exhibitorId',
        exact: true,
        title: 'navigationMenu.home',
        breadcrumbKey: 'pagesBreadcrumbs.home.exhibitor',
        showBackButton: true,
        showRestoreButton: true,
        component: HomeExhibitor,
      },
      {
        path: '/detail/:exhibitorId/detail',
        exact: true,
        title: 'navigationMenu.exhibitors',
        breadcrumbKey: 'pagesBreadcrumbs.exhibitor.detail',
        showBackButton: true,
        showRestoreButton: true,
        component: ExhibitorDetail,
      },
    ],
  },
  {
    mainRouteKeyName: 'service',
    path: '/services',
    icon: 'Servizi-fiera',
    title: 'navigationMenu.services',
    children: [
      {
        path: '',
        exact: true,
        title: 'navigationMenu.services',
        breadcrumbKey: 'pagesBreadcrumbs.services.services',
        showBackButton: false,
        showRestoreButton: false,
        component: Services,
      },
    ],
  },
  {
    mainRouteKeyName: 'infomobility',
    path: '/infomobility',
    icon: 'Info-mobilita',
    title: 'navigationMenu.infomobility',
    children: [
      {
        path: '',
        exact: true,
        title: 'navigationMenu.infomobility',
        breadcrumbKey: 'pagesBreadcrumbs.infomobility.infomobility',
        showBackButton: false,
        showRestoreButton: true,
        component: Infomobility,
      },
    ],
  },
];

function moldRouteItem(acc, route) {
  return reduce(
    route.children,
    (_acc, child) => {
      _acc.push({
        ...child,
        path: child.path === '' ? `${route.path}/` : route.path + child.path,
      });
      return _acc;
    },
    acc,
  );
}

export const RouterItems = reduce(
  routesConfiguration,
  moldRouteItem,
  [],
);

export default routesConfiguration;
