import React, { useCallback, useMemo } from 'react';
import i18n from 'i18next';
import { map } from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../theme';
import { H3, H4 } from './headings';
import TotemIcon from './icon';

const ExhibitorCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExhibitorCategoryButtonWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
`;

const ExhibitorCategoryImage = styled.div`
  height: 120px;
  width: 120px;
  background-image: url(${(props) => props.$imageUrl});
  background-position: 50%;
  background-size: cover;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const ExhibitorCategoryTitle = styled.div`
  flex: 1;
  border: 2px solid ${theme.secondaryLineColor};
  border-right-width: 0;
  border-left-width: ${(props) => props.$showLeftBorder && '2px'};
  border-top-left-radius: ${(props) => props.$showLeftBorder && '4px'};
  border-bottom-left-radius: ${(props) => props.$showLeftBorder && '4px'};
  padding: 40px 21px;
`;

const ExhibitorCategoryNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 120px;
  color: white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${(props) => props.theme.primaryColor};
`;

const ExhibitorCategoryArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 120px;
  color: white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${(props) => props.theme.primaryColor};
`;

const AccordionArrow = styled.div`
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: ${(props) => (props.$active ? 'rotate(-90deg)' : 'rotate(90deg)')};
`;

const ExhibitorSubcategoriesContainer = styled.div`
  max-height: ${(props) => (props.$active ? '1500px' : '0')};
  overflow: ${(props) => (props.$active ? 'auto' : 'hidden')};
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const ExhibitorSubcategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.secondaryLightColor};
  padding: 48px;
`;

const SubcategoriesTitleButton = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.primaryColor};
  padding: 12px 0;
`;

const SubcategoriesTitle = styled(H3)`
  text-transform: uppercase;
`;

const ExhibitorSubcategoriesItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SubcategoryItemButton = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: calc((100% - 100px) / 3);
  margin-top: 96px;
  padding: 24px 0;
  padding-left: ${(props) => props.$hasMargin && '48px'};
`;

const SubCategoryName = styled.p`
  text-transform: uppercase;
  flex: 1;
`;

const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  border-right: ${(props) => (props.$noDivider ? '0' : `2px solid ${theme.secondaryLineColor}`)};
`;

const ExhibitorCategoryButton = (props) => {
  const {
    buttonClickHandler,
    title,
    categoriesLength,
    itemIndex,
    image,
    subCategoriesTitle,
    subCategories,
    subCategoryClickHandler,
    activeItem,
    propItem,
  } = props;

  const buttonHandler = useCallback(() => {
    buttonClickHandler(propItem, itemIndex);
  }, [buttonClickHandler, itemIndex, propItem]);

  const subTitleButtonHandler = useCallback((e) => {
    e.stopPropagation();
    subCategoryClickHandler(propItem, '1');
  }, [propItem, subCategoryClickHandler]);

  const subButtonHandler = useCallback((e) => {
    e.stopPropagation();
    if (subCategories.length > 1) {
      const res = Number.parseInt(e.currentTarget.dataset.subCategoryIndexNumber, 10);
      subCategoryClickHandler(subCategories[res], '2');
    }
  }, [subCategories, subCategoryClickHandler]);

  const renderSubCategoryList = useMemo(() => map(subCategories, (item, index) => (
    <SubcategoryItemButton
      key={item.id + item.product + index}
      data-sub-category-index-number={index}
      onClick={subButtonHandler}
      $hasMargin={index % 3 !== 0}
    >
      <SubCategoryName>{item.name[i18n.language]}</SubCategoryName>
      <IconWrapper $noDivider={(index + 1) === subCategories.length || index % 3 === 2}>
        <TotemIcon icon="Chevron-Right" size="60px" />
      </IconWrapper>
    </SubcategoryItemButton>
  )), [subButtonHandler, subCategories]);

  const renderRightButtonItem = useCallback(() => {
    if (categoriesLength) {
      return (
        <ExhibitorCategoryNumber>
          <H4>{categoriesLength}</H4>
        </ExhibitorCategoryNumber>
      );
    }
    if (!categoriesLength && subCategories.length > 1) {
      return (
        <ExhibitorCategoryArrowContainer>
          <AccordionArrow $active={activeItem}>
            <TotemIcon icon="Chevron-Right" size="50px" />
          </AccordionArrow>
        </ExhibitorCategoryArrowContainer>
      );
    }

    return (
      <ExhibitorCategoryArrowContainer>
        <TotemIcon icon="Chevron-Right" size="50px" />
      </ExhibitorCategoryArrowContainer>
    );
  }, [activeItem, categoriesLength, subCategories.length]);

  return (
    <ExhibitorCategoryContainer>
      <ExhibitorCategoryButtonWrapper onClick={buttonHandler}>
        {image && <ExhibitorCategoryImage $imageUrl={image} />}
        <ExhibitorCategoryTitle $showLeftBorder={!image}>
          <H4>{title}</H4>
        </ExhibitorCategoryTitle>
        {renderRightButtonItem()}
      </ExhibitorCategoryButtonWrapper>
      {
        subCategories.length > 1 && (
          <ExhibitorSubcategoriesContainer $active={activeItem}>
            <ExhibitorSubcategoriesWrapper>
              <SubcategoriesTitleButton onClick={subTitleButtonHandler}>
                <SubcategoriesTitle>{subCategoriesTitle}</SubcategoriesTitle>
                <TotemIcon icon="Chevron-Right" size="50px" />
              </SubcategoriesTitleButton>
              <ExhibitorSubcategoriesItemWrapper>
                {renderSubCategoryList}
              </ExhibitorSubcategoriesItemWrapper>
            </ExhibitorSubcategoriesWrapper>
          </ExhibitorSubcategoriesContainer>
        )
      }
    </ExhibitorCategoryContainer>
  );
};

ExhibitorCategoryButton.propTypes = {
  title: PropTypes.string.isRequired,
  buttonClickHandler: PropTypes.func.isRequired,
  categoriesLength: PropTypes.number,
  image: PropTypes.string,
  itemIndex: PropTypes.number.isRequired,
  subCategoriesTitle: PropTypes.string,
  subCategories: PropTypes.arrayOf(
    PropTypes.object,
  ),
  subCategoryClickHandler: PropTypes.func,
  activeItem: PropTypes.bool,
};

ExhibitorCategoryButton.defaultProps = {
  image: null,
  subCategoriesTitle: '',
  subCategories: null,
  subCategoryClickHandler: null,
  activeItem: false,
  categoriesLength: null,
};

export default ExhibitorCategoryButton;
