import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import doSignInAction from './actions/do-sign-in';
import doSignOutAction from './actions/do-sign-out';

type LoginState = 'signed-out'|'signed-in'|'error'|'pending';

type LoginSliceState = {
  state: LoginState;
  userId?: string;
};

function doSignInPending(state: LoginSliceState, action: PayloadAction<void>): void {
  state.state = 'pending';
}

function doSignInFulfilled(
  state: LoginSliceState,
  action: PayloadAction<{ userId: string }>,
): void {
  state.state = 'signed-in';
  state.userId = action.payload.userId;
}

function doSignInRejected(state: LoginSliceState, action: PayloadAction<{ error: Error }>): void {
  state.state = 'error';
  state.userId = undefined;
  // console.error(action.error);
}

function doSignOutPending(state: LoginSliceState, action: PayloadAction<void>): void {
  state.state = 'pending';
}

function doSignOutCompleted(state: LoginSliceState, action: PayloadAction<void>): void {
  state.state = 'signed-out';
  state.userId = undefined;
}

export const loginSlice = createSlice<LoginSliceState, any, 'login'>({
  name: 'login',
  initialState: {
    state: 'signed-out',
    userId: undefined,
  },
  reducers: {},
  extraReducers: {
    [doSignInAction.pending as unknown as string]: doSignInPending,
    [doSignInAction.fulfilled as unknown as string]: doSignInFulfilled,
    [doSignInAction.rejected as unknown as string]: doSignInRejected,
    [doSignOutAction.pending as unknown as string]: doSignOutPending,
    [doSignOutAction.fulfilled as unknown as string]: doSignOutCompleted,
    [doSignOutAction.rejected as unknown as string]: doSignOutCompleted,
  },
});

export default loginSlice.reducer;

export function signInStateSelector(state: { login: LoginSliceState }): LoginState {
  return state.login.state;
}

export function userIdSelector(state: { login: LoginSliceState }): string|undefined {
  return state.login.userId;
}
