import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getTotemEventsAction,
  eventsStateSelector,
  updateFilterQuery,
  eventsDatesFilter,
  eventsPadsFilter,
  updateDateFilter,
  updatePadsFilter,
  getEventFiltersSelector,
  resetFilters,
  getTotemEventsCategoriesAction,
  filteredEventsSelector,
  updateCategoryFilter,
} from '../store/totem-events-slice';
import { configurationStateSelector } from '../store/totem-configuration-slice';
import useTotemCustomSelector from '../common/use-totem-selector';
import { H4 } from '../components/headings';
import TotemLeftPanel from '../components/totem-left-panel';
import TotemRightPanel from '../components/totem-right-panel';
import EventCardButton from '../components/event-card-button';
import FilterHeader from '../components/filter-header';
import TotemRadioButton from '../components/totem-radio-button';
import theme from '../theme';
import TotemFilterCategoryButton from '../components/totem-filter-category-button';

const EventsContainer = styled.div`
  display: flex;
`;

const EventCardsContainer = styled.div`
  padding: 50px 50px 0 50px;
`;

const EventFiltersContainer = styled.div`
  padding: 36px;
`;

const EventFilterWrapper = styled.div`
  margin-top: 32px;
`;

const Paragraph = styled.p`
  font-size: ${theme.fontSize.bodyFont};
  font-family: ${theme.fontFamily};
`;

const Events = ({ accessibilityActive }) => {
  const { t, i18n } = useTranslation('events-pages');
  const [tc] = useTranslation('common');
  const history = useHistory();
  const dispatch = useDispatch();
  const eventFilters = useSelector(getEventFiltersSelector);
  const filterDates = useSelector(eventsDatesFilter);
  const filterPads = useSelector(eventsPadsFilter);
  const [eventQuery, setEventQuery] = useState(eventFilters.searchEventFilter);
  const [loader, totemConfig] = useTotemCustomSelector(
    configurationStateSelector,
    'configurationLoading',
    'configuration',
  );
  const totemEvents = useSelector(filteredEventsSelector);
  const [eventsCategoriesLoader, totemEventsCategories] = useTotemCustomSelector(
    eventsStateSelector,
    'eventsCategoriesLoading',
    'eventsCategories',
  );

  useEffect(() => {
    dispatch(getTotemEventsAction({ editionCode: totemConfig?.appTotemConfig?.editionCode ?? '', language: 'en' }));
    dispatch(getTotemEventsAction({ editionCode: totemConfig?.appTotemConfig?.editionCode ?? '', language: 'it' }));
    dispatch(getTotemEventsCategoriesAction({ editionCode: totemConfig?.appTotemConfig?.editionCode ?? '', language: 'en' }));
    dispatch(getTotemEventsCategoriesAction({ editionCode: totemConfig?.appTotemConfig?.editionCode ?? '', language: 'it' }));
  }, [dispatch, totemConfig.appTotemConfig.editionCode]);

  const eventClickHandler = useCallback((eventId) => {
    history.push(`${history.location.pathname}/detail/${eventId}`);
  }, [history]);

  const onSearchClickHandler = useCallback(() => {
    if (eventQuery.length > 2 || eventQuery.length === 0) {
      dispatch(updateFilterQuery(eventQuery));
    }
  }, [eventQuery, dispatch]);

  const handleResetFilter = useCallback(() => {
    setEventQuery('');
    dispatch(resetFilters());
  }, [dispatch]);

  const handleDateFilterChange = useCallback((event) => {
    dispatch(updateDateFilter(event.target.value));
  }, [dispatch]);

  const handlePadsFilterChange = useCallback((event) => {
    dispatch(updatePadsFilter(event.target.value));
  }, [dispatch]);

  const handleCategoryFilterChange = useCallback((event) => {
    dispatch(updateCategoryFilter(event));
  }, [dispatch]);

  return (
    <EventsContainer>
      <TotemLeftPanel accessibilityActive={accessibilityActive}>
        <EventCardsContainer>
          {totemEvents[i18n.language].length > 0
            && totemEvents[i18n.language].map((event) => (
              <EventCardButton
                key={event.id}
                cardClickHandler={eventClickHandler}
                event={event}
              />
            ))}
        </EventCardsContainer>
      </TotemLeftPanel>
      <TotemRightPanel
        accessibilityActive={accessibilityActive}
        showSearchBar
        searchTitle={t('searchLabel')}
        onSearchInputChangeHandler={(ev) => setEventQuery(ev.target.value)}
        onPerformSearchClickHandler={onSearchClickHandler}
        searchValue={eventQuery}
      >
        <EventFiltersContainer>
          <FilterHeader removeFilterClickHandler={handleResetFilter} />
          <EventFilterWrapper>
            <H4>{tc('pages.categories')}</H4>
            <br />
            {totemEventsCategories[i18n.language].length > 0
              && totemEventsCategories[i18n.language].map((cat) => (
                <TotemFilterCategoryButton
                  key={cat.id}
                  title={cat.label}
                  itemIndex={cat.id}
                  isActive={
                    eventFilters.categoriesFilter.length === 0
                      || eventFilters.categoriesFilter.includes(cat.id)
                  }
                  onClickCategoryHandler={handleCategoryFilterChange}
                />
              ))}
          </EventFilterWrapper>
          <EventFilterWrapper>
            <H4>{tc('pages.date')}</H4>
            <br />
            <RadioGroup
              aria-label={tc('pages.date')}
              name="dates"
              value={eventFilters.dateEventFilter}
              onChange={handleDateFilterChange}
            >
              {filterDates
                && filterDates.map((item) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<TotemRadioButton />}
                    label={<Paragraph>{item}</Paragraph>}
                  />
                ))}
            </RadioGroup>
          </EventFilterWrapper>
          <EventFilterWrapper>
            <H4>{tc('pages.pavillion')}</H4>
            <br />
            <RadioGroup
              aria-label={tc('pages.pavillion')}
              name="pavillion"
              value={eventFilters.padsEventFilter}
              onChange={handlePadsFilterChange}
            >
              {filterPads
                && filterPads.map((item) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<TotemRadioButton />}
                    label={<Paragraph>{item}</Paragraph>}
                  />
                ))}
            </RadioGroup>
          </EventFilterWrapper>
        </EventFiltersContainer>
      </TotemRightPanel>
    </EventsContainer>
  );
};

Events.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
};

Events.defaultProps = {};

export default Events;
