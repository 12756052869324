import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const AccessibilityIndicatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 1307px;
  width: 100%;
  background-color: white;
`;

const TextIndicationContainer = styled.h4`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.secondaryLineColor};
  color: white;
  font-weight: ${(props) => props.theme.bodyBoldFont.fontWeight};
  font-size: ${(props) => props.theme.fontSize.h3};
  text-transform: uppercase;
  padding: 28px 0;
  height: 64px;
`;

const AccessibilityIndicator = () => {
  const { t } = useTranslation('common');

  return (
    <AccessibilityIndicatorContainer>
      <TextIndicationContainer>{t('accessibility.indicatorText')}</TextIndicationContainer>
    </AccessibilityIndicatorContainer>
  );
};

export default AccessibilityIndicator;
