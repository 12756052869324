import styled from 'styled-components';
import theme from '../theme';

const NoResultCard = styled.p`
  font-size: ${theme.fontSize.h3};
  margin: 60px;
  background-color: ${theme.secondaryLightColor};
  padding: 30px;
`;

export default NoResultCard;
