/* global NODE_ENV */
/* eslint-env browser */

import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import './i18n';
import configureStore from './store/configure-store';
import App from './features/app';

switch (NODE_ENV) {
  case 'staging': {
    // eslint-disable-next-line global-require,@typescript-eslint/no-var-requires
    const { default: worker } = require('./mocks/browser');
    worker.start({
      serviceWorker: {
        // Points to the custom location of the Service Worker file.
        url: '/frontend-framework-team/boilerplate-react/mockServiceWorker.js',
      },
    });
    break;
  }
  case 'development': {
    // eslint-disable-next-line global-require,@typescript-eslint/no-var-requires
    const { default: worker } = require('./mocks/browser');
    worker.start();
    break;
  }
  default:
}

const store = configureStore();

function renderApp() {
  render(
    <Provider store={store}>
      <Suspense fallback="loading">
        <Router>
          <StylesProvider injectFirst>
            <App />
          </StylesProvider>
        </Router>
      </Suspense>
    </Provider>,
    document.querySelector('#react-app'),
  );
}

renderApp();

if ((NODE_ENV !== 'production' && NODE_ENV !== 'staging') && module && module.hot) {
  module.hot.accept('./features/app', renderApp);
}
