import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../theme';
import { H4 } from './headings';
import TotemIcon from './icon';
import HorizontalExhibitorLocationDetail from './horizontal-exhibitor-location-detail';

const HomepageExhibitorButtonContainer = styled.div`
  display: flex;
  margin: ${(props) => (props.$hideBottomBorder ? '0 10px' : '0 40px')};
  padding: 40px 0;
  align-items: center;
  border-bottom: 0;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: space-between;

  :last-child {
    border-bottom: ${(props) => (
    !props.$hideBottomBorder && `2px solid ${theme.secondaryLineColor}`
  )};
  }
`;

const InfoContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const LogoNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExhibitorLogo = styled.div.attrs((props) => ({
  style: {
    backgroundImage: `url(${props.$imageUrl})`,
  },
}))`
  height: 70px;
  width: 70px;
  border: 2px solid ${theme.secondaryLineColor};
  background-color: white;
  background-position: 50%;
  background-size: cover;
  box-sizing: border-box;
`;

const EmptyExhibitorLogo = styled.div`
  height: 70px;
  width: 70px;
  border: 2px solid ${theme.secondaryLineColor};
  background-color: white;
  background-position: 50%;
  background-size: cover;
  box-sizing: border-box;
`;

const TextualInfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px 0 20px;
  max-width: 295px;
  gap: 10px;
`;

const CustomH4 = styled(H4)`
  width: 100%;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  //margin-bottom: 15px;
`;

const IconColor = styled.div`
  color: ${theme.secondaryMediumColor};
`;

const HomepageExhibitorButton = (props) => {
  const {
    exhibitorName,
    icon,
    padText,
    standText,
    buttonClickHandler,
    exhibitor,
    itemIndex,
    hideDivider,
  } = props;

  const itemClick = useCallback(() => {
    buttonClickHandler(itemIndex, exhibitor);
  }, [buttonClickHandler, exhibitor, itemIndex]);

  return (
    <HomepageExhibitorButtonContainer $hideBottomBorder={hideDivider} onClick={itemClick}>
      <InfoContainerWrapper>
        <LogoNameWrapper>
          {icon && <ExhibitorLogo $imageUrl={icon} />}
          {!icon && <EmptyExhibitorLogo />}
          <TextualInfoContainer>
            <CustomH4>{exhibitorName}</CustomH4>
          </TextualInfoContainer>
        </LogoNameWrapper>
        {!!padText.length && !!standText.length && (
          <HorizontalExhibitorLocationDetail
            padText={padText}
            standText={standText}
            iconSize="60px"
          />
        )}
      </InfoContainerWrapper>
      <IconColor>
        <TotemIcon icon="Chevron-Right" size="60px" />
      </IconColor>
    </HomepageExhibitorButtonContainer>
  );
};

HomepageExhibitorButton.propTypes = {
  exhibitorName: PropTypes.string.isRequired,
  icon: PropTypes.string,
  padText: PropTypes.string,
  standText: PropTypes.string,
  buttonClickHandler: PropTypes.func.isRequired,
  itemIndex: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  exhibitor: PropTypes.object.isRequired,
  hideDivider: PropTypes.bool,
};

HomepageExhibitorButton.defaultProps = {
  icon: null,
  padText: '',
  standText: '',
  hideDivider: false,
};

export default HomepageExhibitorButton;
