/* eslint-disable import/extensions */
import totemConfigSlice from './totem-config-slice';
import totemEventsSlice from './totem-events-slice';
import totemHomeSlice from './totem-home-slice';
import totemExhibitorsSlice from './totem-exhibitors-slice';
import scrollRestoration from './scroll-restoration-slice';

export default {
  totemConfig: totemConfigSlice,
  totemHome: totemHomeSlice,
  totemExhibitors: totemExhibitorsSlice,
  totemEvents: totemEventsSlice,
  scrollRestoration,
};
