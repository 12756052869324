import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { H4 } from '../components/headings';
import { logAnalytic } from '../store/totem-config-slice';
import { analyticsEvents } from '../common/analytics-constants';

const InfomobilityContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  justify-content: ${(props) => (!props.$loadedContent ? 'center' : 'flex-start')};
  align-items: ${(props) => (!props.$loadedContent ? 'center' : 'flex-start')};
`;

const InfoIframe = styled.iframe`
  /* width: 25%;
  height: 25%;
  transform: scale(4);
  transform-origin: 0 0; */
  display: ${(props) => (props.$visible ? 'flex' : 'none')};;
  width: 50%;
  height: 50%;
  transform: scale(2);
  transform-origin: 0 0;
`;

const itLink = 'https://fieramilano.kotukodev.it/#/dashboard?lang=it-IT';
const enLink = 'https://fieramilano.kotukodev.it/#/dashboard?lang=en-EN';

const Infomobility = ({ accessibilityActive }) => {
  const { t, i18n } = useTranslation('common');
  const [loadedFrame, setLoadedFrame] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logAnalytic({ eventName: analyticsEvents.PAGE_INFOMOBILITY }));
  }, [dispatch]);

  const pickRightLink = useCallback(() => {
    if (i18n.language === 'it') {
      return itLink;
    }
    return enLink;
  }, [i18n.language]);

  return (
    <InfomobilityContainer $loadedContent={loadedFrame}>
      {!loadedFrame && <H4>{t('pages.loading')}</H4>}
      <InfoIframe
        key={pickRightLink()}
        $visible={loadedFrame}
        title="Infomobility"
        src={pickRightLink()}
        onLoad={() => setLoadedFrame(true)}
        sandbox="allow-scripts allow-same-origin"
      />
    </InfomobilityContainer>
  );
};

Infomobility.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
};

Infomobility.defaultProps = {};

export default Infomobility;
