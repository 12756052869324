import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TotemIcon from './icon';

const ExhibitorLocationContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ExhibitorLocationTextualData = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  
  /*p:nth-child(2) {
    margin-top: 10px;
  }*/
`;

const BoldParagraph = styled.p`
  font-weight: bold;
`;

const ExhibitorLocationDetail = ({
  className, padText, standText, iconSize,
}) => (
  <ExhibitorLocationContainer className={className}>
    <TotemIcon
      icon="Pin-mappa"
      color="black"
      size={iconSize}
      text-align="center"
    />
    <ExhibitorLocationTextualData>
      <BoldParagraph>{padText}</BoldParagraph>
      <p>{standText}</p>
    </ExhibitorLocationTextualData>
  </ExhibitorLocationContainer>
);

ExhibitorLocationDetail.propTypes = {
  padText: PropTypes.string.isRequired,
  standText: PropTypes.string.isRequired,
  iconSize: PropTypes.string,
};

ExhibitorLocationDetail.defaultProps = {
  iconSize: '50px',
};

export default ExhibitorLocationDetail;
