import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  configurationPoisListSelector, logAnalytic, servicesSelectedPoiSelector,
  updateSelectedService,
} from '../store/totem-config-slice';
import TotemLeftPanel from '../components/totem-left-panel';
import TotemRightPanel from '../components/totem-right-panel';
import ServiceListButton from '../components/service-list-button';
import TotemMaps from '../components/totem-maps';
import { analyticsEvents, eventsDescriptions } from '../common/analytics-constants';

const ServicesContainer = styled.div`
  display: flex;
`;

const ServicesListWrapper = styled.div`
  margin-top: 140px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Services = ({ accessibilityActive, pageRestoreButtonHandler }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation('service-page');
  const poiSelector = useCallback(configurationPoisListSelector, []);
  const poiList = useSelector(poiSelector, shallowEqual);
  const selectedService = useSelector(servicesSelectedPoiSelector);

  useEffect(() => {
    dispatch(logAnalytic({ eventName: analyticsEvents.PAGE_SERVICES }));
  }, [dispatch]);

  const buttonClickHandler = useCallback(
    (ev) => {
      if (poiList && poiList.length > 0) {
        const { serviceIndex } = ev.currentTarget.dataset;
        const currService = poiList.find((item) => item.iconName === serviceIndex);
        dispatch(updateSelectedService(currService.id));
        dispatch(logAnalytic({
          eventName: analyticsEvents.CLICK_SERVICE_BUTTON,
          eventParams: {
            [eventsDescriptions.CLICK_SERVICE_BUTTON.serviceName]:
              currService?.title?.it ?? currService.id,
          },
        }));
      }
    },
    [dispatch, poiList],
  );

  const renderServicesList = useMemo(
    () => poiList.map(
      (item) => (
        <ServiceListButton
          key={item.iconName}
          icon={item.iconName}
          description={item.title[i18n.language]}
          isActive={
              item.id === selectedService || selectedService === -1
            }
          buttonClickHandler={buttonClickHandler}
        />
      ),
    ),
    [
      buttonClickHandler,
      i18n.language,
      selectedService,
      poiList,
    ],
  );

  return (
    <ServicesContainer>
      <TotemLeftPanel accessibilityActive={accessibilityActive}>
        <TotemMaps
          accessibilityActive={accessibilityActive}
          isServicesPage
          restoreButtonCLickHandler={pageRestoreButtonHandler}
        />
      </TotemLeftPanel>
      <TotemRightPanel
        accessibilityActive={accessibilityActive}
        showSearchBar={false}
      >
        <ServicesListWrapper>
          {renderServicesList}
        </ServicesListWrapper>
      </TotemRightPanel>
    </ServicesContainer>
  );
};

Services.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
  pageRestoreButtonHandler: PropTypes.func.isRequired,
};

Services.defaultProps = {};

export default Services;
