import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import TotemLeftPanel from '../components/totem-left-panel';
import TotemRightPanel from '../components/totem-right-panel';
import HomepageExhibitorButton from '../components/homepage-exhibitor-button';
import {
  configExhibitorsStateSelector,
  configExhibitorsStringFilterValueStateSelector,
  configNotFilteredExhibitorsStateSelector,
  configurationBrandsAsExhibitor, logAnalytic,
  resetExhibitorsFilter,
  setStringFilter,
} from '../store/totem-config-slice';
import { changeHomeState, indexStateSelector } from '../store/scroll-restoration-slice';
import TotemMaps from '../components/totem-maps';
import Button from '../components/button';
import { H4 } from '../components/headings';
import TotemKeyboard from '../components/totem-keyboard';
import { analyticsEvents, eventsDescriptions } from '../common/analytics-constants';

const HomeContainer = styled.div`
  display: flex;
`;

const NoExhibitorFoundContainer = styled.div`
  display: flex;
  padding: 36px;
  flex-direction: column;
  gap: 35px;
  align-items: center;
  text-align: center;
`;

const NoExhibitorFoundTitle = styled(H4)`
  margin-top: 74px;
`;

const NoExhibitorFoundMessage = styled.p`
  margin-bottom: 25px;
`;

const Home = ({ accessibilityActive, pageRestoreButtonHandler }) => {
  const history = useHistory();
  const { t } = useTranslation('home-pages');
  const dispatch = useDispatch();
  const rightPanelRef = useRef(null);

  const homeExhibitors = useSelector(configExhibitorsStateSelector);
  const fullExhibitorsList = useSelector(configNotFilteredExhibitorsStateSelector, shallowEqual);
  const scrollRestorationIndex = useSelector(indexStateSelector);
  const reduxExhibitorQuery = useSelector(configExhibitorsStringFilterValueStateSelector);
  const brandsAsExhibitor = useSelector(configurationBrandsAsExhibitor);
  const [exhibitorQuery, setExhibitorQuery] = useState(reduxExhibitorQuery);
  const [keyboardOpen, setKeyboardOpen] = useState(false);

  useEffect(() => {
    dispatch(logAnalytic({ eventName: analyticsEvents.PAGE_HOME }));
  }, [dispatch]);

  const homeClickHandler = useCallback(
    (index, { id, exhibitorId }) => {
      const correctId = brandsAsExhibitor ? exhibitorId : id;
      const newPath = `${history.location.pathname}/detail/${encodeURIComponent(correctId)}`;
      history.push(newPath.replace('//', '/'));
      const realIndex = fullExhibitorsList.findIndex((item) => item.id === id);
      dispatch(changeHomeState(realIndex));
    },
    [brandsAsExhibitor, dispatch, fullExhibitorsList, history],
  );

  const logHomeSearchValue = useCallback(
    debounce(
      (value) => {
        dispatch(logAnalytic({
          eventName: analyticsEvents.SEARCH_HOME,
          eventParams: {
            [eventsDescriptions.SEARCH_HOME.searchValue]: value,
          },
        }));
      },
      1500,
    ),
    [],
  );

  const searchInputChange = useCallback((ev) => {
    setExhibitorQuery(ev.target.value);
    dispatch(setStringFilter(ev.target.value));
    logHomeSearchValue(ev.target.value);
  }, [dispatch, logHomeSearchValue]);

  const performStringButtonHandler = useCallback(() => {
    dispatch(setStringFilter(exhibitorQuery));
    logHomeSearchValue(exhibitorQuery);
  }, [dispatch, exhibitorQuery, logHomeSearchValue]);

  const handleBackwardRecovery = useCallback(() => {
    dispatch(resetExhibitorsFilter());
  }, [dispatch]);

  const closeKeyBoardHandler = useCallback(() => {
    setKeyboardOpen(false);
  }, []);

  const keyBoardInputChange = useCallback((value) => {
    let toSave = exhibitorQuery + value;
    if (value === '⇦') {
      toSave = exhibitorQuery.substring(0, exhibitorQuery.length - 1);
    }
    if (value === 'cancel') {
      toSave = '';
    }
    if (value === 'enter') {
      toSave = toSave.substring(0, toSave.length - 5);
    }
    setExhibitorQuery(toSave);
    dispatch(setStringFilter(toSave));
    logHomeSearchValue(toSave);
  }, [dispatch, exhibitorQuery, logHomeSearchValue]);

  const openKeyBoardEvent = useCallback(() => {
    setKeyboardOpen(true);
  }, []);

  const renderVirtuosoItem = useCallback(
    (index, data) => (
      <HomepageExhibitorButton
        key={data.id}
        itemIndex={index}
        icon={data.logoUrl}
        exhibitorName={data.companyName}
        // padText={t('pavillion', { pads: data.pads[0].id })}
        // standText={t('stand', { stands: data.pads[0].stand.join(' ') })}
        buttonClickHandler={homeClickHandler}
        exhibitor={data}
      />
    ),
    [homeClickHandler],
  );

  const virtuoso = React.useRef(null);

  useEffect(() => {
    dispatch(resetExhibitorsFilter());
    if (scrollRestorationIndex.homeState) {
      setTimeout(() => {
        virtuoso.current.scrollToIndex({
          index: scrollRestorationIndex.homeState,
          behavior: 'smooth',
        });
        // dispatch(changeHomeState(false));
      }, 100);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HomeContainer>
      <TotemLeftPanel accessibilityActive={accessibilityActive}>
        <TotemMaps
          accessibilityActive={accessibilityActive}
          isServicesPage={false}
          restoreButtonCLickHandler={pageRestoreButtonHandler}
        />
      </TotemLeftPanel>
      <TotemRightPanel
        accessibilityActive={accessibilityActive}
        showSearchBar
        searchTitle={t('searchLabel')}
        onSearchInputChangeHandler={searchInputChange}
        onPerformSearchClickHandler={performStringButtonHandler}
        searchValue={exhibitorQuery}
        onInputFocusHandler={openKeyBoardEvent}
      >
        {homeExhibitors.length > 0 && (
          <Virtuoso
            ref={virtuoso}
            data={homeExhibitors}
            style={{
              overflowX: 'hidden',
            }}
            totalCount={homeExhibitors.length}
            itemContent={renderVirtuosoItem}
          />
        )}
        {
          homeExhibitors.length === 0 && (
            <NoExhibitorFoundContainer>
              <NoExhibitorFoundTitle>{t('no_exhibitor_found_title')}</NoExhibitorFoundTitle>
              <NoExhibitorFoundMessage>{t('no_exhibitor_found_message')}</NoExhibitorFoundMessage>
              <Button variant="contained" onClick={handleBackwardRecovery}>
                {t('no_exhibitor_found_button')}
              </Button>
            </NoExhibitorFoundContainer>
          )
        }
        <TotemKeyboard
          isKeyboardOpen={keyboardOpen}
          onKeyboardCloseHandler={closeKeyBoardHandler}
          onKeyboardButtonPressed={keyBoardInputChange}
          itemToIgnoreRef={rightPanelRef.current}
        />
      </TotemRightPanel>
    </HomeContainer>
  );
};

Home.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
  pageRestoreButtonHandler: PropTypes.func.isRequired,
};

Home.defaultProps = {};

export default Home;
