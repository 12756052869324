import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'i18next';
import generateUrl from '../../generate-url';

export default createAsyncThunk(
  'configuration/getTotemConfiguration',
  ({ totemId }: { totemId: string }, thunkAPI) => fetch(generateUrl(`/totem/${totemId}`), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': i18n.language ?? 'en',
      Authorization: process.env.REACT_APP_API_KEY ?? '',
    },
  }).then((res) => {
    switch (res.status) {
      case 200:
        return res.json();
      case 500:
        throw new Error(res.statusText);
      default:
        throw new Error('Server Error');
    }
  }),
);
