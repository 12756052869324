import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import {
  reduce,
  filter,
  has,
  isEmpty,
  sortBy,
} from 'lodash';
import getTotemConfigurationAction from './actions/get-totem-configuration';
import getTotemExhibitionDataAction from './actions/get-totem-exhibition-data';
import logFirebaseAnalyticEvent from './actions/log-firebase-analytic-event';
import {
  filterServiceList,
  triggerCalculatePathFunc,
} from './actions/services-action';
import {
  clearAllUserInteraction,
  clearUserInteractionOnEventsPage,
  clearUserInteractionOnExhibitorsPage,
} from './actions/reset-totem-config';
import {
  applyExhibitorsCategoryFilter,
  applyExhibitorsCountryFilter,
  applyExhibitorsPadsFilter,
  applyExhibitorsStringFilter,
  applySectorFilter,
} from './actions/exhibitors-filter';
import {
  applyEventsCategoryFilter,
  applyEventsDatesFilter,
  applyEventsPadsFilter,
  applyEventsStringFilter,
} from './actions/events-filter';

export const allVisiblePage = [
  'information',
  'exhibitor',
  'event',
  'home',
  'service',
  'infomobility',
];

// eslint-disable-next-line no-shadow
export enum StandLabelStyle {
  COORDINATE = 'coordinate',
  ASSEGNATARIO = 'assegnatario',
}

type TotemConfigurationResponse = {
  appTotemConfig: any;
  editionTotemConfig: any;
};

export type TotemExhibitorsFilters = {
  searchExhibitorsFilter: string;
  padsExhibitorsFilter: Array<string>;
  categoriesFilter: Array<string>;
  countryExhibitorsFilter: Array<string>;
  sectorFilter: string | null | undefined;
};

export type TotemEventsFilters = {
  searchEventFilter: string;
  dateEventFilter: Array<string>;
  padsEventFilter: Array<string>;
  categoriesFilter: Array<string>;
};

export type TotemConfigurationState = {
  configurationLoading: boolean;
  configuration: TotemConfigurationResponse;
  configurationError: any;

  dataLoading: boolean;

  exhibitors: Array<any>;
  exhibitorsCategories: Array<any>;
  exhibitorsSectors: Array<any>;
  brands: Array<any>;
  clustersList: Array<any>;
  pavilionList: Array<string>;
  countryList: Array<string>;
  filteredExhibitors: Array<any>;
  exhibitorsPageFilters: TotemExhibitorsFilters;

  events: Array<any>;
  eventsCategories: Array<any>;
  eventsPavilionList: Array<string>;
  eventsDateList: Array<string>;
  filteredEvents: Array<any>;
  eventsPageFilters: TotemEventsFilters;

  poiList: Array<any>;
  pavilion: Array<any>;
  stands: Array<any>;
  filteredPoi: Array<any>;
  selectedPoi: number;
  drawExhibitorPath: boolean;

  dataError: any;

  utilsApiError: string | null;

  sessionId: string;
};

export type ExhibitorItem = {
  id: string;
  exhibitionCode: string;
  originalEditionCode: string;
  companyName: string;
  logoUrl?: string;
  country: string;
  eboothId: number;
  state: string;
  zip?: string;
  city: string;
  address: string;
  brands: Brand[];
  categories: any[];
  groups: any[];
  commodities: Commodity[];
  pads: Pad[];
  coExhibitors: any[];
  type: string;
  marketplaceUrl?: string;
};

export type Brand = {
  id: string;
  type: string;
};

export type Commodity = {
  product: string;
};

export type Pad = {
  id: string;
  stand: string[];
};

export interface BrandItem {
  name: string;
  phone?: string;
  email?: string;
  web?: string;
  fax?: string;
  address?: string;
  city: City;
  // eslint-disable-next-line ,camelcase
  country_code: string;
  description: Description;
  id: string;
  type: string;
  logoUrl?: string;
}

export interface City {
  name?: string;
  code?: string;
  cap?: string;
}

export interface Description {
  it?: string;
  en?: string;
}

// ------------------------------ FUNCTION Filter cluster array keeping only
// ------------------------------ the items with a cluster group

const filterClusters = (categories: Array<any>) => filter(
  categories,
  (item: any) => item.clusterGroup,
);

// ------------------------------ FUNCTION Get usable cluster array

const getClusters = (categories: Array<any>) => reduce(
  categories,
  (acc: Array<any>, item: any) => {
    const tmp = acc.find(
      (accItem: any) => accItem.oldId === item.clusterGroup?.oldId || false,
    );
    if (!tmp) {
      const clusterToAdd = {
        ...item.clusterGroup,
        data: [{ ...item }],
      };
      acc.push(clusterToAdd);
    } else {
      tmp.data.push(item);
    }
    return acc;
  },
  [],
);

// ------------------------------ FUNCTION Get array of Pavilion taken from Exhibitors

const getExhibitorsPavilionList = (exhibitors: Array<any>) => reduce(
  exhibitors,
  (acc: Array<any>, item: any) => {
    if (item.type && item.type === 'representedHouse') {
      return acc;
    }
    if (item.pads[0].id.trim().length > 0 && !acc.includes(item.pads[0].id)) {
      acc.push(item.pads[0].id);
    }
    return acc;
  },
  [],
)
  .sort((a, b) => a - b);

// ------------------------------ FUNCTION Get array of country got from Exhibitors

const getCountryList = (exhibitors: Array<any>) => reduce(
  exhibitors,
  (acc: Array<any>, item: any) => {
    if (item.country && !acc.includes(item.country)) {
      acc.push(item.country);
    }
    return acc;
  },
  [],
);

// ------------------------------ FUNCTION Get array of pavilions got from Events

const getEventsPavilionList = (events: Array<any>) => reduce(
  events,
  (acc: Array<any>, item: any) => {
    if (!item.pad) {
      return acc;
    }
    if (item.pad === 'Digital') {
      return acc;
    }
    const found = acc.find((newItem: string) => newItem === item.pad);
    if (found) {
      return acc;
    }
    acc.push(item.pad);
    return acc;
  },
  [],
);

// ------------------------------ FUNCTION Get array of dates got from Events

const getEventsDatesList = (events: Array<any>) => reduce(
  events,
  (acc: Array<any>, item: any) => {
    if (!item.startDate) {
      return acc;
    }
    const newDate = new Date(item.startDate).toLocaleDateString('it');
    const found = acc.find((newItem: string) => newItem === newDate);
    if (found) {
      return acc;
    }
    acc.push(newDate);
    return acc;
  },
  [],
);

// ------------------------------ FUNCTION Filter editionConfig.json
// ------------------------------ poi that should be visible in totem

const takeOnlyVisiblePois = (poisList: Array<any>) => filter(
  poisList,
  (poi) => !poi.hideInTotem,
);

// ------------------------------ FUNCTION Filter GeoJson data which contain geometry property

const keepOnlyTotemValidData = (geoJsonData: any) => {
  if (isEmpty(geoJsonData)) {
    return geoJsonData;
  }
  return {
    ...geoJsonData,
    features: filter(
      geoJsonData?.features ?? [],
      (feature) => has(feature, 'geometry'),
    ),
  };
};

// ------------------------------ FUNCTION merge Exhibitors and Brands as Exhibitors

const mergeExhibitorsAndBrands = (exhibitors: Array<any>, brands: Array<any>) => {
  const brandAsExhibitor = brands.map((brand: BrandItem) => {
    const associatedExhibitor = exhibitors.find((exhib) => exhib.brands?.length
      && exhib.brands.some((b: any) => b.id === brand.id));

    return {
      id: brand.id,
      exhibitionCode: exhibitors[0].exhibitionCode ?? '',
      originalEditionCode: exhibitors[0].originalEditionCode ?? '',
      companyName: brand.name,
      ...(brand.logoUrl && { logoUrl: brand.logoUrl }),
      country: brand.country_code ?? '',
      eboothId: -1,
      state: '-',
      ...(brand.city?.cap && { zip: brand.city?.cap }),
      city: brand.city.name ?? '',
      address: brand.address ?? '',
      brands: [],
      categories: [],
      groups: [],
      commodities: [],
      pads: [...associatedExhibitor.pads],
      coExhibitors: [],
      type: brand.type,
      associatedExhibitorId: associatedExhibitor.id,
      ...(brand.web && { web: brand.web }),
      ...(brand.email && { email: brand.email }),
      ...(brand.phone && { phone: brand.phone }),
    };
  });

  const newExhibitors = [...exhibitors, ...brandAsExhibitor];

  return sortBy(newExhibitors, ['companyName']);
};

// ------------------------------ GET Totem configuration

const getTotemConfigurationPending = (
  state: TotemConfigurationState,
  action: PayloadAction<void>,
): void => {
  state.configurationLoading = true;
};

const getTotemConfigurationFulfilled = (
  state: TotemConfigurationState,
  action: PayloadAction<TotemConfigurationResponse>,
): void => {
  state.configurationLoading = false;
  const lat = Number.parseFloat(action.payload.appTotemConfig.totemPavillion.latitude.replace(',', '.'));
  const long = Number.parseFloat(action.payload.appTotemConfig.totemPavillion.longitude.replace(',', '.'));
  if (action.payload.appTotemConfig.totemPavillion) {
    // all this ... to check force CMS data to be used right
    state.configuration.appTotemConfig = {
      ...action.payload.appTotemConfig,
      totemPavillion: {
        ...action.payload.appTotemConfig.totemPavillion,
        latitude: lat,
        longitude: long,
        floor: Number(action.payload.appTotemConfig.totemPavillion.floor) ?? 0,
      },
    };
  } else {
    state.configuration.appTotemConfig = action.payload.appTotemConfig;
  }
  state.configuration.editionTotemConfig = action.payload.editionTotemConfig;
};

const getTotemConfigurationRejected = (
  state: TotemConfigurationState,
  action: PayloadAction<any, string, any, { error: Error }>,
): void => {
  state.configurationLoading = false;
  state.configurationError = action.error;
  console.log(action.error);
};

// ------------------------------ GET All exhibition data

const getTotemExhibitionDataPending = (
  state: TotemConfigurationState,
  action: PayloadAction<void>,
): void => {
  state.dataLoading = true;
};

const getTotemExhibitionDataFulfilled = (
  state: TotemConfigurationState,
  action: PayloadAction<any>,
): void => {
  state.dataLoading = false;
  const [
    exhibitionConfig,
    exhibitorsList,
    exhibitorsCluster,
    exhibitorsCommodities,
    exhibitorsBrands,
    exhibitionEvents,
    exhibitionEventsCategories,
    poiGroundFloor,
    poiFirstFloor,
    pavilionsGroundFloor,
    pavilionsFirstFloor,
    standsGroundFloor,
    standsFirstFloor,
  ] = action.payload;

  state
    .configuration
    .appTotemConfig
    .poiCategoriesConfig = exhibitionConfig.poiCategoriesConfig
      ? takeOnlyVisiblePois(exhibitionConfig.poiCategoriesConfig)
      : [];

  state
    .configuration
    .appTotemConfig
    .showWayFinding = exhibitionConfig.mapWidgetConfiguration?.showWayFinding ?? true;

  state.configuration.appTotemConfig.totemExhibitionPageVisibility = exhibitionConfig
    .totemExhibitionPageVisibility ?? allVisiblePage;

  state.configuration.appTotemConfig.totemStandLabel = exhibitionConfig
    .totemStandLabel ?? StandLabelStyle.COORDINATE;

  state
    .configuration
    .appTotemConfig
    .totemHideBusinessCommunityExhibitorsFilter = exhibitionConfig
      .totemHideBusinessCommunityExhibitorsFilter ?? false;

  state
    .configuration
    .appTotemConfig
    .totemHidePavilionExhibitorsFilter = exhibitionConfig
      .totemHidePavilionExhibitorsFilter ?? false;

  state
    .configuration
    .appTotemConfig
    .totemHideCountryExhibitorsFilter = exhibitionConfig
      .totemHideCountryExhibitorsFilter ?? false;

  if (exhibitionConfig.useBrandsAsExhibitors) {
    state
      .configuration
      .editionTotemConfig
      .useBrandsAsExhibitors = exhibitionConfig.useBrandsAsExhibitors;

    state.exhibitors = exhibitorsBrands.data;
    state.exhibitorsCategories = exhibitorsCluster.data;
    state.exhibitorsSectors = exhibitorsCommodities.data;
    state.brands = exhibitorsBrands.data;
    state.clustersList = getClusters(filterClusters(exhibitorsCluster.data));
    state.pavilionList = getExhibitorsPavilionList(exhibitorsBrands.data);
    state.countryList = getCountryList(exhibitorsBrands.data);
    state.filteredExhibitors = exhibitorsBrands.data;
  } else {
    state.exhibitors = mergeExhibitorsAndBrands(exhibitorsList.data, exhibitorsBrands.data);
    state.exhibitorsCategories = exhibitorsCluster.data;
    state.exhibitorsSectors = exhibitorsCommodities.data;
    state.brands = exhibitorsBrands.data;
    state.clustersList = getClusters(filterClusters(exhibitorsCluster.data));
    state.pavilionList = getExhibitorsPavilionList(exhibitorsList.data);
    state.countryList = getCountryList(exhibitorsList.data);
    state.filteredExhibitors = mergeExhibitorsAndBrands(exhibitorsList.data, exhibitorsBrands.data);
  }

  state.events = exhibitionEvents.data;
  state.eventsCategories = exhibitionEventsCategories.data;
  state.eventsPavilionList = getEventsPavilionList(exhibitionEvents.data);
  state.eventsDateList = getEventsDatesList(exhibitionEvents.data);
  state.filteredEvents = exhibitionEvents.data;

  state.poiList[0] = poiGroundFloor;
  state.poiList[1] = poiFirstFloor;

  state.pavilion[0] = keepOnlyTotemValidData(pavilionsGroundFloor);
  state.pavilion[1] = keepOnlyTotemValidData(pavilionsFirstFloor);

  state.stands[0] = keepOnlyTotemValidData(standsGroundFloor);
  state.stands[1] = keepOnlyTotemValidData(standsFirstFloor);

  state.filteredPoi[0] = poiGroundFloor;
  state.filteredPoi[1] = poiFirstFloor;
};

const getTotemExhibitionDataRejected = (
  state: TotemConfigurationState,
  action: PayloadAction<any, string, any, { error: Error }>,
): void => {
  state.dataLoading = false;
  state.dataError = action.error;
  console.log(action.error);
};

// ------------------------------ REDUCER update state when utils api fail

const setApiFailure = (
  state: TotemConfigurationState,
  action: PayloadAction<string | null>,
): void => {
  state.utilsApiError = action.payload;
};

// ------------------------------ REDUCER SessionId

const setSessionId = (
  state: TotemConfigurationState,
): void => {
  state.sessionId = nanoid();
};

// ------------------------------ SLICE INITIALIZATION

export const totemConfigSlice = createSlice<
  TotemConfigurationState,
  any,
  'totemConfiguration'
>({
  name: 'totemConfiguration',
  initialState: {
    configurationLoading: false,
    configuration: {
      appTotemConfig: {},
      editionTotemConfig: {},
    },
    configurationError: null,

    dataLoading: false,

    exhibitors: [],
    exhibitorsCategories: [],
    exhibitorsSectors: [],
    brands: [],
    clustersList: [],
    pavilionList: [],
    countryList: [],
    filteredExhibitors: [],
    exhibitorsPageFilters: {
      searchExhibitorsFilter: '',
      categoriesFilter: [],
      padsExhibitorsFilter: [],
      countryExhibitorsFilter: [],
      sectorFilter: null,
    },

    events: [],
    eventsCategories: [],
    eventsPavilionList: [],
    eventsDateList: [],
    filteredEvents: [],
    eventsPageFilters: {
      searchEventFilter: '',
      dateEventFilter: [],
      padsEventFilter: [],
      categoriesFilter: [],
    },

    poiList: [],
    pavilion: [],
    stands: [],
    filteredPoi: [],
    selectedPoi: -1,
    drawExhibitorPath: false,

    dataError: null,

    utilsApiError: null,

    sessionId: '',
  },
  reducers: {
    resetTotemConfig: clearAllUserInteraction,

    resetExhibitorsFilter: clearUserInteractionOnExhibitorsPage,
    setSectorFilter: applySectorFilter,
    setStringFilter: applyExhibitorsStringFilter,
    setCategoryFilter: applyExhibitorsCategoryFilter,
    setPadsFilter: applyExhibitorsPadsFilter,
    setCountryFilter: applyExhibitorsCountryFilter,

    resetEventsFilter: clearUserInteractionOnEventsPage,
    setEventStringFilter: applyEventsStringFilter,
    setEventCategoryFilter: applyEventsCategoryFilter,
    setEventDatesFilter: applyEventsDatesFilter,
    setEventPadsFilter: applyEventsPadsFilter,

    updateSelectedService: filterServiceList,
    showRouteToExhibitor: triggerCalculatePathFunc,

    updateUtilsApiError: setApiFailure,
    createNewSessionId: setSessionId,
    logAnalytic: logFirebaseAnalyticEvent,
  },
  extraReducers: {
    [
      (getTotemConfigurationAction.pending as unknown) as string
    ]: getTotemConfigurationPending,
    [
      (getTotemConfigurationAction.fulfilled as unknown) as string
    ]: getTotemConfigurationFulfilled,
    [
      (getTotemConfigurationAction.rejected as unknown) as string
    ]: getTotemConfigurationRejected,
    [
      (getTotemExhibitionDataAction.pending as unknown) as string
    ]: getTotemExhibitionDataPending,
    [
      (getTotemExhibitionDataAction.fulfilled as unknown) as string
    ]: getTotemExhibitionDataFulfilled,
    [
      (getTotemExhibitionDataAction.rejected as unknown) as string
    ]: getTotemExhibitionDataRejected,
  },
});

export const {
  resetTotemConfig,

  resetExhibitorsFilter,
  setSectorFilter,
  setStringFilter,
  setCategoryFilter,
  setPadsFilter,
  setCountryFilter,

  resetEventsFilter,
  setEventStringFilter,
  setEventCategoryFilter,
  setEventDatesFilter,
  setEventPadsFilter,

  updateSelectedService,
  showRouteToExhibitor,

  updateUtilsApiError,

  createNewSessionId,
  logAnalytic,
} = totemConfigSlice.actions;

export default totemConfigSlice.reducer;

// ------------------------------ SELECTOR CONFIG STATE

export const totemConfigStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): TotemConfigurationState => state.totemConfig;

export const totemConfigLoadingSelector = (state: {
  totemConfig: TotemConfigurationState;
}): boolean => state.totemConfig.dataLoading;

export const totemConfigErrorsSelector = (state: {
  totemConfig: TotemConfigurationState;
}): boolean => state.totemConfig.configurationError || state.totemConfig.dataError;

export const configStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): TotemConfigurationResponse => state.totemConfig?.configuration;

export const configurationColorStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): TotemConfigurationState => state
  .totemConfig.configuration?.editionTotemConfig?.colors ?? null;

export const configurationPoisListSelector = (state: {
  totemConfig: TotemConfigurationState;
}): TotemConfigurationState => state
  .totemConfig.configuration?.appTotemConfig?.poiCategoriesConfig ?? [];

export const configurationBrandsAsExhibitor = (state: {
  totemConfig: TotemConfigurationState;
}): boolean => state
  .totemConfig.configuration?.editionTotemConfig?.useBrandsAsExhibitors ?? false;

export const configurationWayFindingEnabled = (state: {
  totemConfig: TotemConfigurationState;
}): boolean => state
  .totemConfig.configuration?.appTotemConfig?.showWayFinding;

export const configurationPagesVisibility = (state: {
  totemConfig: TotemConfigurationState;
}): boolean => state
  .totemConfig.configuration?.appTotemConfig?.totemExhibitionPageVisibility;

export const configurationHideBusinessCommunityExhibitorsFilter = (state: {
  totemConfig: TotemConfigurationState;
}): boolean => state
  .totemConfig.configuration?.appTotemConfig?.totemHideBusinessCommunityExhibitorsFilter;

export const configurationHidePavilionExhibitorsFilter = (state: {
  totemConfig: TotemConfigurationState;
}): boolean => state
  .totemConfig.configuration?.appTotemConfig?.totemHidePavilionExhibitorsFilter;

export const configurationHideCountryExhibitorsFilter = (state: {
  totemConfig: TotemConfigurationState;
}): boolean => state
  .totemConfig.configuration?.appTotemConfig?.totemHideCountryExhibitorsFilter;

// ------------------------------ SELECTOR exhibitors

export const configNotFilteredExhibitorsStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.exhibitors;

export const configExhibitorsStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.filteredExhibitors;

export const configExhibitorsCategoriesStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.exhibitorsCategories;

export const configExhibitorsSectorStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.exhibitorsSectors;

export const configBrandsStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.brands;

export const configExhibitorsClusterListStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.clustersList;

export const configExhibitorsPavilionListStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.pavilionList;

export const configExhibitorsCountryListStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.countryList;

export const configFilteredExhibitorsStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.filteredExhibitors;

export const configExhibitorsFiltersStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): TotemExhibitorsFilters => state.totemConfig.exhibitorsPageFilters;

export const configExhibitorsStringFilterValueStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): string => state.totemConfig.exhibitorsPageFilters.searchExhibitorsFilter;

// ------------------------------ SELECTOR events

export const configEventsStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.events;

export const configEventsCategoriesStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.eventsCategories;

export const configEventsPavilionStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.eventsPavilionList;

export const configEventsDateListStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.eventsDateList;

export const configFilteredEventsStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.filteredEvents;

export const configEventsFiltersStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): TotemEventsFilters => state.totemConfig.eventsPageFilters;

// ------------------------------ SELECTOR services

export const filteredServicesStateSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.filteredPoi;

export const servicesPavilionsSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.pavilion;

export const servicesPavilionsCentersSelector = (state: {
  totemConfig: TotemConfigurationState;
}): any => {
  const centers: any = {};
  state.totemConfig.poiList?.forEach((poiByFloor) => {
    poiByFloor.features?.forEach((feature: any) => {
      if (feature.properties.category === 3) {
        centers[feature.properties.pad] = {
          id: feature.properties && feature.properties.poiTrackheraId,
          longitude:
            feature.geometry
            && feature.geometry.coordinates
            && feature.geometry.coordinates[0],
          latitude:
            feature.geometry
            && feature.geometry.coordinates
            && feature.geometry.coordinates[1],
        };
      }
    });
  });
  return centers;
};

export const servicesStandsSelector = (state: {
  totemConfig: TotemConfigurationState;
}): Array<any> => state.totemConfig.stands;

export const servicesSelectedPoiSelector = (state: {
  totemConfig: TotemConfigurationState;
}): number => state.totemConfig.selectedPoi;

export const servicesDrawExhibitorPathSelector = (state: {
  totemConfig: TotemConfigurationState;
}): boolean => state.totemConfig.drawExhibitorPath;

// ------------------------------ SELECTOR utilsAPI

export const utilsApiErrorSelector = (state: {
  totemConfig: TotemConfigurationState;
}): string | null => state.totemConfig.utilsApiError;

// ------------------------------ SELECTOR session ID

export const sessionIdSelector = (state: {
  totemConfig: TotemConfigurationState;
}): string | null => state.totemConfig.sessionId;
