import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  configStateSelector,
  createNewSessionId,
  totemConfigLoadingSelector,
} from '../store/totem-config-slice';
import TotemSlider from '../components/totem-slider';
import Button from '../components/button';
import Icon from '../components/icon';
import theme from '../theme';

const AdvPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.theme.secondaryDarkColor};
`;

const SwiperContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 94%;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 6%;
`;

const ImageItem = ({ item }) => (
  <img src={item.screenSaverAdv} alt={`banner of ${item.exhibitorId}`} />
);

const AdvScreenSaverPage = ({ screenSaverButtonHandler }) => {
  const [t] = useTranslation('adv-page');
  const totemConfig = useSelector(configStateSelector);
  const isTotemLoading = useSelector(totemConfigLoadingSelector);
  const isTotemDataLoading = useSelector(totemConfigLoadingSelector);
  const history = useHistory();
  const banners = totemConfig.appTotemConfig?.advertisement ?? null;
  const totemId = totemConfig.appTotemConfig?.totemId;
  const dispatch = useDispatch();

  const slideClickHandler = useCallback((item) => {
    if (!isTotemLoading && item && item.exhibitorId) {
      history.push(`/${totemId}/exhibitor/exhibitors/undefined/exhibitor/${item.exhibitorId}`);
    }
  }, [history, isTotemLoading, totemId]);

  const emptyFunc = useCallback(() => {
    console.log('Totem is still loading');
  }, []);

  useEffect(() => {
    dispatch(createNewSessionId());
  }, [dispatch]);

  return (
    <AdvPageContainer>
      <SwiperContainer>
        {banners && (
          <TotemSlider
            sliderContentHeight="2560px"
            slideComponent={ImageItem}
            slideItems={banners}
            onSlideClick={!isTotemDataLoading ? screenSaverButtonHandler : emptyFunc}
          />
        )}
      </SwiperContainer>
      <ActionButtonContainer>
        <Button
          $fontSize="h3"
          variant="contained"
          onClick={screenSaverButtonHandler}
          endIcon={<Icon icon="Chevron-Right" size={theme.fontSize.bodyFont} />}
          disabled={isTotemDataLoading}
        >
          {t('start_now_button')}
        </Button>
      </ActionButtonContainer>
    </AdvPageContainer>
  );
};

AdvScreenSaverPage.propTypes = {
  screenSaverButtonHandler: PropTypes.func.isRequired,
};

AdvScreenSaverPage.defaultProps = {};

export default AdvScreenSaverPage;
