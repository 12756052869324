import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { configurationStateSelector } from '../store/totem-configuration-slice';
import useTotemCustomSelector from '../common/use-totem-selector';
import TotemIcon from './icon';
import { H3 } from './headings';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 600px;
  filter: ${(props) => (props.$screenSaverActive ? 'brightness(0.1)' : 'unset')};
`;

const ContainerFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FirstRowContainer = styled.div`
  display: flex;
`;

const HeaderLeftColumn = styled(ContainerFlexColumn)`
  width: 1560px;
`;

const HeaderRightColumn = styled(ContainerFlexColumn)`
  width: 100%;
`;

const TotemWeatherWidget = styled.iframe`
  width: 600px;
  height: 380px;
`;

const TotemSubtitle = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${(props) => props.theme.primaryBarColor};
  color: white;
  height: 100%;
  padding: 28px 50px;
`;

const CapitalizedDate = styled(H3)`
  text-transform: capitalize;
  flex: 1;
`;

const MeteoItem = styled(H3)`
  flex: 1;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TotemHeader = ({ screenSaverActive }) => {
  const [loader, totemConfig] = useTotemCustomSelector(
    configurationStateSelector,
    'configurationLoading',
    'configuration',
  );
  const { banners } = totemConfig.editionTotemConfig;
  const [currentTime, setCurrentTime] = useState(new Date());
  const { i18n } = useTranslation('common');
  const dateFormatter = {
    weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit',
  };
  const timeFormatter = { timeStyle: 'short' };

  useEffect(() => {
    const timerId = setInterval(
      () => setCurrentTime(new Date()),
      1000,
    );

    return () => clearInterval(timerId);
  }, [setCurrentTime]);

  return (
    <HeaderContainer $screenSaverActive={screenSaverActive}>
      {banners && (
        <>
          <FirstRowContainer>
            <HeaderLeftColumn>
              <img src={banners.coverEditionImage} alt="cover edition img" />
            </HeaderLeftColumn>
            <HeaderRightColumn>
              <img src={banners.organizerImage} alt="organizer img" />
              <img
                src="https://via.placeholder.com/600x260.png?text=Meteo"
                alt="meteo_iframe"
              />
            </HeaderRightColumn>
          </FirstRowContainer>
          <TotemSubtitle>
            <CapitalizedDate>
              {currentTime.toLocaleDateString(i18n.language, dateFormatter)}
            </CapitalizedDate>
            <H3>
              {currentTime.toLocaleTimeString(i18n.language, timeFormatter)}
            </H3>
            <MeteoItem>
              30°
              <TotemIcon size="50px" icon="Giorno-sereno" />
            </MeteoItem>
          </TotemSubtitle>
        </>
      )}
    </HeaderContainer>
  );
};

TotemHeader.propTypes = {
  screenSaverActive: PropTypes.bool.isRequired,
};

TotemHeader.defaultProps = {};

export default TotemHeader;
