import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AccessibilityIndicator from './accessibility-indicator';
import TotemBreadcrumbs from './totem-breadcrumbs';
import TotemBackButton from './totem-back-button';

const ChildrenContainer = styled.div`
  position: relative;
  height: ${(props) => (props.$accessible ? '1307px' : '2610px')};
`;

const ChildrenWrapper = styled.div`
  height: ${(props) => (props.$accessible ? '1225px' : '2515px')};
  overflow-y: auto;
  overflow-x: hidden;
`;

const NavigationStickerButtons = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 50px;
  z-index: 3;
  opacity: 0.9;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

const PageContainer = (props) => {
  const {
    accessibilityActive,
    children,
    labelKey,
    backButton,
    backButtonClick,
    restoreButton,
    restoreButtonClick,
  } = props;

  return (
    <>
      {accessibilityActive && <AccessibilityIndicator />}
      <ChildrenContainer $accessible={accessibilityActive}>
        {!accessibilityActive && <TotemBreadcrumbs labelKey={labelKey} />}
        <NavigationStickerButtons>
          {backButton && <TotemBackButton clickHandler={backButtonClick} />}
          {restoreButton && <TotemBackButton type="restore" clickHandler={restoreButtonClick} />}
        </NavigationStickerButtons>
        <ChildrenWrapper $accessible={accessibilityActive}>
          {children}
        </ChildrenWrapper>
        {accessibilityActive && <TotemBreadcrumbs labelKey={labelKey} />}
      </ChildrenContainer>
    </>
  );
};

PageContainer.propTypes = {};

PageContainer.defaultProps = {};

export default PageContainer;
