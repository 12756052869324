import styled from 'styled-components';

export const H2 = styled.h2`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.h2.fontSize};
  font-weight: ${(props) => props.theme.h2.fontWeight};
`;

export const H3 = styled.h3`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.h3.fontSize};
  font-weight: ${(props) => props.theme.h3.fontWeight};
`;

export const H4 = styled.h4`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.h4.fontSize};
  font-weight: ${(props) => props.theme.h4.fontWeight};
`;

export const BodyText = styled.span`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.bodyFont.fontSize};
  font-weight: ${(props) => props.theme.bodyFont.fontWeight};
`;

export const BodyBoldText = styled(BodyText)`
  font-weight: ${(props) => props.theme.bodyBoldFont.fontWeight};
`;
