import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { configurationStateSelector } from '../store/totem-configuration-slice';
import useTotemCustomSelector from '../common/use-totem-selector';
import TotemSlider from '../components/totem-slider';
import Button from '../components/button';
import Icon from '../components/icon';
import theme from '../theme';

const AdvPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.theme.secondaryDarkColor};
`;

const SwiperContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95%;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageItem = ({ item }) => (
  <img src={item.screenSaverAdv} alt={`banner of ${item.exhibitorId}`} />
);

const AdvScreenSaverPage = ({ screenSaverButtonHandler }) => {
  const [t] = useTranslation('adv-page');
  const [loader, totemConfig] = useTotemCustomSelector(
    configurationStateSelector,
    'configurationLoading',
    'configuration',
  );
  const banners = totemConfig.appTotemConfig?.advertisement ?? null;

  const slideClickHandler = useCallback((item) => {
    console.log(item);
  }, []);

  return (
    <AdvPageContainer>
      <SwiperContainer>
        {banners && (
          <TotemSlider
            sliderContentHeight="2200px"
            slideComponent={ImageItem}
            slideItems={banners}
            onSlideClick={slideClickHandler}
          />
        )}
      </SwiperContainer>
      <ActionButtonContainer>
        <Button onClick={() => screenSaverButtonHandler()} endIcon={<Icon icon="Chevron-Right" size={theme.fontSize.bodyFont} />}>{t('start_now_button')}</Button>
      </ActionButtonContainer>
    </AdvPageContainer>
  );
};

AdvScreenSaverPage.propTypes = {
  screenSaverButtonHandler: PropTypes.func.isRequired,
};

AdvScreenSaverPage.defaultProps = {};

export default AdvScreenSaverPage;
