import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TotemDivider from './divider';
import { configurationStateSelector } from '../store/totem-configuration-slice';
import useTotemCustomSelector from '../common/use-totem-selector';
import TotemSlider from './totem-slider';
import FlagButton from './totem-icon-button';

const FooterContainer = styled.div`
  display: flex;
  height: 440px;
  width: 100%;
`;

const ContainerFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderLeftColumn = styled(ContainerFlexColumn)`
  width: 1560px;
  filter: ${(props) => (props.$screenSaverActive ? 'brightness(0.1)' : 'unset')};
`;

const TotemFooterCarousel = styled.div`
  width: 100%;
`;

const HeaderRightColumn = styled(ContainerFlexColumn)`
  width: 100%;
  justify-content: space-evenly;
  background-color: ${(props) => props.theme.secondaryDarkColor};
`;

const FlagButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const ImageItem = ({ item }) => (
  <img src={item.bannerAdv} alt={`banner of ${item.exhibitorId}`} />
);

const TotemFooter = ({
  screenSaverActive,
  onLanguageChange,
  onAccessibilityButtonClick,
  currentSelectedLanguage,
  accessibilityStatus,
  accessibilityVisible,
}) => {
  const [loader, totemConfig] = useTotemCustomSelector(
    configurationStateSelector,
    'configurationLoading',
    'configuration',
  );
  const banners = totemConfig.appTotemConfig?.advertisement ?? null;

  const slideClickHandler = useCallback(
    (item) => {
      if (screenSaverActive) {
        return;
      }
      console.log(item);
    },
    [screenSaverActive],
  );

  return (
    <FooterContainer>
      <HeaderLeftColumn $screenSaverActive={screenSaverActive}>
        <TotemFooterCarousel>
          {banners && (
            <TotemSlider
              sliderContentHeight="440px"
              slideComponent={ImageItem}
              slideItems={banners}
              onSlideClick={slideClickHandler}
              allowTouchMove={!screenSaverActive}
            />
          )}
        </TotemFooterCarousel>
      </HeaderLeftColumn>

      <HeaderRightColumn>
        <FlagButtonContainer>
          <FlagButton
            buttonClick={onLanguageChange}
            isUnactive={currentSelectedLanguage === 'en'}
            iconName="BandieraITA"
            size="84px"
            value="it"
            showBackground={false}
          />
          <FlagButton
            buttonClick={onLanguageChange}
            isUnactive={currentSelectedLanguage === 'it'}
            iconName="BandieraENG"
            size="84px"
            value="en"
            showBackground={false}
          />
        </FlagButtonContainer>
        <TotemDivider variant="middle" />
        <FlagButtonContainer>
          {accessibilityVisible && (
            <FlagButton
              buttonClick={onAccessibilityButtonClick}
              activeLightColor={accessibilityStatus}
              size="84px"
              value="accessibility"
              iconName="Sedia-a-rotelle"
            />
          )}
        </FlagButtonContainer>
      </HeaderRightColumn>
    </FooterContainer>
  );
};

TotemFooter.propTypes = {
  screenSaverActive: PropTypes.bool.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  onAccessibilityButtonClick: PropTypes.func.isRequired,
  currentSelectedLanguage: PropTypes.string.isRequired,
  accessibilityStatus: PropTypes.bool.isRequired,
  accessibilityVisible: PropTypes.bool.isRequired,
};

TotemFooter.defaultProps = {};

export default TotemFooter;
