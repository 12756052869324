import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import generateUrl from '../generate-url';

interface TotemEventsLanguageList {
  [key: string]: Array<any>;
}
interface TotemEventsFilters {
  searchEventFilter: string;
  dateEventFilter: string;
  padsEventFilter: string;
  categoriesFilter: Array<string>;
}
interface TotemEventsState {
  eventsLoading: boolean;
  events: TotemEventsLanguageList;
  eventsError: any;

  eventsCategoriesLoading: boolean;
  eventsCategories: TotemEventsLanguageList;
  eventsCategoriesError: any;

  filters: TotemEventsFilters;
}

export const getTotemEventsAction = createAsyncThunk(
  'events/getTotemEventsAction',
  ({ editionCode, language }: { editionCode: string, language: string }, thunkAPI) => fetch(generateUrl(`/hub/searchEvents/${editionCode}`), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': language ?? 'en',
      Authorization: process.env.REACT_APP_API_KEY ?? '',
    },
  }).then((res) => {
    switch (res.status) {
      case 200:
        return res.json();
      case 500:
        throw new Error(res.statusText);
      default:
        throw new Error('Server Error');
    }
  }),
);

const getTotemEventsPending = (
  state: TotemEventsState,
  action: PayloadAction<void>,
): void => {
  state.eventsLoading = true;
};

const getTotemEventsFulfilled = (
  state: TotemEventsState,
  action: PayloadAction<any, any, any>,
): void => {
  state.eventsLoading = false;
  if (action.meta?.arg?.language) {
    const lng = action.meta.arg.language;
    state.events[lng] = action.payload.data;
  } else {
    state.events[i18n.language] = action.payload.data;
  }
};

const getTotemEventsRejected = (
  state: TotemEventsState,
  action: PayloadAction<{ error: Error }>,
): void => {
  state.eventsLoading = false;
  state.eventsError = action.payload;
  console.log(action.payload);
};

export const getTotemEventsCategoriesAction = createAsyncThunk(
  'events/getTotemEventsCategoriesAction',
  ({ editionCode, language }: { editionCode: string, language: string }, thunkAPI) => fetch(generateUrl(`/hub/eventCategories/${editionCode}`), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': language ?? 'en',
      Authorization: process.env.REACT_APP_API_KEY ?? '',
    },
  }).then((res) => {
    switch (res.status) {
      case 200:
        return res.json();
      case 500:
        throw new Error(res.statusText);
      default:
        throw new Error('Server Error');
    }
  }),
);

const getTotemEventsCategoriesPending = (
  state: TotemEventsState,
  action: PayloadAction<void>,
): void => {
  state.eventsCategoriesLoading = true;
};

const getTotemEventsCategoriesFulfilled = (
  state: TotemEventsState,
  action: PayloadAction<any, any, any>,
): void => {
  state.eventsCategoriesLoading = false;
  if (action.meta?.arg?.language) {
    const lng = action.meta.arg.language;
    state.eventsCategories[lng] = action.payload.data;
  } else {
    state.eventsCategories[i18n.language] = action.payload.data;
  }
};

const getTotemEventsCategoriesRejected = (
  state: TotemEventsState,
  action: PayloadAction<{ error: Error }>,
): void => {
  state.eventsCategoriesLoading = false;
  state.eventsCategoriesError = action.payload;
  console.log(action.payload);
};

export const totemEventsSlice = createSlice<TotemEventsState, any, 'totemEvents'>({
  name: 'totemEvents',
  initialState: {
    eventsLoading: false,
    events: {
      en: [],
      it: [],
    },
    eventsError: false,

    eventsCategoriesLoading: false,
    eventsCategories: {
      en: [],
      it: [],
    },
    eventsCategoriesError: false,

    filters: {
      searchEventFilter: '',
      dateEventFilter: '',
      padsEventFilter: '',
      categoriesFilter: [],
    },
  },
  reducers: {
    updateFilterQuery: (
      state: TotemEventsState,
      action: PayloadAction<string>,
    ) => {
      state.filters.searchEventFilter = action.payload;
    },
    updateDateFilter: (
      state: TotemEventsState,
      action: PayloadAction<string>,
    ) => {
      state.filters.dateEventFilter = action.payload;
    },
    updatePadsFilter: (
      state: TotemEventsState,
      action: PayloadAction<string>,
    ) => {
      state.filters.padsEventFilter = action.payload;
    },
    updateCategoryFilter: (
      state: TotemEventsState,
      action: PayloadAction<string>,
    ) => {
      if (action.payload) {
        const found = state.filters.categoriesFilter.findIndex(
          (item) => item === action.payload,
        );
        if (found !== -1) {
          state.filters.categoriesFilter = state.filters.categoriesFilter.filter(
            (item) => item !== state.filters.categoriesFilter[found],
          );
        } else {
          state.filters.categoriesFilter = [...state.filters.categoriesFilter, action.payload];
        }
      }
    },
    resetFilters: (state: TotemEventsState) => {
      state.filters = {
        searchEventFilter: '',
        dateEventFilter: '',
        padsEventFilter: '',
        categoriesFilter: [],
      };
    },
  },
  extraReducers: {
    [(getTotemEventsAction.pending as unknown) as string]: getTotemEventsPending,
    [(getTotemEventsAction.fulfilled as unknown) as string]: getTotemEventsFulfilled,
    [(getTotemEventsAction.rejected as unknown) as string]: getTotemEventsRejected,
    [
      (getTotemEventsCategoriesAction.pending as unknown) as string
    ]: getTotemEventsCategoriesPending,
    [
      (getTotemEventsCategoriesAction.fulfilled as unknown) as string
    ]: getTotemEventsCategoriesFulfilled,
    [
      (getTotemEventsCategoriesAction.rejected as unknown) as string
    ]: getTotemEventsCategoriesRejected,
  },
});

export default totemEventsSlice.reducer;

export const {
  updateFilterQuery,
  updateDateFilter,
  updatePadsFilter,
  resetFilters,
  updateCategoryFilter,
} = totemEventsSlice.actions;

export const eventsStateSelector = (state: {
  totemEvents: TotemEventsState;
}): TotemEventsState => state.totemEvents;

export const getEventFiltersSelector = (state: {
  totemEvents: TotemEventsState;
}): TotemEventsFilters => state.totemEvents.filters;

export const filteredEventsSelector = (state: {
  totemEvents: TotemEventsState;
}): TotemEventsLanguageList => {
  let newItEventsLists = state.totemEvents.events.it;
  let newEnEventsLists = state.totemEvents.events.en;
  if (state.totemEvents.filters.searchEventFilter.length > 0) {
    newItEventsLists = newItEventsLists.filter(
      (item) => item.title.toLowerCase().includes(
        state.totemEvents.filters.searchEventFilter.toLowerCase(),
      ),
    );
    newEnEventsLists = newEnEventsLists.filter(
      (item) => item.title.toLowerCase().includes(
        state.totemEvents.filters.searchEventFilter.toLowerCase(),
      ),
    );
  }
  if (state.totemEvents.filters.dateEventFilter.length > 0) {
    const getFormattedDate = (date: number) => {
      const nd = new Date(date);
      return nd.toLocaleDateString('it');
    };
    newItEventsLists = newItEventsLists.filter(
      (item) => item.startDate
        && getFormattedDate(item.startDate)
          === state.totemEvents.filters.dateEventFilter,
    );
    newEnEventsLists = newEnEventsLists.filter(
      (item) => item.startDate
        && getFormattedDate(item.startDate)
          === state.totemEvents.filters.dateEventFilter,
    );
  }
  if (state.totemEvents.filters.padsEventFilter.length > 0) {
    newItEventsLists = newItEventsLists.filter(
      (item) => item.pad === state.totemEvents.filters.padsEventFilter,
    );
    newEnEventsLists = newEnEventsLists.filter(
      (item) => item.pad === state.totemEvents.filters.padsEventFilter,
    );
  }
  if (state.totemEvents.filters.categoriesFilter.length > 0) {
    const checker = (arr: any, target: any) => target.every((v: any) => arr.includes(v));
    newItEventsLists = newItEventsLists.filter(
      (item) => item.categories
        && checker(
          item.categories.map((subItem: any) => subItem.id),
          state.totemEvents.filters.categoriesFilter,
        ),
    );
    newEnEventsLists = newEnEventsLists.filter(
      (item) => item.categories
        && checker(
          item.categories.map((subItem: any) => subItem.id),
          state.totemEvents.filters.categoriesFilter,
        ),
    );
  }
  return {
    it: [...newItEventsLists],
    en: [...newEnEventsLists],
  };
};

export const eventsDatesFilter = (state: {
  totemEvents: TotemEventsState;
}): string[] => state.totemEvents.events[i18n.language].reduce((acc, item) => {
  if (!item.startDate) { return acc; }
  const newDate = new Date(item.startDate).toLocaleDateString('it');
  const found = acc.find((newItem: string) => newItem === newDate);
  if (found) { return acc; }
  acc.push(newDate);
  return acc;
}, []);

export const eventsPadsFilter = (state: {
  totemEvents: TotemEventsState;
}): string[] => state.totemEvents.events[i18n.language].reduce((acc, item) => {
  if (!item.pad) { return acc; }
  if (item.pad === 'Digital') { return acc; }
  const found = acc.find((newItem: string) => newItem === item.pad);
  if (found) { return acc; }
  acc.push(item.pad);
  return acc;
}, []);
