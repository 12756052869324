import React, {
  useCallback, useState, useEffect, useRef, useMemo,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  useHistory, Switch, Route, useParams, NavLink, Redirect, useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';
import i18n from 'i18next';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import TotemFooter from '../components/totem-footer';
import TotemHeader from '../components/totem-header';
import getTotemExhibitionData from '../store/actions/get-totem-exhibition-data';
import {
  configurationPagesVisibility,
  logAnalytic,
  resetTotemConfig,
  updateUtilsApiError,
} from '../store/totem-config-slice';
import { resetAllScrollState } from '../store/scroll-restoration-slice';
import AdvScreenSaverPage from './adv-screen-saver-page';
import routesConfiguration, { RouterItems } from '../common/routesConfiguration';
import PageContainer from '../components/page-container';
import TotemIcon from '../components/icon';
import theme from '../theme';
import TotemModal from '../components/totem-modal';
import { analyticsEvents, eventsDescriptions } from '../common/analytics-constants';

const TotemActiveNavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const BodyContainer = styled.div`
  height: 2800px;
`;

const MenuContainer = styled.div`
  display: flex;
  width: 100%;
`;

const MenuItemText = styled.span`
  margin-top: 10px;
  text-transform: capitalize;
`;

const TotemNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: white;
  color: ${(props) => props.theme.primaryColor};
  text-decoration: none;
  padding: 38px 0;

  &.selected {
    background-color: ${(props) => props.theme.primaryColor};
    color: white;
  }
`;

const FakePage = styled.h3`
  height: 2614px;
  vertical-align: center;
  text-align: center;
  font-size: 104px;
  font-weight: bold;
`;

function MenuItem({
  path, title, icon, activeClassName, exact,
}) {
  return (
    <TotemNavLink to={path} exact={exact} activeClassName={activeClassName}>
      <TotemIcon icon={icon} size={theme.fontSize.h2} />
      <MenuItemText>{title}</MenuItemText>
    </TotemNavLink>
  );
}

const LandingPage = () => {
  const { totemId } = useParams();
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const isAdv = !!useRouteMatch([`/${totemId}/adv/`, `/${totemId}`]);
  const [isAccessibilityActive, setIsAccessibilityActive] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language ?? 'it');
  const history = useHistory();
  const clickTimeout = useRef(null);
  const dataUpdateTimeout = useRef(null);
  const notSyncScreenSaveActive = useRef(isAdv);
  const [isScreenSaveActive, setIsScreenSaveActive] = useState(isAdv);

  const filterRoutes = useSelector(configurationPagesVisibility, shallowEqual);

  useEffect(() => {
    dispatch(getTotemExhibitionData({ totemId }));
  }, [dispatch, totemId]);

  const redirectToLandingAndResetState = useCallback(() => {
    history.push(`/${totemId}/adv/`);
    dispatch(resetTotemConfig());
    dispatch(resetAllScrollState());
    dispatch(updateUtilsApiError(null));
    dispatch(logAnalytic({ eventName: analyticsEvents.CLICK_RESTART_BUTTON }));
    setIsScreenSaveActive(true);
    notSyncScreenSaveActive.current = true;
  }, [dispatch, history, totemId]);

  // Screensaver management
  useEffect(() => {
    const idleTime = 30 * 3;
    if (!isScreenSaveActive) {
      clickTimeout.current = setTimeout(
        redirectToLandingAndResetState,
        1000 * idleTime,
      );
      // console.log('New TimeOut', clickTimeout.current);
    }
    // let clickTimeout;
    const handleWindowClick = () => {
      clearTimeout(clickTimeout.current);
      // console.log('Clear TimeOut', clickTimeout.current);
      clickTimeout.current = setTimeout(
        redirectToLandingAndResetState,
        1000 * idleTime,
      );
      // console.log('New TimeOut', clickTimeout.current);
    };

    document.addEventListener('click', handleWindowClick);
    return () => {
      clearTimeout(clickTimeout.current);
      // console.log('Clear TimeOut', clickTimeout.current);
      document.removeEventListener('click', handleWindowClick);
    };
  }, [
    isScreenSaveActive,
    setIsScreenSaveActive,
    history,
    totemId,
    dispatch,
    redirectToLandingAndResetState,
  ]);

  // Data update after two hours if no user is using totem
  useEffect(() => {
    const updateTime = 60 * 60 * 2;
    let innerTimer = null;

    const innerConditionalTriggerDataUpdate = () => {
      if (notSyncScreenSaveActive.current && totemId) {
        dispatch(logAnalytic({ eventName: analyticsEvents.TOTEM_AUTO_UPDATE_DATA }));
        dispatch(getTotemExhibitionData({ totemId }));
        if (innerTimer) {
          clearTimeout(innerTimer);
        }
        return true;
      }
      return false;
    };

    const conditionalTriggerDataUpdate = () => {
      // console.log('Landing: 2h dopo');
      const updated = innerConditionalTriggerDataUpdate();
      if (!updated && !innerTimer) {
        innerTimer = setInterval(innerConditionalTriggerDataUpdate, 1000 * 30);
        // console.log('Landing: Partito secondo timer', innerTimer);
      }
    };

    if (!dataUpdateTimeout.current) {
      dataUpdateTimeout.current = setInterval(conditionalTriggerDataUpdate, 1000 * updateTime);
      // console.log('Landing: Partito primo timer', dataUpdateTimeout.current);
    }

    return () => {
      if (!dataUpdateTimeout.current) {
        clearTimeout(dataUpdateTimeout.current);
        // console.log('Landing: Cancellato primo timer', dataUpdateTimeout.current);
      }
    };
  }, [dispatch, totemId]);

  const updateLanguage = useCallback((lng) => {
    if (i18n.language !== lng) {
      i18n
        .changeLanguage(lng)
        .then((_t) => {
        // console.log('Lingua cambiata');
        })
        .catch((err) => {
        // console.log('something went wrong loading', err);
        });
      setCurrentLanguage(lng);
      dispatch(logAnalytic({
        eventName: analyticsEvents.CLICK_LANGUAGE_BUTTON,
        eventParams: {
          [eventsDescriptions.CLICK_LANGUAGE_BUTTON.languageValue]: lng,
        },
      }));
    }
  }, [setCurrentLanguage, dispatch]);

  const updateAccessibilityStatus = useCallback(() => {
    // console.log('accessibilità cliccata');
    setIsAccessibilityActive(!isAccessibilityActive);
    dispatch(logAnalytic({ eventName: analyticsEvents.CLICK_ACCESSIBILITY_BUTTON }));
  }, [setIsAccessibilityActive, isAccessibilityActive, dispatch]);

  const advPageButtonHandler = useCallback(() => {
    setIsScreenSaveActive(false);
    notSyncScreenSaveActive.current = false;
    history.push(`/${totemId}/home/`);
  }, [setIsScreenSaveActive, history, totemId]);

  const backButtonClickHandler = useCallback(() => history.goBack(), [history]);

  const menuItems = useMemo(() => {
    if (!filterRoutes) return null;

    const filteredRoutes = routesConfiguration
      .filter(({ mainRouteKeyName }) => filterRoutes.includes(mainRouteKeyName));

    return filteredRoutes.map(({ path, title, icon }) => (
      <MenuItem
        key={`/${totemId}${path}`}
        path={`/${totemId}${path}`}
        title={t(title)}
        icon={icon}
        // exact={!item.children}
        activeClassName="selected"
      />
    ));
  }, [filterRoutes, t, totemId]);

  const routeItems = useMemo(
    () => map(
      RouterItems,
      ({
        path,
        exact,
        breadcrumbKey,
        showBackButton,
        showRestoreButton,
        title,
        component: ChildComponent,
      }) => (
        <Route
          key={`/${totemId}${path}`}
          path={`/${totemId}${path}`}
          exact={exact}
        >
          <TotemActiveNavigationContainer>
            {ChildComponent ? (
              <PageContainer
                accessibilityActive={isAccessibilityActive}
                labelKey={breadcrumbKey}
                backButton={showBackButton}
                backButtonClick={backButtonClickHandler}
                restoreButton={showRestoreButton}
                restoreButtonClick={redirectToLandingAndResetState}
              >
                <ChildComponent
                  accessibilityActive={isAccessibilityActive}
                  pageRestoreButtonHandler={redirectToLandingAndResetState}
                />
              </PageContainer>
            ) : (
              <FakePage>{t(title)}</FakePage>
            )}
            <MenuContainer>{menuItems}</MenuContainer>
          </TotemActiveNavigationContainer>
        </Route>
      ),
    ),
    [
      totemId,
      isAccessibilityActive,
      backButtonClickHandler,
      redirectToLandingAndResetState,
      t,
      menuItems,
    ],
  );

  return (
    <>
      <TotemHeader screenSaverActive={false} />
      <BodyContainer>
        <Switch>
          <Route exact path={`/${totemId}/adv/`}>
            <AdvScreenSaverPage
              screenSaverButtonHandler={advPageButtonHandler}
            />
          </Route>
          {routeItems}
          <Redirect from="*" to={`/${totemId}/adv/`} />
        </Switch>
      </BodyContainer>
      <TotemFooter
        screenSaverActive={false}
        onLanguageChange={updateLanguage}
        onAccessibilityButtonClick={updateAccessibilityStatus}
        currentSelectedLanguage={currentLanguage}
        accessibilityStatus={isAccessibilityActive}
        accessibilityVisible={!isScreenSaveActive}
      />
      <TotemModal />
    </>
  );
};

export default LandingPage;
