import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { H4 } from './headings';
import TotemIcon from './icon';
import theme from '../theme';
import { configurationStateSelector } from '../store/totem-configuration-slice';
import useTotemCustomSelector from '../common/use-totem-selector';

const EventCardButtonContainer = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  margin-bottom: 50px;
  border: 2px solid ${theme.secondaryLineColor};
`;

const LeftSideCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const RightSideCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  padding: 25px 25px 25px 50px;
`;

const LeftBottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.secondaryLightColor};
  padding: 25px;
  font-size: ${theme.fontSize.overline};
`;

const RightBottomContainer = styled(LeftBottomContainer)`
  background-color: transparent;
  padding: 0;
`;

const ImgItem = styled.img`
  width: 50%;
`;

const CssImageItem = styled.div`
  width: 100%;
  height: 350px;
  background-position: 50%;
  background-size: cover;
  background-image: url(${(props) => props.$imageUrl});
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 10px;
`;

const TagItem = styled.span`
  font-size: ${theme.fontSize.overline};
  text-transform: uppercase;
  margin: 5px 10px;
  padding: 8px 16px;
  color: white;
  background-color: ${theme.secondaryDarkColor};
  border-radius: 4px;
`;

const EventOwner = styled(TagItem)`
  background-color: ${theme.secondaryMediumColor};
  margin-right: 25px;
`;

const InfoFakeButton = styled.div`
  padding: 0 7px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  font-size: ${theme.fontSize.h2};
`;

const getDate = (date) => {
  if (date) {
    const nd = new Date(date);
    return <span>{nd.toLocaleDateString('it')}</span>;
  }
  return <span> - </span>;
};

const EventCardButton = (props) => {
  const { t, i18n } = useTranslation('events-pages');
  const { cardClickHandler, event } = props;
  const [loader, totemConfig] = useTotemCustomSelector(
    configurationStateSelector,
    'configurationLoading',
    'configuration',
  );

  const createTiming = useCallback((start, end) => {
    const timeFormatter = { timeStyle: 'short' };
    const b = new Date(start);
    if (!start && !end) {
      return <span> - </span>;
    }
    if (end) {
      const e = new Date(end);
      return (
        <span>
          {`${b.toLocaleTimeString(i18n.language, timeFormatter)} - ${e.toLocaleTimeString(i18n.language, timeFormatter)}`}
        </span>
      );
    }
    return <span>{b.toLocaleTimeString(i18n.language, timeFormatter)}</span>;
  }, [i18n.language]);

  const getPads = useCallback((pad) => {
    if (pad) {
      const res = Number.parseInt(pad, 10);
      return <span>{res ? t('pad') + res : pad}</span>;
    }
    return <span>NaN</span>;
  }, [t]);

  const getOwnerName = useCallback(() => {
    if (event.type === 'Organizer') {
      return totemConfig?.appTotemConfig?.editionName[i18n.language];
    }
    return event.exhibitor?.name ?? 'No Name';
  }, [event, i18n.language, totemConfig.appTotemConfig.editionName]);

  return (
    <EventCardButtonContainer onClick={() => cardClickHandler(event.id)}>
      <LeftSideCard>
        <CssImageItem $imageUrl={event.coverImage ?? ''}>
          <TagContainer>
            {
              event.categories && event.categories.map((item) => (
                <TagItem key={item.id}>{item.label}</TagItem>
              ))
            }
          </TagContainer>
        </CssImageItem>
        <LeftBottomContainer>
          {getDate(event.startDate ?? null)}
          {createTiming(event.startDate ?? null, event.endDate ?? null)}
          {getPads(event.pad ?? null)}
        </LeftBottomContainer>
      </LeftSideCard>
      <RightSideCard>
        <H4>{event.title}</H4>
        {event.descriptionShort && <p>{event.descriptionShort}</p>}
        <RightBottomContainer>
          <EventOwner>{getOwnerName()}</EventOwner>
          <InfoFakeButton>
            <TotemIcon icon="Chevron-Right" size="50px" />
          </InfoFakeButton>
        </RightBottomContainer>
      </RightSideCard>
    </EventCardButtonContainer>
  );
};

EventCardButton.propTypes = {};

EventCardButton.defaultProps = {};

export default EventCardButton;
