import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TotemIcon from './icon';

const ServiceListContainer = styled.div`
  width: 50%;
`;

const ServiceList = styled.div`
  justify-content: space-between;
`;

const ServiceButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-bottom: 140px;
  flex-direction: column;
  opacity: ${(props) => (props.$active ? '1' : '0.4')};
`;

const ServiceButton = styled.div`
  border-radius: 50%;
  justify-content: center;
  align-content: center;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.primaryColor};
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.3));
  width: 110px;
  height: 110px;
`;

const ServiceDescription = styled.span`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  margin-top: 32px;
  width: 60%;
`;

const ServiceListButton = ({
  description,
  icon,
  isActive,
  buttonClickHandler,
}) => (
  <ServiceListContainer>
    <ServiceList>
      <ServiceButtonContainer
        $active={isActive}
        onClick={buttonClickHandler}
        data-service-index={icon}
      >
        <ServiceButton>
          <TotemIcon
            icon={icon}
            color="white"
            size="60px"
            text-align="center"
          />
        </ServiceButton>
        <ServiceDescription>
          {' '}
          {description}
        </ServiceDescription>
      </ServiceButtonContainer>
    </ServiceList>
  </ServiceListContainer>
);

ServiceListButton.propTypes = {
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  buttonClickHandler: PropTypes.func.isRequired,
};

ServiceListButton.defaultProps = {
  isActive: true,
};

export default ServiceListButton;
