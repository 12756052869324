import { createSlice } from '@reduxjs/toolkit';

type State = number | boolean;
type Props = {
  index: State;
};
export const scrollRestoration = createSlice({
  name: 'scrollRestoration',
  initialState: {
    index: false,
    eventState: false,
    homeState: false,
  },
  reducers: {
    changeIndexState: (state, action) => {
      state.index = action.payload;
    },
    changeEventsState: (state, action) => {
      state.eventState = action.payload;
    },
    changeHomeState: (state, action) => {
      state.homeState = action.payload;
    },
    resetAllScrollState: (state) => {
      state.index = false;
      state.eventState = false;
      state.homeState = false;
    },
  },
});

export const indexStateSelector = (state: { scrollRestoration: Props }):
 any => state.scrollRestoration;

export const {
  changeIndexState,
  changeEventsState,
  changeHomeState,
  resetAllScrollState,
} = scrollRestoration.actions;

export default scrollRestoration.reducer;
