import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { H4 } from '../components/headings';

const InfomobilityContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  justify-content: ${(props) => (!props.$loadedContent ? 'center' : 'flex-start')};
  align-items: ${(props) => (!props.$loadedContent ? 'center' : 'flex-start')};
`;

const InfoIframe = styled.iframe`
  /* width: 25%;
  height: 25%;
  transform: scale(4);
  transform-origin: 0 0; */
  display: ${(props) => (props.$visible ? 'flex' : 'none')};;
  width: 50%;
  height: 50%;
  transform: scale(2);
  transform-origin: 0 0;
`;

const Infomobility = ({ accessibilityActive }) => {
  const { t } = useTranslation('common');
  const [loadedFrame, setLoadedFrame] = useState(false);

  return (
    <InfomobilityContainer $loadedContent={loadedFrame}>
      {!loadedFrame && <H4>{t('pages.loading')}</H4>}
      <InfoIframe
        $visible={loadedFrame}
        title="Infomobility"
        src="https://fieramilano.kotukodev.it/#/dashboard"
        onLoad={() => setLoadedFrame(true)}
        sandbox="allow-scripts allow-same-origin"
      />
    </InfomobilityContainer>
  );
};

Infomobility.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
};

Infomobility.defaultProps = {};

export default Infomobility;
