import { createAsyncThunk } from '@reduxjs/toolkit';
import generateUrl from '../../generate-url';

export default createAsyncThunk(
  'login/doSignOut',
  (arg, thunkAPI) => fetch(
    generateUrl('/endpoint/sign-out'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    },
  )
    .then((res) => undefined),
);
