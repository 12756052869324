import React, {
  useCallback, useState, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Virtuoso } from 'react-virtuoso';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { debounce, map } from 'lodash';
import { H4 } from '../components/headings';
import TotemLeftPanel from '../components/totem-left-panel';
import TotemRightPanel from '../components/totem-right-panel';
import EventCardButton from '../components/event-card-button';
import FilterHeader from '../components/filter-header';
import theme from '../theme';
import TotemFilterCategoryButton from '../components/totem-filter-category-button';
import {
  configEventsCategoriesStateSelector,
  configEventsDateListStateSelector,
  configEventsFiltersStateSelector,
  configEventsPavilionStateSelector,
  configFilteredEventsStateSelector,
  logAnalytic,
  resetEventsFilter,
  setEventCategoryFilter,
  setEventDatesFilter,
  setEventPadsFilter,
  setEventStringFilter,
} from '../store/totem-config-slice';
import TotemFormControlLabel from '../components/totem-form-control-label';
import TotemCheckbox from '../components/totem-checkbox';
import { changeEventsState, indexStateSelector } from '../store/scroll-restoration-slice';
import NoResultCard from '../components/no-result-card';
import TotemKeyboard from '../components/totem-keyboard';
import { analyticsEvents, eventsDescriptions } from '../common/analytics-constants';

const EventsContainer = styled.div`
  display: flex;
`;

const EventFiltersContainer = styled.div`
  padding: 36px;
`;

const EventFilterWrapper = styled.div`
  margin-top: 32px;
`;

const EventsCategoryFilterContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: scroll;
`;

const PadButtonsWrapper = styled(EventsContainer)`
  margin-top: 50px;
  flex-wrap: wrap;
  gap: 20px 20px;
`;

const PadButton = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
  padding: 20px;
  border: 2px solid ${theme.primaryBarColor};
  opacity: ${(props) => (props.$opacityActive ? '1' : '0.4')};
`;

const DateListWrapper = styled(EventsContainer)`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding: 20px;
  max-height: 500px;
  overflow-y: scroll;
  background-color: white;
  border: 2px solid ${theme.secondaryLineColor};
`;

const Events = ({ accessibilityActive }) => {
  const { t, i18n } = useTranslation('events-pages');
  const [tc] = useTranslation('common');
  const history = useHistory();
  const dispatch = useDispatch();

  const eventFilters = useSelector(configEventsFiltersStateSelector);
  const filterDates = useSelector(configEventsDateListStateSelector);
  const filterPads = useSelector(configEventsPavilionStateSelector);
  const [eventQuery, setEventQuery] = useState(eventFilters.searchEventFilter);

  const totemEvents = useSelector(configFilteredEventsStateSelector);
  const totemEventsCategories = useSelector(configEventsCategoriesStateSelector);
  const scrollRestorationIndex = useSelector(indexStateSelector);

  const rightPanelRef = useRef(null);
  const [keyboardOpen, setKeyboardOpen] = useState(false);

  useEffect(() => {
    dispatch(logAnalytic({ eventName: analyticsEvents.PAGE_EVENTS }));
  }, [dispatch]);

  const logHomeSearchValue = useCallback(
    debounce(
      (value) => {
        dispatch(logAnalytic({
          eventName: analyticsEvents.SEARCH_EVENT,
          eventParams: {
            [eventsDescriptions.SEARCH_EVENT.searchValue]: value,
          },
        }));
      },
      1500,
    ),
    [],
  );

  const eventClickHandler = useCallback(
    (index, { id, title }) => {
      history.push(`${history.location.pathname}/detail/${id}`);
      dispatch(changeEventsState(index));
      dispatch(logAnalytic({
        eventName: analyticsEvents.CLICK_EVENT_BUTTON,
        eventParams: {
          [eventsDescriptions.CLICK_EVENT_BUTTON.eventName]: title.it ?? id,
        },
      }));
    },
    [dispatch, history],
  );

  const searchInputChange = useCallback((ev) => {
    setEventQuery(ev.target.value);
    dispatch(setEventStringFilter(ev.target.value));
    logHomeSearchValue(ev.target.value);
  }, [dispatch, logHomeSearchValue]);

  const onSearchClickHandler = useCallback(() => {
    if (eventQuery.length > 2 || eventQuery.length === 0) {
      dispatch(setEventStringFilter(eventQuery));
      logHomeSearchValue(eventQuery);
    }
  }, [eventQuery, dispatch, logHomeSearchValue]);

  const handleResetFilter = useCallback(() => {
    setEventQuery('');
    dispatch(resetEventsFilter());
    dispatch(logAnalytic({ eventName: analyticsEvents.FILTER_EVENT_CLEAR }));
  }, [dispatch]);

  const handleCategoryFilterChange = useCallback(
    (event, name) => {
      dispatch(setEventCategoryFilter(event));
      dispatch(logAnalytic({
        eventName: analyticsEvents.FILTER_EVENT,
        eventParams: {
          [eventsDescriptions.FILTER_EVENT.filterValue]: name,
        },
      }));
    },
    [dispatch],
  );

  const filterCategoryButtons = useMemo(() => map(totemEventsCategories, (cat) => (
    <TotemFilterCategoryButton
      key={cat.id}
      title={cat.label[i18n.language]}
      itemIndex={cat.id}
      itemName={cat.label.it ?? cat.label}
      isActive={
        eventFilters.categoriesFilter.length === 0
        || eventFilters.categoriesFilter.includes(cat.id)
      }
      onClickCategoryHandler={handleCategoryFilterChange}
    />
  )),
  [
    eventFilters.categoriesFilter,
    handleCategoryFilterChange,
    i18n.language,
    totemEventsCategories,
  ]);

  const padsButtonHandler = useCallback((event) => {
    const res = Number.parseInt(event.currentTarget.dataset.padIndex, 10);
    dispatch(setEventPadsFilter(filterPads[res]));
    dispatch(logAnalytic({
      eventName: analyticsEvents.FILTER_EVENT,
      eventParams: {
        [eventsDescriptions.FILTER_EVENT.filterValue]: filterPads[res],
      },
    }));
  }, [dispatch, filterPads]);

  const renderPavilionList = useMemo(() => map(filterPads, (item, index) => (
    <PadButton
      key={`pad_${item}`}
      data-pad-index={index}
      onClick={padsButtonHandler}
      $opacityActive={
        eventFilters.padsEventFilter.length === 0
        || eventFilters.padsEventFilter.includes(item)
      }
    >
      {item}
    </PadButton>
  )), [filterPads, padsButtonHandler, eventFilters.padsEventFilter]);

  const dateButtonHandler = useCallback((event) => {
    dispatch(setEventDatesFilter(event.target.name));
    dispatch(logAnalytic({
      eventName: analyticsEvents.FILTER_EVENT,
      eventParams: {
        [eventsDescriptions.FILTER_EVENT.filterValue]: event.target.name,
      },
    }));
  }, [dispatch]);

  const renderDatesList = useMemo(() => map(filterDates, (item) => (
    <TotemFormControlLabel
      key={`date_${item}`}
      name={item}
      checked={eventFilters.dateEventFilter.includes(item)}
      control={<TotemCheckbox />}
      onChange={dateButtonHandler}
      label={item}
    />
  )), [filterDates, eventFilters.dateEventFilter, dateButtonHandler]);

  const virtuoso = React.useRef(null);

  useEffect(() => {
    if (scrollRestorationIndex.eventState) {
      setTimeout(() => {
        virtuoso.current.scrollToIndex({
          index: scrollRestorationIndex.eventState,
          // align:'start',
          behavior: 'smooth',
        });
      }, 100);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderVirtuosoItem = useCallback(
    (index, data) => (
      <EventCardButton
        key={data.id}
        cardClickHandler={eventClickHandler}
        indexItem={index}
        event={data}
      />
    ),
    [eventClickHandler],
  );

  const closeKeyBoardHandler = useCallback(() => {
    setKeyboardOpen(false);
  }, []);

  const keyBoardInputChange = useCallback((value) => {
    let toSave = eventQuery + value;
    if (value === '⇦') {
      toSave = eventQuery.substring(0, eventQuery.length - 1);
    }
    if (value === 'cancel') {
      toSave = '';
    }
    if (value === 'enter') {
      toSave = toSave.substring(0, toSave.length - 5);
    }
    setEventQuery(toSave);
    dispatch(setEventStringFilter(toSave));
    logHomeSearchValue(toSave);
  }, [dispatch, eventQuery, logHomeSearchValue]);

  const openKeyBoardEvent = useCallback(() => {
    setKeyboardOpen(true);
  }, []);

  return (
    <EventsContainer>
      <TotemLeftPanel accessibilityActive={accessibilityActive}>
        {totemEvents.length > 0 ? (
          <Virtuoso
            ref={virtuoso}
            data={totemEvents}
            style={{
              padding: '0 50px',
              overflowX: 'hidden',
            }}
            totalCount={totemEvents.length}
            itemContent={renderVirtuosoItem}
          />
        ) : (
          <NoResultCard>
            {t('no_data_found')}
          </NoResultCard>
        )}
      </TotemLeftPanel>
      <TotemRightPanel
        accessibilityActive={accessibilityActive}
        showSearchBar
        searchTitle={t('searchLabel')}
        onSearchInputChangeHandler={searchInputChange}
        onPerformSearchClickHandler={onSearchClickHandler}
        searchValue={eventQuery}
        onInputFocusHandler={openKeyBoardEvent}
      >
        <EventFiltersContainer>
          <FilterHeader removeFilterClickHandler={handleResetFilter} />
          {
            totemEventsCategories.length > 0 && (
              <EventFilterWrapper>
                <H4>{tc('pages.categories')}</H4>
                <EventsCategoryFilterContainer>
                  {filterCategoryButtons}
                </EventsCategoryFilterContainer>
              </EventFilterWrapper>
            )
          }
          {
            filterPads.length > 0 && (
              <EventFilterWrapper>
                <H4>{tc('pages.pavillion')}</H4>
                <PadButtonsWrapper>
                  {renderPavilionList}
                </PadButtonsWrapper>
              </EventFilterWrapper>
            )
          }
          <EventFilterWrapper>
            <H4>{tc('pages.date')}</H4>
            <DateListWrapper>
              {renderDatesList}
            </DateListWrapper>
          </EventFilterWrapper>
        </EventFiltersContainer>
        <TotemKeyboard
          isKeyboardOpen={keyboardOpen}
          onKeyboardCloseHandler={closeKeyBoardHandler}
          onKeyboardButtonPressed={keyBoardInputChange}
          itemToIgnoreRef={rightPanelRef.current}
        />
      </TotemRightPanel>
    </EventsContainer>
  );
};

Events.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
};

Events.defaultProps = {};

export default Events;
