/* eslint-disable import/no-extraneous-dependencies */
import {
  name, internet, company, address, image, random, lorem, seed,
} from 'faker';

export default function getTotemConfiguration(req, res, ctx) {
  seed(123);
  return res(
    ctx.status(200),
    ctx.set('Content-Type', 'application/json'),
    ctx.json({
      appTotemConfig: {
        createdAt: '2021-06-07T07:49:01.006Z',
        totemStatus: 'ACTIVE',
        totemName: {
          en: name.firstName(),
          it: name.firstName(),
        },
        totemUrl: internet.domainName(),
        editionName: {
          en: company.companyName(),
          it: company.companyName(),
        },
        editionCode: random.number,
        editionId: random.number,
        totemPavillion: {
          pavillion: `Padiglione${random.number(999999)}`,
          pavillionLatitude: address.latitude(),
          pavillionLongitude: address.longitude(),
        },
        advertisement: [
          {
            screenSaverAdv: 'https://via.placeholder.com/1560x2200.png?text=banner+1',
            bannerAdv: image.animals(1560, 440),
            exhibitorId: random.number(999999),
          },
          {
            screenSaverAdv: 'https://via.placeholder.com/1560x2200.png?text=banner+2',
            bannerAdv: image.business(1560, 440),
            exhibitorId: random.number(999999),
          },
          {
            screenSaverAdv: 'https://via.placeholder.com/1560x2200.png?text=banner+3',
            bannerAdv: image.nature(1560, 440),
            exhibitorId: random.number(999999),
          },
        ],
      },
      editionTotemConfig: {
        banners: {
          coverEditionImage: image.business(1560, 480),
          organizerImage: image.business(600, 220),
          subtitle: {
            en: name.title(),
            it: name.title(),
          },
        },
        color: {
          primaryColor: '#2E66B7',
          primaryBarColor: '#1D2321',
        },
        informations: [
          {
            informationTitle: {
              en: name.title(),
              it: name.title(),
            },
            informationButtonImage: image.business(1005, 470),
            informationCoverImage: 'https://via.placeholder.com/2160x470.png?text=information+cover+1',
            informationText: {
              en: lorem.paragraph(random.number(100)),
              it: lorem.paragraph(random.number(100)),
            },
          },
          {
            informationTitle: {
              en: name.title(),
              it: name.title(),
            },
            informationButtonImage: image.business(1005, 470),
            informationCoverImage: 'https://via.placeholder.com/2160x470.png?text=information+cover+2',
            informationText: {
              en: lorem.paragraph(random.number(100)),
              it: lorem.paragraph(random.number(100)),
            },
          },
          {
            informationTitle: {
              en: name.title(),
              it: name.title(),
            },
            informationButtonImage: image.business(1005, 470),
            informationCoverImage: 'https://via.placeholder.com/2160x470.png?text=information+cover+3',
            informationText: {
              en: lorem.paragraph(random.number(100)),
              it: lorem.paragraph(random.number(100)),
            },
          },
          {
            informationTitle: {
              en: name.title(),
              it: name.title(),
            },
            informationButtonImage: image.business(1005, 470),
            informationCoverImage: 'https://via.placeholder.com/2160x470.png?text=information+cover+4',
            informationText: {
              en: lorem.paragraph(random.number(100)),
              it: lorem.paragraph(random.number(100)),
            },
          },
          {
            informationTitle: {
              en: name.title(),
              it: name.title(),
            },
            informationButtonImage: image.business(1005, 470),
            informationCoverImage: 'https://via.placeholder.com/2160x470.png?text=information+cover+5',
            informationText: {
              en: lorem.paragraph(random.number(100)),
              it: lorem.paragraph(random.number(100)),
            },
          },
        ],
      },
    }),
  );
}
