import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { map } from 'lodash';
import { H2, H4 } from '../components/headings';
import { homeStateSelector } from '../store/totem-home-slice';
import theme from '../theme';
import ExhibitorLocationDetail from '../components/exhibitor-location-detail';
import Button from '../components/button';
import IconDetailHeader from '../components/icon-detail-header';
import LabelValueDetail from '../components/label-value-detail';
import {
  configNotFilteredExhibitorsStateSelector,
  configurationBrandsAsExhibitor,
  configurationWayFindingEnabled,
  showRouteToExhibitor,
} from '../store/totem-config-slice';
import BrandCard from '../components/brand-card';
import CategoryCard from '../components/category-card';
import HomepageExhibitorButton from '../components/homepage-exhibitor-button';
import { changeHomeState } from '../store/scroll-restoration-slice';

const ExhibitorDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  position: relative;
  height: 940px;
`;

const HeroImage = styled.div`
  height: 810px;
  background-image: url(${(props) => props.$imageUrl});
  background-position: 50%;
  background-size: cover;
  z-index: 0;
`;

const EmptyHeroImage = styled.div`
  height: 810px;
  background-color: transparent;
  z-index: 0;
`;

const ExhibitorLogo = styled.div`
  height: 260px;
  width: 260px;
  position: absolute;
  border: 2px solid ${theme.secondaryLineColor};
  background-color: white;
  background-image: url(${(props) => props.$imageUrl});
  background-position: 50%;
  background-size: cover;
  z-index: 1;
  top: 680px;
  left: 130px;
  box-sizing: border-box;
`;

const EmptyExhibitorLogo = styled.div`
  height: 260px;
  width: 260px;
  position: absolute;
  border: 2px solid ${theme.secondaryLineColor};
  background-image: url(${(props) => props.$imageUrl});
  background-position: 50%;
  background-size: cover;
  z-index: 1;
  top: 680px;
  left: 130px;
  box-sizing: border-box;
`;

const ExhibitorDetailsWrapper = styled(ExhibitorDetailContainer)`
  padding: 50px 130px;
`;

const ExhibitorDetailsWithBackgroundWrapper = styled(ExhibitorDetailContainer)`
  padding: 50px 130px;
  background-color: ${theme.secondaryLightColor};
`;

const ExhibitorTitleBarContainer = styled.div`
  display: flex;
  align-items: center;
  
  & > h2,
  & > div {
    flex: 2;
  }
  
  & > button {
    flex: 1;
  }
`;

const ExhibitorDetailsBarContainer = styled.div`
  display: flex;
`;

const ExhibitorAddressWrapper = styled(ExhibitorDetailContainer)`
  flex: 2;
`;

const ExhibitorContactsWrapper = styled(ExhibitorDetailContainer)`
  flex: 2;
`;

const ExhibitorShowInAppWrapper = styled(ExhibitorDetailContainer)`
  flex: 1;
`;

const ExhibitorCategoryContainer = styled(ExhibitorDetailContainer)`
  flex-direction: column;
  align-items: flex-start;
  
  margin-top: 70px;
`;

const ExhibitorCategoryWrapper = styled(ExhibitorDetailsBarContainer)`
  margin-top: 70px;
  align-self: flex-start;
  flex-wrap: wrap;
  gap: 68px;
`;

const RepresentedHouseExhibitorWrapper = styled(ExhibitorDetailsBarContainer)`
  margin-top: 70px;
  align-self: flex-start;
  flex-wrap: wrap;
  gap: 34px 10px;
`;

const ExhibitorBrandsWrapper = styled(ExhibitorDetailsBarContainer)`
  margin-top: 70px;
  align-self: flex-start;
  flex-wrap: wrap;
  gap: 34px 68px;
`;

const LocationDetails = styled.div`
  padding-left: 65px;
`;

const H4CustomMargin = styled(H4)`
  margin-top: 10px;
`;

const ExhibitorDetail = (props) => {
  const { t, i18n } = useTranslation('exhibitor-pages');
  const [nt] = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();
  const showWayFinding = useSelector(configurationWayFindingEnabled, shallowEqual);
  const fullExhibitorsList = useSelector(configNotFilteredExhibitorsStateSelector, shallowEqual);
  const brandsAsExhibitor = useSelector(configurationBrandsAsExhibitor);
  const {
    exhibitorDetailsLoading,
    exhibitorDetails,
    exhibitorCluster,
  } = useSelector(homeStateSelector);

  const calculatePathHandler = useCallback((ev) => {
    dispatch(showRouteToExhibitor(true));
    history.goBack();
  }, [dispatch, history]);

  const renderClusterList = useMemo(() => map(exhibitorCluster, (item) => (
    <ExhibitorCategoryContainer key={`totem_${item.oldId}`}>
      <IconDetailHeader
        iconName="Categorie-speciali"
        title={item.name[i18n.language]}
      />
      <ExhibitorCategoryWrapper>
        {item.data.map((subItem) => (
          <CategoryCard
            key={`cls_${subItem.id}`}
            categoryImg={subItem.logoUrl?.length > 0 ? subItem.logoUrl : null}
            title={subItem.name[i18n.language]}
          />
        ))}
      </ExhibitorCategoryWrapper>
    </ExhibitorCategoryContainer>
  )), [exhibitorCluster, i18n.language]);

  const renderBrandsList = useMemo(
    () => exhibitorDetails
      && map(exhibitorDetails.brands, (item) => (
        <BrandCard key={item.name.replace(/\s/g, '')} extended title={item.name} />
      )),
    [exhibitorDetails],
  );

  const homeClickHandler = useCallback(
    (index, { id }) => {
      const newPath = history.location.pathname
        .split('/')
        .slice(0, 4)
        .concat(encodeURIComponent(id))
        .join('/');
      history.replace(newPath.replace('//', '/'));
      dispatch(changeHomeState(id));
    },
    [dispatch, history],
  );

  const representedHouseExhibitors = useMemo(() => {
    if (brandsAsExhibitor || !exhibitorDetails) {
      return null;
    }
    let exhibitorsList = null;
    if (exhibitorDetails.type && exhibitorDetails.type === 'representedHouse') {
      exhibitorsList = fullExhibitorsList
        .filter((exhibitor) => exhibitor
          .brands?.some((brand) => brand.id === exhibitorDetails.id));
    }
    if (!exhibitorsList || !exhibitorsList.length) {
      return null;
    }
    return (
      <ExhibitorCategoryContainer>
        <IconDetailHeader
          iconName="Espositori"
          title={nt('navigationMenu.exhibitors')}
        />
        <RepresentedHouseExhibitorWrapper>
          {exhibitorsList.map((exhibitor, index) => (
            <HomepageExhibitorButton
              key={`rhe_${exhibitor.id}`}
              itemIndex={index}
              icon={exhibitor.logoUrl}
              exhibitorName={exhibitor.companyName}
              padText={t('pavillion', { pads: exhibitor.pads[0].id })}
              standText={t('stand', { stands: exhibitor.pads[0].stand.join(' ') })}
              buttonClickHandler={homeClickHandler}
              exhibitor={exhibitor}
              hideDivider
            />
          ))}
        </RepresentedHouseExhibitorWrapper>
      </ExhibitorCategoryContainer>
    );
  }, [brandsAsExhibitor, exhibitorDetails, fullExhibitorsList, homeClickHandler, nt, t]);

  return (
    <ExhibitorDetailContainer>
      {exhibitorDetails && (
        <>
          <HeaderWrapper>
            {exhibitorDetails.coverPictureUrl
            && <HeroImage $imageUrl={exhibitorDetails.coverPictureUrl} />}
            {!exhibitorDetails.coverPictureUrl && <EmptyHeroImage />}
            {exhibitorDetails.logoUrl
            && <ExhibitorLogo $imageUrl={exhibitorDetails.logoUrl} />}
            {!exhibitorDetails.logoUrl && <EmptyExhibitorLogo />}
          </HeaderWrapper>
          <ExhibitorDetailsWrapper>
            <ExhibitorTitleBarContainer>
              <div>
                <H2>{exhibitorDetails.companyName}</H2>
                {
                  exhibitorDetails.type
                  && exhibitorDetails.type === 'representedHouse'
                  && (<H4CustomMargin>{t('representedHouse')}</H4CustomMargin>)
                }
                {!exhibitorDetails.type && (<H4CustomMargin>{t('exhibitor')}</H4CustomMargin>)}
              </div>
              {exhibitorDetails.pads && (
                <ExhibitorLocationDetail
                  padText={t('pavillion', { pads: exhibitorDetails.pads[0].id })}
                  standText={t('stand', { stands: exhibitorDetails.pads[0].stand.join(' ') })}
                  iconSize="60px"
                />
              )}
              {
                showWayFinding
                && !exhibitorDetails.type
                && (
                  <Button variant="contained" onClick={calculatePathHandler}>
                    {t('calculate_path')}
                  </Button>
                )
              }
            </ExhibitorTitleBarContainer>
          </ExhibitorDetailsWrapper>
          <ExhibitorDetailsWithBackgroundWrapper>
            <ExhibitorDetailsBarContainer>

              <ExhibitorAddressWrapper>
                <IconDetailHeader iconName="Pin-mappa" title={t('address')} />
                <LocationDetails>
                  <LabelValueDetail labelText={t('address')} valueText={exhibitorDetails.address} />
                  <LabelValueDetail labelText={t('city')} valueText={exhibitorDetails.city} />
                  <LabelValueDetail labelText={t('zip')} valueText={exhibitorDetails.zip} />
                  <LabelValueDetail labelText={t('country')} valueText={exhibitorDetails.country} />
                </LocationDetails>
              </ExhibitorAddressWrapper>

              <ExhibitorContactsWrapper>
                <IconDetailHeader iconName="Contatti" title={t('contacts')} />
                <LocationDetails>
                  <LabelValueDetail labelText={t('phone')} valueText={exhibitorDetails.phone} />
                  <LabelValueDetail labelText={t('email')} valueText={exhibitorDetails.email} />
                </LocationDetails>
              </ExhibitorContactsWrapper>

              {/* <ExhibitorShowInAppWrapper> */}
              {/*  <IconDetailHeader iconName="App" title={t('app')} /> */}
              {/*  <LocationDetails> */}
              {/*    <p>{t('open_in_app')}</p> */}
              {/*  </LocationDetails> */}
              {/* </ExhibitorShowInAppWrapper> */}

            </ExhibitorDetailsBarContainer>

            {representedHouseExhibitors}

            {renderClusterList}

            {
              renderBrandsList.length > 0 && (
                <ExhibitorCategoryContainer>
                  <IconDetailHeader
                    iconName="Categorie-merceologiche"
                    title={t('brands')}
                  />
                  <ExhibitorBrandsWrapper>
                    {renderBrandsList}
                  </ExhibitorBrandsWrapper>
                </ExhibitorCategoryContainer>
              )
            }

          </ExhibitorDetailsWithBackgroundWrapper>
        </>
      )}
    </ExhibitorDetailContainer>
  );
};

ExhibitorDetail.propTypes = {};

ExhibitorDetail.defaultProps = {};

export default ExhibitorDetail;
