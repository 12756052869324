import { PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import {
  TotemConfigurationState,
  TotemEventsFilters,
} from '../totem-config-slice';

const checkAndReplace = (source: Array<string>, newItem: string): Array<string> => {
  if (source.includes(newItem)) {
    return source.filter(
      (item) => item !== newItem,
    );
  }
  return [...source, newItem];
};

const stringFilter = (events: Array<any>, filter: string): Array<any> => events
  .filter((event: any) => event.title[i18n.language].toLowerCase().includes(filter));

const categoriesFilterFunc = (events: Array<any>, filters: Array<string>) => {
  if (filters.length === 0) {
    return events;
  }

  return events.filter(
    (event) => event.categories.some(
      (category: any) => filters.includes(category.id),
    ),
  );
};

const dateFilter = (events: Array<any>, filters: Array<string>): Array<any> => {
  if (filters.length === 0) {
    return events;
  }

  return events.filter((event) => {
    const newDate = new Date(event.startDate).toLocaleDateString('it');
    return filters.includes(newDate);
  });
};

const padsFilter = (
  events: Array<any>,
  filters: Array<string>,
): Array<any> => {
  if (filters.length === 0) {
    return events;
  }
  return events.filter(
    (event) => event.pads.some(
      (pad: any) => filters.includes(pad.id),
    ),
  );
};

const applyAllFilters = (
  events: Array<any>,
  filters: TotemEventsFilters,
): Array<any> => {
  const {
    searchEventFilter,
    categoriesFilter,
    padsEventFilter,
    dateEventFilter,
  } = filters;

  let eventsFiltered = stringFilter(events, searchEventFilter);
  eventsFiltered = categoriesFilterFunc(
    eventsFiltered,
    categoriesFilter,
  );
  eventsFiltered = dateFilter(
    eventsFiltered,
    dateEventFilter,
  );
  eventsFiltered = padsFilter(
    eventsFiltered,
    padsEventFilter,
  );
  return eventsFiltered;
};

export const applyEventsStringFilter = (
  state: TotemConfigurationState,
  action: PayloadAction<string>,
): void => {
  state.eventsPageFilters.searchEventFilter = action.payload;
  state.filteredEvents = applyAllFilters(
    state.events,
    state.eventsPageFilters,
  );
};

export const applyEventsCategoryFilter = (
  state: TotemConfigurationState,
  action: PayloadAction<string>,
): void => {
  state.eventsPageFilters.categoriesFilter = checkAndReplace(
    state.eventsPageFilters.categoriesFilter,
    action.payload,
  );

  state.filteredEvents = applyAllFilters(
    state.events,
    state.eventsPageFilters,
  );
};

export const applyEventsDatesFilter = (
  state: TotemConfigurationState,
  action: PayloadAction<string>,
): void => {
  state.eventsPageFilters.dateEventFilter = checkAndReplace(
    state.eventsPageFilters.dateEventFilter,
    action.payload,
  );

  state.filteredEvents = applyAllFilters(
    state.events,
    state.eventsPageFilters,
  );
};

export const applyEventsPadsFilter = (
  state: TotemConfigurationState,
  action: PayloadAction<string>,
): void => {
  state.eventsPageFilters.padsEventFilter = checkAndReplace(
    state.eventsPageFilters.padsEventFilter,
    action.payload,
  );

  state.filteredEvents = applyAllFilters(
    state.events,
    state.eventsPageFilters,
  );
};
