import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TotemIcon from './icon';
import theme from '../theme';

const TotemFilterCategoryButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: ${(props) => (props.$itemClickable ? 'pointer' : 'default')};;
  margin-bottom: 40px;
  height: 125px;
  border: 2px solid ${theme.secondaryLineColor};
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;

  opacity: ${(props) => (props.$opacityActive ? '1' : '0.4')};
`;

const CssImageItem = styled.div.attrs((props) => ({
  style: {
    backgroundImage: `url(${props.$imageUrl})`,
  },
}))`
  width: 125px;
  height: 125px;
  background-position: 50%;
  background-size: cover;
`;

const InfoFakeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 125px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  font-size: ${theme.fontSize.h2};
`;

const BoldParagraph = styled.p`
  flex: 1px;
  padding: 0 32px;
  font-weight: bold;
`;

const TotemFilterCategoryButton = ({
  categoryImg,
  title,
  onClickCategoryHandler,
  isActive,
  itemIndex,
  itemName,
}) => {
  const clickHandler = useCallback(() => {
    onClickCategoryHandler(itemIndex, itemName ?? null);
  }, [itemIndex, itemName, onClickCategoryHandler]);

  return (
    <TotemFilterCategoryButtonContainer
      $opacityActive={isActive}
      $itemClickable={onClickCategoryHandler}
      onClick={onClickCategoryHandler ? clickHandler : onClickCategoryHandler}
    >
      {!categoryImg && (
        <InfoFakeButton>
          <TotemIcon icon="Eventi" size="50px" />
        </InfoFakeButton>
      )}
      {categoryImg && categoryImg.length > 0 && <CssImageItem $imageUrl={categoryImg} />}
      <BoldParagraph>
        {title}
      </BoldParagraph>
    </TotemFilterCategoryButtonContainer>
  );
};

TotemFilterCategoryButton.propTypes = {
  categoryImg: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClickCategoryHandler: PropTypes.func,
  isActive: PropTypes.bool,
  itemIndex: PropTypes.string.isRequired,
};

TotemFilterCategoryButton.defaultProps = {
  categoryImg: null,
  onClickCategoryHandler: null,
  isActive: true,
};

export default TotemFilterCategoryButton;
