import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import generateUrl from '../generate-url';

interface TotemConfigurationResponse {
  appTotemConfig: any;
  editionTotemConfig: any;
}

interface TotemConfigurationState {
  configurationLoading: boolean;
  configuration: TotemConfigurationResponse;
  configurationError: any;
}

export const getTotemConfigurationAction = createAsyncThunk(
  'configuration/getTotemConfiguration',
  ({ totemId }: { totemId: string }, thunkAPI) => fetch(generateUrl(`/totem/${totemId}`), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': i18n.language ?? 'en',
      Authorization: process.env.REACT_APP_API_KEY ?? '',
    },
  }).then((res) => {
    switch (res.status) {
      case 200:
        return res.json();
      case 500:
        throw new Error(res.statusText);
      default:
        throw new Error('Server Error');
    }
  }),
);

const getTotemConfigurationPending = (
  state: TotemConfigurationState,
  action: PayloadAction<void>,
): void => {
  state.configurationLoading = true;
};

const getTotemConfigurationFulfilled = (
  state: TotemConfigurationState,
  action: PayloadAction<TotemConfigurationResponse>,
): void => {
  state.configurationLoading = false;
  state.configuration.appTotemConfig = action.payload.appTotemConfig;
  state.configuration.editionTotemConfig = action.payload.editionTotemConfig;
};

const getTotemConfigurationRejected = (
  state: TotemConfigurationState,
  action: PayloadAction<{ error: Error }>,
): void => {
  state.configurationLoading = false;
  state.configurationError = action.payload;
  console.log(action.payload);
};

export const totemConfigurationSlice = createSlice<
  TotemConfigurationState,
  any,
  'totemConfiguration'
>({
  name: 'totemConfiguration',
  initialState: {
    configurationLoading: false,
    configuration: {
      appTotemConfig: {},
      editionTotemConfig: {},
    },
    configurationError: null,
  },
  reducers: {},
  extraReducers: {
    [(getTotemConfigurationAction.pending as unknown) as string]: getTotemConfigurationPending,
    [(getTotemConfigurationAction.fulfilled as unknown) as string]: getTotemConfigurationFulfilled,
    [(getTotemConfigurationAction.rejected as unknown) as string]: getTotemConfigurationRejected,
  },
});

export default totemConfigurationSlice.reducer;

export const configurationStateSelector = (state: {
  totemConfiguration: TotemConfigurationState;
}): TotemConfigurationState => state.totemConfiguration;

export const configurationColorStateSelector = (state: {
  totemConfiguration: TotemConfigurationState;
}): TotemConfigurationState => state
  .totemConfiguration.configuration?.editionTotemConfig?.colors ?? null;
