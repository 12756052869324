import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const BreadcreumbsText = styled.h3`
  width: 100%;
  background-color: ${(props) => props.theme.primaryColor};
  text-align: center;
  color: white;
  font-weight: ${(props) => props.theme.h3.fontWeight};
  font-size: ${(props) => props.theme.h3.fontSize};
  padding: 16px 0;
`;

const TotemBreadcrumbs = ({ labelKey }) => {
  const { t } = useTranslation('common');

  return (
    <BreadcreumbsText>{t(labelKey)}</BreadcreumbsText>
  );
};

export default TotemBreadcrumbs;
