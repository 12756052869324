import { PayloadAction } from '@reduxjs/toolkit';
import { TotemConfigurationState } from '../totem-config-slice';

const getFilteredPoiList = (sourceArray: any, filter: number): any => ({
  ...sourceArray,
  features: sourceArray.features?.filter(
    (item: any) => item?.properties && item?.properties?.category === filter,
  ),
});

export const filterServiceList = (
  state: TotemConfigurationState,
  action: PayloadAction<number>,
): void => {
  const sp = state.selectedPoi === action.payload ? -1 : action.payload;
  state.selectedPoi = sp;
  if (sp !== -1) {
    state.filteredPoi[0] = getFilteredPoiList(state.poiList[0], state.selectedPoi);
    state.filteredPoi[1] = getFilteredPoiList(state.poiList[1], state.selectedPoi);
  } else {
    state.filteredPoi = state.poiList;
  }
};

export const triggerCalculatePathFunc = (
  state: TotemConfigurationState,
  action: PayloadAction<boolean>,
): void => {
  state.drawExhibitorPath = action.payload;
};
