import { useSelector } from 'react-redux';

export default (
  steteSelectorRef: any,
  loadingKey?: string,
  dataKey?: string,
  errorKey?: string,
): [loader: boolean, data: any, error: any] => {
  const state = useSelector(steteSelectorRef) as any;

  const loader = state && loadingKey && state[loadingKey] !== null ? state[loadingKey] : null;
  const data = state && dataKey && state[dataKey] ? state[dataKey] : null;
  const error = state && errorKey && state[errorKey] ? state[errorKey] : null;

  return [loader, data, error];
};
