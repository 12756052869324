import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducer from './reducers';

export default function customConfigureStore() {
  const store = configureStore({
    reducer,
    middleware: [...getDefaultMiddleware()],
    // preloadedState: {},
    // enhancers: [],
  });

  if ((NODE_ENV !== 'production' && NODE_ENV !== 'staging') && module && module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      store.replaceReducer(reducer as any);
    });
  }

  return store;
}
