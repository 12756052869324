import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Icon from './icon';

const BackButtonContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  border: ${(props) => props.$type === 'outlined' && `2px solid ${props.theme.primaryColor}`};
  background-color: ${(props) => (props.$type === 'filled' ? props.theme.primaryColor : 'white')};
  color: ${(props) => (props.$type === 'filled' ? 'white' : props.theme.primaryColor)};
  opacity: 0.85;
`;

const TextRotated = styled.span`
  transform: rotateZ(180deg);
  writing-mode: vertical-rl;
  text-transform: uppercase;
  /* margin-left: 5px; */
  margin-top: 20px;
`;

const TotemBackButton = ({ clickHandler, type }) => {
  const { t } = useTranslation('common');

  const getButtonStyle = useCallback(() => {
    if (type === 'restore') return 'outlined';
    return 'filled';
  }, [type]);

  const getIconName = useCallback(
    () => {
      if (type === 'maps') return 'Chiudi';
      if (type === 'restore') return 'Restore';
      return 'Chevron-Left';
    },
    [type],
  );

  const getButtonMsg = useCallback(
    () => {
      if (type === 'maps') return t('buttons.mapBackButton');
      if (type === 'restore') return t('buttons.restart');
      return t('buttons.backButton');
    },
    [t, type],
  );

  return (
    <BackButtonContainer $type={getButtonStyle()} onClick={clickHandler}>
      <Icon size="60px" icon={getIconName()} />
      <TextRotated>
        {getButtonMsg()}
      </TextRotated>
    </BackButtonContainer>
  );
};

TotemBackButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  type: PropTypes.string,
};

TotemBackButton.defaultProps = {
  type: 'default',
};

export default TotemBackButton;
