export default {
  primaryColor: '#2E66B7',
  primaryBarColor: '#1D2321',
  primaryLightColor: '#ECF1F8',
  secondaryDarkColor: '#1D2321', // DARK
  secondaryMediumColor: '#6B6B6E', // MEDIUM GREY
  secondaryLineColor: '#E0E0E0', // LINE GREY
  secondaryLightColor: '#F4F5F4', // LIGHT GREY
  h2: {
    fontSize: '64px',
    fontWeight: 'bold',
  },
  h3: {
    fontSize: '50px',
    fontWeight: 'bold',
  },
  h4: {
    fontSize: '36px',
    fontWeight: 'bold',
  },
  bodyFont: {
    fontSize: '32px',
    fontWeight: 'normal',
  },
  bodyBoldFont: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  fontSize: {
    h2: '64px',
    h3: '50px',
    h4: '36px',
    bodyFont: '32px',
    bodyBoldFont: '32px',
    overline: '25px',
    caption: '23px',
    customPavilionLabel: '57px',
  },
  fontFamily: 'Mulish',
};
