import { TotemConfigurationState } from '../totem-config-slice';

export const clearAllUserInteraction = (
  state: TotemConfigurationState,
): void => {
  state.filteredExhibitors = state.exhibitors;
  state.exhibitorsPageFilters = {
    searchExhibitorsFilter: '',
    categoriesFilter: [],
    padsExhibitorsFilter: [],
    countryExhibitorsFilter: [],
    sectorFilter: null,
  };

  state.filteredEvents = state.events;
  state.eventsPageFilters = {
    searchEventFilter: '',
    dateEventFilter: [],
    padsEventFilter: [],
    categoriesFilter: [],
  };

  state.filteredPoi = state.poiList;
  state.selectedPoi = -1;
  state.drawExhibitorPath = false;
};

export const clearUserInteractionOnExhibitorsPage = (
  state: TotemConfigurationState,
): void => {
  state.filteredExhibitors = state.exhibitors;
  state.exhibitorsPageFilters = {
    searchExhibitorsFilter: '',
    categoriesFilter: [],
    padsExhibitorsFilter: [],
    countryExhibitorsFilter: [],
    sectorFilter: null,
  };
};

export const clearUserInteractionOnEventsPage = (
  state: TotemConfigurationState,
): void => {
  state.filteredEvents = state.events;
  state.eventsPageFilters = {
    searchEventFilter: '',
    dateEventFilter: [],
    padsEventFilter: [],
    categoriesFilter: [],
  };
};
