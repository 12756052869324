import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Icon from './icon';

const BackButtonContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background-color: ${(props) => props.theme.secondaryMediumColor};
  color: white;
`;

const TextRotated = styled.span`
  transform: rotateZ(180deg);
  writing-mode: vertical-rl;
  text-transform: uppercase;
  /* margin-left: 5px; */
  margin-top: 20px;
`;

const TotemBackButton = ({ clickHandler }) => {
  const { t } = useTranslation('common');

  return (
    <BackButtonContainer onClick={clickHandler}>
      <Icon icon="Chevron-Left" />
      <TextRotated>
        { t('buttons.backButton') }
      </TextRotated>
    </BackButtonContainer>
  );
};

TotemBackButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
};

TotemBackButton.defaultProps = {};

export default TotemBackButton;
