import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reduce } from 'lodash';
import generateUrl from '../generate-url';

interface TotemEventsLanguageList {
  [key: string]: Array<any>;
}

interface TotemHomeState {
  exhibitorDetailsLoading: boolean;
  exhibitorDetails: any;
  exhibitorDetailsError: any;

  exhibitorCluster: Array<any>;
}

const generateCorrectUrl = (
  editionCode: string,
  eboothId: string,
  isBrandsAsExhibitors: boolean,
): string => {
  if (isBrandsAsExhibitors) {
    return generateUrl(`/hub/searchBrand/${editionCode}/brand/${encodeURIComponent(eboothId)}`);
  }
  return generateUrl(
    `/hub/searchExhibitors/${editionCode}/exhibitor/${encodeURIComponent(eboothId)}`,
  );
};

const getThisExhibitorsCategories = (exhibitor: any, clusterList: Array<any>) => reduce(
  clusterList,
  (acc: Array<any>, item: any) => {
    const categories: Array<any> = [];
    item.data.forEach((category: any) => {
      if (category.exhibitors.includes(exhibitor.id)) {
        categories.push(category);
      }
    });
    if (categories.length > 0) {
      const itemToPush = { ...item, data: [...categories] };
      acc.push(itemToPush);
    }
    return acc;
  },
  [],
);

export const getTotemHomeExhibitorDetailsAction = createAsyncThunk(
  'home/getTotemHomeExhibitorDetailsAction',
  (
    {
      editionCode,
      eboothId,
      isBrandsAsExhibitors,
    }: { editionCode: string; eboothId: string; isBrandsAsExhibitors: boolean },
    thunkAPI,
  ) => fetch(generateCorrectUrl(editionCode, eboothId, isBrandsAsExhibitors), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': 'all',
      Authorization: process.env.REACT_APP_API_KEY ?? '',
    },
  }).then(async (res) => {
    switch (res.status) {
      case 200: {
        const data = await res.json();
        const globalState = thunkAPI.getState() as any;
        const { clustersList } = globalState.totemConfig;
        return [data, clustersList];
      }
      case 500:
        throw new Error(res.statusText);
      default:
        throw new Error('Server Error');
    }
  }),
);

const getExhibitorDetailsPending = (
  state: TotemHomeState,
  action: PayloadAction<void>,
): void => {
  state.exhibitorDetailsLoading = true;
};

const getExhibitorDetailsPendingFulfilled = (
  state: TotemHomeState,
  action: PayloadAction<any>,
): void => {
  state.exhibitorDetailsLoading = false;
  const [exhibitorDetail, clusterList] = action.payload;

  state.exhibitorDetails = exhibitorDetail;
  state.exhibitorCluster = getThisExhibitorsCategories(exhibitorDetail, clusterList);
};

const getExhibitorDetailsError = (
  state: TotemHomeState,
  action: PayloadAction<{ error: Error }>,
): void => {
  state.exhibitorDetailsLoading = false;
  state.exhibitorDetailsError = action.payload;
  console.log(action.payload);
};

const setExhibitorDetailsFromBrand = (
  state: TotemHomeState,
  action: PayloadAction<any>,
): void => {
  state.exhibitorDetails = action.payload.exhibitorItem;
};

export const totemHomeSlice = createSlice<TotemHomeState, any, 'totemHome'>({
  name: 'totemHome',
  initialState: {
    exhibitorDetailsLoading: false,
    exhibitorDetails: null,
    exhibitorDetailsError: null,

    exhibitorCluster: [],
  },
  reducers: {
    setExhibitorDetailsFrom: setExhibitorDetailsFromBrand,
  },
  extraReducers: {
    [
      (getTotemHomeExhibitorDetailsAction.pending as unknown) as string
    ]: getExhibitorDetailsPending,
    [
      (getTotemHomeExhibitorDetailsAction.fulfilled as unknown) as string
    ]: getExhibitorDetailsPendingFulfilled,
    [
      (getTotemHomeExhibitorDetailsAction.rejected as unknown) as string
    ]: getExhibitorDetailsError,
  },
});

export const { setExhibitorDetailsFrom } = totemHomeSlice.actions;

export default totemHomeSlice.reducer;

export const homeStateSelector = (state: {
  totemHome: TotemHomeState;
}): TotemHomeState => state.totemHome;
