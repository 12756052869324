import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { configStateSelector, logAnalytic } from '../store/totem-config-slice';
import { H2 } from '../components/headings';
import { analyticsEvents, eventsDescriptions } from '../common/analytics-constants';

const InformationDetailContainer = styled.div``;

const InfoContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 140px 400px;
`;

const InfoContent = styled.span`
  margin-top: 38px;
  
  * {
    all: revert;
  } 
`;

const InformationDetail = () => {
  const totemConfig = useSelector(configStateSelector);
  const { infoId } = useParams();
  const { t, i18n } = useTranslation('info-detail-page');
  const dispatch = useDispatch();

  useEffect(() => {
    const infoName = totemConfig.editionTotemConfig?.informations[infoId]?.informationTitle?.it ?? '';
    dispatch(logAnalytic({
      eventName: analyticsEvents.CLICK_INFORMATION_BUTTON,
      eventParams: {
        [eventsDescriptions.CLICK_INFORMATION_BUTTON.informationName]: infoName,
      },
    }));
  }, [dispatch, infoId, totemConfig.editionTotemConfig.informations]);

  const InformationItem = ({ item }) => (
    <InformationDetailContainer>
      <img src={item.informationCoverImage} alt={t('coverALTdesc')} />
      <InfoContentContainer>
        <H2>{item.informationTitle[i18n.language]}</H2>
        <InfoContent dangerouslySetInnerHTML={{ __html: item.informationText[i18n.language] }} />
      </InfoContentContainer>
    </InformationDetailContainer>
  );

  return (
    <>
      {
        totemConfig.editionTotemConfig?.informations?.length > 0 && (
          <InformationItem item={totemConfig.editionTotemConfig.informations[infoId]} />
        )
      }
    </>
  );
};

export default InformationDetail;
