import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../theme';

const Marker = styled.div`
  text-align: center;
  font-family: ${theme.fontFamily};
  font-size: ${theme.fontSize.bodyFont};
  line-height: ${theme.fontSize.bodyFont};
  font-weight: bold;
  margin-bottom: 25px;
  background-color: white;
  padding: 20px;
  border: 2px solid ${theme.primaryBarColor};
  border-radius: 35px;
  opacity: 0.75;
`;

const GateText = styled.span`
  white-space: break-spaces;
`;

const TotemGateMarker = ({ name }) => (
  <Marker>
    <GateText>{name.replaceAll(' ', '\n')}</GateText>
  </Marker>
);

TotemGateMarker.prototype = {
  name: PropTypes.string,
};

TotemGateMarker.defaultProps = {
  name: '',
};

export default TotemGateMarker;
