/* global NODE_ENV */

import { getAnalytics, logEvent } from 'firebase/analytics';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  TotemConfigurationState,
} from '../totem-config-slice';

type LogFirebaseAnalyticConfig = {
  eventName: string;
  eventParams: {
    [key: string]: string
  }
}

export default (
  state: TotemConfigurationState,
  action: PayloadAction<LogFirebaseAnalyticConfig>,
): void => {
  const analytics = getAnalytics();
  const { sessionId } = state;
  const { appTotemConfig } = state.configuration;
  const { eventName, eventParams = {} } = action.payload;

  const defaultParams = {
    totem_id: appTotemConfig?.totemId ?? '',
    totem_name: appTotemConfig?.totemName ?? '',
    totem_pavilion: appTotemConfig?.totemPavillion?.pavillion ?? '',
    totem_floor: appTotemConfig?.totemPavillion?.floor ?? '',
    totem_environment: String(NODE_ENV) ?? '',
    edition_code: appTotemConfig?.editionCode ?? '',
    edition_name: appTotemConfig?.editionName?.it ?? '',
    log_time: new Date().toString(),
    possible_session_id: sessionId ?? '',
  };

  if (NODE_ENV && NODE_ENV !== 'production') {
    console.info(eventName.toUpperCase(), { ...defaultParams, ...eventParams });
  }

  logEvent(
    analytics,
    eventName,
    { ...defaultParams, ...eventParams },
  );
};
