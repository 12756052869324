import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { darken } from '@material-ui/core/styles';
import theme from '../theme';

const TotemButton = styled(Button)`
  font-size: ${(props) => (props.$fontSize ? props.theme.fontSize[props.$fontSize] : props.theme.fontSize.bodyFont)};
  font-family: ${(props) => props.theme.fontFamily};
  text-transform: none;
  padding: 18px 35px;

  &.MuiButton-outlined {
    background-color: #fff;
    color: ${(props) => props.theme.primaryColor};
    border-color: ${(props) => props.theme.primaryColor};

    &:hover {
      background-color: darken('ffffff', 0.1);
    }
  }

  &.MuiButton-contained {
    background-color: ${(props) => props.theme.primaryColor};
    color: #fff;

    &:hover {
      background-color: ${(props) => darken(props.theme.primaryColor, 0.1)};
    }
  }

  &.Mui-disabled {
    background-color: ${theme.secondaryLineColor};
    color: ${theme.secondaryMediumColor};
    border-color: ${theme.secondaryMediumColor};
  }

  .MuiButton-iconSizeMedium > *:first-child {
    font-size: ${(props) => (props.$iconFontSize ? props.theme.fontSize[props.$iconFontSize] : props.theme.fontSize.bodyFont)};
  }
`;

export default TotemButton;
