/* eslint-disable max-len */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import generateUrl from '../generate-url';

interface TotemExhibitorsLanguageList {
  [key: string]: Array<any>;
}

interface TotemExhibitorsFilters {
  searchExhibitorsFilter: string;
  padsExhibitorsFilter: Array<string>;
  categoriesFilter: Array<string>;
  countryExhibitorsFilter: Array<string>;
  stateExhibitorsFilter: string;
  cityExhibitorsFilter: string;
}

interface TotemExhibitorsState {
  exhibitorsLoading: boolean;
  exhibitors: Array<any>;
  exhibitorsError: any;

  exhibitorsCategoriesLoading: boolean;
  exhibitorsCategories: Array<any>;
  exhibitorsCategoriesError: any;

  exhibitorsSectorsLoading: boolean;
  exhibitorsSectors: Array<any>;
  exhibitorsSectorsError: any;

  filteredExhibitors: Array<any>;
  clustersList: Array<any>;

  filters: TotemExhibitorsFilters;
}

const getClusters = (categories: Array<any>) => categories.reduce((acc, item) => {
  const tmp = acc.find((accItem: any) => accItem.oldId === item.clusterGroup.oldId);
  if (!tmp) {
    const clusterToAdd = { ...item.clusterGroup, data: [{ ...item }] };
    acc.push(clusterToAdd);
  } else {
    tmp.data.push(item);
  }
  return acc;
},
[]);

export const getTotemExhibitorsAction = createAsyncThunk(
  'exhibitors/getTotemExhibitorsAction',
  ({ editionCode, language }: { editionCode: string, language: string }, thunkAPI) => fetch(generateUrl(`/hub/searchExhibitors/${editionCode}`), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': 'all',
      Authorization: process.env.REACT_APP_API_KEY ?? '',
    },
  }).then((res) => {
    switch (res.status) {
      case 200:
        return res.json();
      case 500:
        throw new Error(res.statusText);
      default:
        throw new Error('Server Error');
    }
  }),
);

const getTotemExhibitorsPending = (
  state: TotemExhibitorsState,
  action: PayloadAction<void>,
): void => {
  state.exhibitorsLoading = true;
};

const getTotemExhibitorsFulfilled = (
  state: TotemExhibitorsState,
  action: PayloadAction<any, any, any>,
): void => {
  state.exhibitorsLoading = false;
  state.exhibitors = action.payload.data;
  // state.filteredExhibitors = action.payload.data.slice(0, 200);
  state.filteredExhibitors = action.payload.data; // TODO: remove this
};

const getTotemExhibitorsRejected = (
  state: TotemExhibitorsState,
  action: PayloadAction<{ error: Error }>,
): void => {
  state.exhibitorsLoading = false;
  state.exhibitorsError = action.payload;
  console.log(action.payload);
};

export const getTotemExhibitorsCategoriesAction = createAsyncThunk(
  'exhibitors/getTotemExhibitorsCategoriesAction',
  (
    { editionCode, language }: { editionCode: string; language: string },
    thunkAPI,
  ) => fetch(generateUrl(`/hub/clusters/${editionCode}`), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': 'all',
      Authorization: process.env.REACT_APP_API_KEY ?? '',
    },
  }).then((res) => {
    switch (res.status) {
      case 200:
        return res.json();
      case 500:
        throw new Error(res.statusText);
      default:
        throw new Error('Server Error');
    }
  }),
);

const getTotemExhibitorsCategoriesPending = (
  state: TotemExhibitorsState,
  action: PayloadAction<void>,
): void => {
  state.exhibitorsCategoriesLoading = true;
};

const getTotemExhibitorsCategoriesFulfilled = (
  state: TotemExhibitorsState,
  action: PayloadAction<any, any, any>,
): void => {
  state.exhibitorsCategoriesLoading = false;
  state.exhibitorsCategories = action.payload.data;
  state.clustersList = getClusters(action.payload.data);
};

const getTotemExhibitorsCategoriesRejected = (
  state: TotemExhibitorsState,
  action: PayloadAction<{ error: Error }>,
): void => {
  state.exhibitorsCategoriesLoading = false;
  state.exhibitorsCategoriesError = action.payload;
  console.log(action.payload);
};

export const getTotemExhibitorsSectorsAction = createAsyncThunk(
  'exhibitors/getTotemExhibitorsSectorsAction',
  (
    { editionCode, language }: { editionCode: string; language: string },
    thunkAPI,
  ) => fetch(generateUrl(`/hub/searchCommodities/${editionCode}`), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': 'all',
      Authorization: process.env.REACT_APP_API_KEY ?? '',
    },
  }).then((res) => {
    switch (res.status) {
      case 200:
        return res.json();
      case 500:
        throw new Error(res.statusText);
      default:
        throw new Error('Server Error');
    }
  }),
);

const getTotemExhibitorsSectorsPending = (
  state: TotemExhibitorsState,
  action: PayloadAction<void>,
): void => {
  state.exhibitorsSectorsLoading = true;
};

const getTotemExhibitorsSectorsFulfilled = (
  state: TotemExhibitorsState,
  action: PayloadAction<any, any, any>,
): void => {
  state.exhibitorsSectorsLoading = false;
  state.exhibitorsSectors = action.payload.data;
};

const getTotemExhibitorsSectorsRejected = (
  state: TotemExhibitorsState,
  action: PayloadAction<{ error: Error }>,
): void => {
  state.exhibitorsSectorsLoading = false;
  state.exhibitorsSectorsError = action.payload;
  console.log(action.payload);
};

export const totemExhibitorsSlice = createSlice<
TotemExhibitorsState,
  any,
  'totemExhibitors'
>({
  name: 'totemExhibitors',
  initialState: {
    exhibitorsLoading: false,
    exhibitors: [],
    exhibitorsError: null,

    exhibitorsCategoriesLoading: false,
    exhibitorsCategories: [],
    exhibitorsCategoriesError: null,

    exhibitorsSectorsLoading: false,
    exhibitorsSectors: [],
    exhibitorsSectorsError: null,

    filteredExhibitors: [],
    clustersList: [],

    filters: {
      searchExhibitorsFilter: '',
      padsExhibitorsFilter: [],
      categoriesFilter: [],
      countryExhibitorsFilter: [],
      stateExhibitorsFilter: '',
      cityExhibitorsFilter: '',
    },
  },
  reducers: {
    updateFilterQuery: (
      state: TotemExhibitorsState,
      action: PayloadAction<string>,
    ) => {
      state.filters.searchExhibitorsFilter = action.payload;
    },
    updatePadsFilter: (
      state: TotemExhibitorsState,
      action: PayloadAction<string>,
    ) => {
      if (action.payload) {
        const found = state.filters.padsExhibitorsFilter.findIndex(
          (item) => item === action.payload,
        );
        if (found !== -1) {
          state.filters.padsExhibitorsFilter = state
            .filters
            .padsExhibitorsFilter.filter((item) => item !== action.payload);
        } else {
          state.filters.padsExhibitorsFilter.push(action.payload);
        }
      }
    },
    updateCategoryFilter: (
      state: TotemExhibitorsState,
      action: PayloadAction<string>,
    ) => {
      if (action.payload) {
        const found = state.filters.categoriesFilter.findIndex(
          (item) => item === action.payload,
        );
        if (found !== -1) {
          state.filters.categoriesFilter = state
            .filters
            .categoriesFilter.filter((item) => item !== action.payload);
        } else {
          state.filters.categoriesFilter.push(action.payload);
        }
      }
      const reducer = (acc: Array<any>, item: string): Array<string> => {
        const currCat = state.exhibitorsCategories.find((cluster) => cluster.id === item);
        if (currCat) {
          return [...acc, ...currCat.exhibitors];
        }
        return acc;
      };
      const exhibitorsIdInSelectedCategories = state
        .filters
        .categoriesFilter
        .reduce(reducer, []);
      if (exhibitorsIdInSelectedCategories.length > 0) {
        state.filteredExhibitors = state
          .filteredExhibitors
          .filter((exhibitor) => exhibitorsIdInSelectedCategories.includes(exhibitor.id));
      }
    },
    updateNationalityFilter: (
      state: TotemExhibitorsState,
      action: PayloadAction<string>,
    ) => {
      if (action.payload) {
        const found = state.filters.countryExhibitorsFilter.findIndex(
          (item) => item === action.payload,
        );
        if (found !== -1) {
          state.filters.countryExhibitorsFilter = state
            .filters
            .countryExhibitorsFilter.filter((item) => item !== action.payload);
        } else {
          state.filters.countryExhibitorsFilter.push(action.payload);
        }
      }
    },
    updateStateFilter: (
      state: TotemExhibitorsState,
      action: PayloadAction<string>,
    ) => {
      state.filters.stateExhibitorsFilter = action.payload;
    },
    updateCityFilter: (
      state: TotemExhibitorsState,
      action: PayloadAction<string>,
    ) => {
      state.filters.cityExhibitorsFilter = action.payload;
    },
    resetFilters: (state: TotemExhibitorsState) => {
      state.filters = {
        searchExhibitorsFilter: '',
        padsExhibitorsFilter: [],
        categoriesFilter: [],
        countryExhibitorsFilter: [],
        stateExhibitorsFilter: '',
        cityExhibitorsFilter: '',
      };
    },
  },
  extraReducers: {
    [(getTotemExhibitorsAction.pending as unknown) as string]: getTotemExhibitorsPending,
    [(getTotemExhibitorsAction.fulfilled as unknown) as string]: getTotemExhibitorsFulfilled,
    [(getTotemExhibitorsAction.rejected as unknown) as string]: getTotemExhibitorsRejected,
    [(getTotemExhibitorsCategoriesAction.pending as unknown) as string]: getTotemExhibitorsCategoriesPending,
    [(getTotemExhibitorsCategoriesAction.fulfilled as unknown) as string]: getTotemExhibitorsCategoriesFulfilled,
    [(getTotemExhibitorsCategoriesAction.rejected as unknown) as string]: getTotemExhibitorsCategoriesRejected,
    [(getTotemExhibitorsSectorsAction.pending as unknown) as string]: getTotemExhibitorsSectorsPending,
    [(getTotemExhibitorsSectorsAction.fulfilled as unknown) as string]: getTotemExhibitorsSectorsFulfilled,
    [(getTotemExhibitorsSectorsAction.rejected as unknown) as string]: getTotemExhibitorsSectorsRejected,
  },
});

export default totemExhibitorsSlice.reducer;

export const {
  updateFilterQuery,
  updatePadsFilter,
  resetFilters,
  updateCategoryFilter,
  updateNationalityFilter,
} = totemExhibitorsSlice.actions;

export const exhibitorsStateSelector = (state: {
  totemExhibitors: TotemExhibitorsState;
}): TotemExhibitorsState => state.totemExhibitors;
