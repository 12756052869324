import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { H3 } from './headings';
import TotemIcon from './icon';
import theme from '../theme';

const InfoCardButtonContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;
  max-height: 611px;
  
  width: ${(props) => (props.$oddCard ? '100%' : 'calc(50% - 25px)')};
`;

const InfoCardButtonDetail = styled.div`
  display: flex;
  align-items: center;

  padding: 50px 50px 25px 50px;
  border-right: 2px solid ${theme.secondaryLineColor};
  border-bottom: 2px solid ${theme.secondaryLineColor};
  border-left: 2px solid ${theme.secondaryLineColor};
  border-radius: 2px;
`;

const CardTitle = styled(H3)`
  flex: 1;
`;

const InfoFakeButton = styled.div`
  padding: 0 7px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  font-size: ${theme.fontSize.h2};
`;

const InfoCardButton = ({
  title, imageSrc, imageAlt, cardClickHandler, fullWidth, cardIndex,
}) => {
  const buttonHandler = useCallback(() => {
    cardClickHandler(cardIndex);
  }, [cardClickHandler, cardIndex]);

  return (
    <InfoCardButtonContainer $oddCard={fullWidth} onClick={buttonHandler}>
      <img src={imageSrc} alt={imageAlt} />
      <InfoCardButtonDetail>
        <CardTitle>{title}</CardTitle>
        <InfoFakeButton>
          <TotemIcon icon="info" />
        </InfoFakeButton>
      </InfoCardButtonDetail>
    </InfoCardButtonContainer>
  );
};

InfoCardButton.propTypes = {
  title: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  cardClickHandler: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  cardIndex: PropTypes.number.isRequired,
};

InfoCardButton.defaultProps = {
  fullWidth: false,
};

export default InfoCardButton;
