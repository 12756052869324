import { createAsyncThunk } from '@reduxjs/toolkit';
import generateUrl from '../../generate-url';

export default createAsyncThunk(
  'login/doSignIn',
  ({ username, password }: { username: string; password: string; }, thunkAPI) => fetch(
    generateUrl('/endpoint/sign-in'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ u: username, p: password }),
    },
  )
    .then((res) => {
      switch (res.status) {
        case 200: return res.json();
        case 500: throw new Error(res.statusText);
        default: throw new Error('Server Error');
      }
    }),
);
