import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { configurationStateSelector } from '../store/totem-configuration-slice';
import useTotemCustomSelector from '../common/use-totem-selector';
import InfoCardButton from '../components/info-card-button';
import { H2 } from '../components/headings';
import TotemLeftPanel from '../components/totem-left-panel';
import TotemRightPanel from '../components/totem-right-panel';

const HomeExhibitorContainer = styled.div`
  display: flex;
`;

const HomeExhibitor = ({ accessibilityActive }) => {
  const { t, i18n } = useTranslation('home-pages');
  const history = useHistory();
  const { exhibitorId } = useParams();

  // const cardButtonHandler = useCallback((index) => {
  //   history.push(`${history.location.pathname}/detail/${index}`);
  // }, [history]);

  return (
    <HomeExhibitorContainer>
      <TotemLeftPanel accessibilityActive={accessibilityActive}>
        <H2 />
      </TotemLeftPanel>
      <TotemRightPanel
        accessibilityActive={accessibilityActive}
        showSearchBar={false}
      >
        <H2>{exhibitorId}</H2>
      </TotemRightPanel>
    </HomeExhibitorContainer>
  );
};

HomeExhibitor.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
};

HomeExhibitor.defaultProps = {};

export default HomeExhibitor;
