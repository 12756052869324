import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../theme';
import TotemIcon from './icon';

const CategoryCardContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  
  box-sizing: border-box;
  border: 2px solid ${theme.secondaryLineColor};
  width: 260px;
  background-color: white;
`;

const CssImageItem = styled.div.attrs((props) => ({
  style: {
    backgroundImage: `url(${props.$imageUrl})`,
  },
}))`
  width: 260px;
  height: 260px;
  background-position: 50%;
  background-size: cover;

  border-bottom: 2px solid ${theme.secondaryLineColor};
`;

const EmptyLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 260px;
  width: 260px;
  background-color: ${(props) => props.theme.primaryColor};
  border-bottom: 2px solid ${theme.secondaryLineColor};
  color: white;
  font-size: ${theme.fontSize.h2};
`;

const TitleContainer = styled.div`
  text-align: center;
  padding: 10px;
`;

const CategoryCard = ({ title, categoryImg }) => (
  <CategoryCardContainer>
    {categoryImg && <CssImageItem $imageUrl={categoryImg} />}
    {!categoryImg && (
      <EmptyLogo>
        <TotemIcon icon="Eventi" size="100px" />
      </EmptyLogo>
    )}
    <TitleContainer>
      {title}
    </TitleContainer>
  </CategoryCardContainer>
);

CategoryCard.propTypes = {
  title: PropTypes.string.isRequired,
  categoryImg: PropTypes.string,
};

CategoryCard.defaultProps = {
  categoryImg: null,
};

export default CategoryCard;
