import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { darken } from '@material-ui/core/styles';

const TotemButton = styled(Button)`
  background-color: ${(props) => props.theme.primaryColor};
  color: #fff;
  font-size: ${(props) => (props.$fontSize ? props.theme.fontSize[props.$fontSize] : props.theme.fontSize.bodyFont)};
  font-family: ${(props) => props.theme.fontFamily};
  text-transform: none;
  padding: 18px 35px;

  &:hover {
    background-color: ${(props) => darken(props.theme.primaryColor, 0.1)};
  }

  .MuiButton-iconSizeMedium > *:first-child {
    font-size: ${(props) => (props.$iconFontSize ? props.theme.fontSize[props.$iconFontSize] : props.theme.fontSize.bodyFont)};
  }
`;

export default TotemButton;
