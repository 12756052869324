import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../theme';

const Marker = styled.div`
  position: relative;
  
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextItem = styled.span`
  position: absolute;
  white-space: nowrap;
  
  font-family: ${theme.fontFamily};
  font-size: ${theme.fontSize.h3};
  font-weight: bold;
  background-color: ${theme.secondaryMediumColor};
  padding: 30px;
  border-radius: 35px;
  opacity: 0.75;
  color: white;
`;

const CustomIconWrapper = styled.span`
  position: relative;
  top: -70px;
`;

const UpArrowItem = styled.span`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 30px solid ${theme.secondaryMediumColor};;
  left: -30px;
`;

const SecondUpArrowItem = styled(UpArrowItem)`
  top: 27px;
`;

const DownArrowItem = styled.span`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid ${theme.secondaryMediumColor};;
  left: -30px;
`;

const SecondDownArrowItem = styled(DownArrowItem)`
  top: 27px;
`;

const TotemSwitchFloorMarker = ({ label, position, clickHandler }) => {
  const InternalClickHandler = useCallback(() => {
    clickHandler(position);
  }, [clickHandler, position]);

  return (
    <Marker onClick={InternalClickHandler}>
      <TextItem>{label}</TextItem>
      {
      position ? (
        <CustomIconWrapper>
          <UpArrowItem />
          <SecondUpArrowItem />
        </CustomIconWrapper>
      ) : (
        <CustomIconWrapper>
          <DownArrowItem />
          <SecondDownArrowItem />
        </CustomIconWrapper>
      )
    }
    </Marker>
  );
};

TotemSwitchFloorMarker.prototype = {
  label: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  clickHandler: PropTypes.func.isRequired,
};

TotemSwitchFloorMarker.defaultProps = {};

export default TotemSwitchFloorMarker;
