import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';

const TotemRadioButton = styled(Radio)`
  &.Mui-checked {
    color: ${(props) => props.theme.primaryColor}
  }
`;

export default TotemRadioButton;
