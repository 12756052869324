import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { lighten } from '@material-ui/core/styles';
import TotemIcon from './icon';

const TotemRightPanelContainer = styled.div`
  display: flex;
  width: 600px;
  flex-direction: ${(props) => (props.$accessibilityOn ? 'column-reverse' : 'column')};
  background-color: ${(props) => props.theme.secondaryLightColor};

  height: ${(props) => (props.$accessibilityOn ? '1225px' : '2532px')};
`;

const SearchBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.primaryColor};
  padding: 36px;
`;

const SearchTitleLabel = styled.h4`
  font-size: ${(props) => props.theme.fontSize.h4};
  color: white;
`;

const SearchInputContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

const SearchIconWrapper = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;

  cursor: pointer;
  color: white;
  -webkit-text-fill-color: white;
`;

const SearchInputItem = styled.input`
  flex: 1;
  font-size: 32px;
  padding: 22px 24px;

  &:focus-visible {
    outline-color: ${(props) => lighten(props.theme.primaryColor, 0.4)};
    outline-style: auto;
    outline-width: 1px;
  }
`;

const ContentWrapper = styled.div`
  height: ${(props) => (props.$accessibilityOn ? '1008px' : 'auto')};
  overflow: auto;
`;

// const TotemLeftPage = styled.div`
//   width: 1560px;
//   overflow: auto;
// `;

// const TotemRightPage = styled.div`
//   width: 600px;
//   overflow: auto;
//   background-color: ${(props) => props.theme.secondaryLightColor};
// `;

const TotemRightPanel = ({
  showSearchBar,
  searchTitle,
  searchValue,
  accessibilityActive,
  children,
  onPerformSearchClickHandler,
  onSearchInputChangeHandler,
}) => {
  const onKeyUp = useCallback(
    (ev) => {
      if (ev.key === 'Enter') {
        onPerformSearchClickHandler(ev);
      }
    },
    [onPerformSearchClickHandler],
  );

  const { t } = useTranslation('common');

  return (
    <TotemRightPanelContainer
      $accessibilityOn={showSearchBar && accessibilityActive}
    >
      {showSearchBar && (
        <SearchBarWrapper>
          <SearchTitleLabel>{searchTitle}</SearchTitleLabel>
          <SearchInputContainer>
            <SearchInputItem
              type="text"
              onKeyPress={onKeyUp}
              placeholder={t('pages.searchPlaceHolder')}
              onChange={onSearchInputChangeHandler}
              value={searchValue}
            />
            <SearchIconWrapper
              onClick={onPerformSearchClickHandler}
            >
              <TotemIcon icon="Cerca" size="50px" />
            </SearchIconWrapper>
          </SearchInputContainer>
        </SearchBarWrapper>
      )}
      <ContentWrapper
        $accessibilityOn={showSearchBar && accessibilityActive}
      >
        {children}
      </ContentWrapper>
    </TotemRightPanelContainer>
  );
};

TotemRightPanel.propTypes = {
  showSearchBar: PropTypes.bool.isRequired,
  accessibilityActive: PropTypes.bool.isRequired,
  searchTitle: PropTypes.string,
  onSearchInputChangeHandler: PropTypes.func,
  onPerformSearchClickHandler: PropTypes.func,
  searchValue: PropTypes.string,
};

TotemRightPanel.defaultProps = {
  searchTitle: '',
  onSearchInputChangeHandler: null,
  onPerformSearchClickHandler: null,
  searchValue: '',
};

export default TotemRightPanel;
