export const pavilionsConnection = {
  type: 'FeatureCollection',
  features: [{
    geometry: {
      coordinates: [
        [
          [
            9.0791357,
            45.5210092,
          ],
          [
            9.0797026,
            45.5208229,
          ],
          [
            9.0797903,
            45.5209422,
          ],
          [
            9.0792221,
            45.5211316,
          ],
          [
            9.0791357,
            45.5210092,
          ],
        ],
      ],
      type: 'Polygon',
    },
    id: '37822-9999',
    maglieId: 'fid-207e4f20_181b36f4d87_-678a',
    properties: {
      exhibitionCode: '37822',
      fill: '#9A3324',
      floor: 1,
      idPadiglione: 9999,
      matrixId: '37822-0-9999',
      numero: '9999',
      pad: 9999,
      stroke: '#3E3535',
    },
    type: 'Feature',
  },
  ],
};

export const pavilionsTicketOffices = {
  type: 'FeatureCollection',
  features: [{
    geometry: {
      coordinates: [
        [
          [
            9.0807369,
            45.5199745,
          ],
          [
            9.0810487,
            45.5198636,
          ],
          [
            9.0808817,
            45.519624,
          ],
          [
            9.0805688,
            45.5197277,
          ],
          [
            9.080735,
            45.5199734,
          ],
        ],
      ],
      type: 'Polygon',
    },
    id: '37822-9999',
    maglieId: 'fid-207e4f20_181b36f4d87_-678a',
    properties: {
      exhibitionCode: '37822',
      fill: '#9A3324',
      floor: 1,
      idPadiglione: 9999,
      matrixId: '37822-0-9999',
      numero: '9999',
      pad: 9999,
      stroke: '#3E3535',
    },
    type: 'Feature',
  },
  ],
};
