export const analyticsEvents = {
  MAP_LOADED: 'map_loaded',
  MAP_INTERACTION: 'map_interaction',
  CLICK_CALCULATE_PATH_BUTTON: 'click_calculate_path_button',
  CLICK_EXHIBITOR_BUTTON: 'click_exhibitor_button',
  CLICK_EVENT_BUTTON: 'click_event_button',
  CLICK_ACCESSIBILITY_BUTTON: 'click_accessibility_button',
  CLICK_LANGUAGE_BUTTON: 'click_language_button',
  CLICK_FULL_EXHIBITOR_LIST_BUTTON: 'click_full_exhibitor_list_button',
  CLICK_FULL_EXHIBITOR_DETAIL_BUTTON: 'click_full_exhibitor_detail_button',
  CLICK_RESTART_BUTTON: 'click_restart_button',
  CLICK_SERVICE_BUTTON: 'click_service_button',
  CLICK_INFORMATION_BUTTON: 'click_information_button',
  CLICK_EXHIBITOR_FIRST_CATEGORY_BUTTON: 'click_exhibitor_first_category_button',
  CLICK_EXHIBITOR_SECOND_CATEGORY_BUTTON: 'click_exhibitor_second_category_button',
  CLICK_MAP_FLOOR_CHANGE_BUTTON: 'click_map_floor_change_button',
  CLICK_MAP_ZOOM_CHANGE_BUTTON: 'click_map_zoom_change_button',
  SEARCH_HOME: 'search_home',
  SEARCH_EXHIBITOR_CATEGORY: 'search_exhibitor_category',
  SEARCH_EXHIBITOR: 'search_exhibitor',
  SEARCH_EVENT: 'search_event',
  PAGE_INFORMATION: 'page_information',
  PAGE_EXHIBITORS: 'page_exhibitors',
  PAGE_EVENTS: 'page_events',
  PAGE_HOME: 'page_home',
  PAGE_SERVICES: 'page_services',
  PAGE_INFOMOBILITY: 'page_infomobility',
  FILTER_EXHIBITOR: 'filter_exhibitor',
  FILTER_EXHIBITOR_CLEAR: 'filter_exhibitor_clear',
  FILTER_EVENT: 'filter_event',
  FILTER_EVENT_CLEAR: 'filter_event_clear',
  TOTEM_AUTO_UPDATE_DATA: 'totem_auto_update_data',
};

export const eventsDescriptions = {
  CLICK_EXHIBITOR_BUTTON: {
    exhibitorId: 'exhibitorId',
  },
  CLICK_LANGUAGE_BUTTON: {
    languageValue: 'languageValue',
  },
  CLICK_EVENT_BUTTON: {
    eventName: 'eventName',
  },
  CLICK_FULL_EXHIBITOR_DETAIL_BUTTON: {
    exhibitorId: 'exhibitorId',
  },
  CLICK_SERVICE_BUTTON: {
    serviceName: 'serviceName',
  },
  CLICK_INFORMATION_BUTTON: {
    informationName: 'serviceName',
  },
  CLICK_EXHIBITOR_FIRST_CATEGORY_BUTTON: {
    categoryName: 'categoryName',
  },
  CLICK_EXHIBITOR_SECOND_CATEGORY_BUTTON: {
    categoryName: 'categoryName',
  },
  CLICK_MAP_FLOOR_CHANGE_BUTTON: {
    floorValue: 'floorValue',
  },
  CLICK_MAP_ZOOM_CHANGE_BUTTON: {
    zoomValueButton: 'zoomValueButton',
  },
  SEARCH_HOME: {
    searchValue: 'searchValue',
  },
  SEARCH_EXHIBITOR_CATEGORY: {
    searchValue: 'searchValue',
  },
  SEARCH_EXHIBITOR: {
    searchValue: 'searchValue',
  },
  SEARCH_EVENT: {
    searchValue: 'searchValue',
  },
  FILTER_EXHIBITOR: {
    filterValue: 'filterValue',
  },
  FILTER_EVENT: {
    filterValue: 'filterValue',
  },
};
