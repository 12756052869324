import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../theme';

const UpperOverlineText = styled.p`
  margin-top: 30px;
  text-transform: uppercase;
  font-size: ${theme.fontSize.overline};
`;

const BodyTextBold = styled.p`
  margin-top: 16px;
  font-weight: bold;
`;

const LabelValueDetail = ({ labelText, valueText }) => (
  <>
    <UpperOverlineText>{labelText ?? ' - '}</UpperOverlineText>
    <BodyTextBold>{valueText ?? ' - '}</BodyTextBold>
  </>
);

LabelValueDetail.propTypes = {
  labelText: PropTypes.string.isRequired,
  valueText: PropTypes.string,
};

LabelValueDetail.defaultProps = {
  valueText: null,
};

export default LabelValueDetail;
