import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map, filter, debounce } from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  configExhibitorsSectorStateSelector,
  configExhibitorsStringFilterValueStateSelector, logAnalytic,
  resetExhibitorsFilter,
  setSectorFilter,
  setStringFilter,
} from '../store/totem-config-slice';
import ExhibitorCategoryButton from '../components/exhibitor-category-button';
import TotemSearchBar from '../components/totem-search-bar';
import { changeIndexState } from '../store/scroll-restoration-slice';
import { BodyUppercaseText } from '../components/headings';
import TotemKeyboard from '../components/totem-keyboard';
import { analyticsEvents, eventsDescriptions } from '../common/analytics-constants';

const CategoryExhibitorListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchBarContainer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.primaryColor};
`;

const TotemSearchBarElement = styled(TotemSearchBar)`
  width: 70%;
`;

const ExhibitorCategoryListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px;
  & > span {
    display: flex;
    align-self: flex-end;
    color: ${(props) => props.theme.primaryColor};
  }
  & > div:not(:first-child) {
    margin-top: 48px;
  }
`;

const FakeTextButton = styled(BodyUppercaseText)`
  cursor: pointer;
`;

const ExhibitorCategoryList = ({ accessibilityActive }) => {
  const { t, i18n } = useTranslation('exhibitor-pages');
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentActiveCategory, setCurrentActiveCategory] = useState(null);
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const searchBarRef = useRef(null);

  const reduxSearchBarValue = useSelector(configExhibitorsStringFilterValueStateSelector);
  const [searchBarValue, setSearchBarValue] = useState(reduxSearchBarValue);
  const sectorsList = useSelector(configExhibitorsSectorStateSelector);

  useEffect(() => {
    dispatch(resetExhibitorsFilter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(changeIndexState(false));
    dispatch(logAnalytic({ eventName: analyticsEvents.PAGE_EXHIBITORS }));
  }, [dispatch]);

  const logHomeSearchValue = useCallback(
    debounce(
      (value) => {
        dispatch(logAnalytic({
          eventName: analyticsEvents.SEARCH_EXHIBITOR_CATEGORY,
          eventParams: {
            [eventsDescriptions.SEARCH_EXHIBITOR_CATEGORY.searchValue]: value,
          },
        }));
      },
      1500,
    ),
    [],
  );

  const exhibitorClickHandler = useCallback(
    (exhibitorCategory, categoryLevel) => {
      history.push(
        `${history.location.pathname}/exhibitors/${exhibitorCategory.id}`,
      );
      dispatch(setSectorFilter(exhibitorCategory));
      if (categoryLevel === '1') {
        dispatch(
          logAnalytic({
            eventName: analyticsEvents.CLICK_EXHIBITOR_FIRST_CATEGORY_BUTTON,
            eventParams: {
              [eventsDescriptions.CLICK_EXHIBITOR_FIRST_CATEGORY_BUTTON
                .categoryName]: exhibitorCategory.name.it,
            },
          }),
        );
      }
      if (categoryLevel === '2') {
        dispatch(
          logAnalytic({
            eventName: analyticsEvents.CLICK_EXHIBITOR_SECOND_CATEGORY_BUTTON,
            eventParams: {
              [eventsDescriptions.CLICK_EXHIBITOR_SECOND_CATEGORY_BUTTON
                .categoryName]: exhibitorCategory.name.it,
            },
          }),
        );
      }
    },
    [dispatch, history],
  );

  const getSubItems = (source, indexLevel) => source.filter((item) => item.level === 2)
    .filter((item) => item.product.startsWith(`${indexLevel}.`));

  const categoryButtonClickHandler = useCallback((item, itemIndex) => {
    const items = getSubItems(sectorsList, item.product);

    if (items.length > 1) {
      setCurrentActiveCategory((prev) => (prev === itemIndex ? null : itemIndex));
    }
    if (items.length === 1) {
      exhibitorClickHandler(items[0], '1');
    }
    if (items.length === 0) {
      exhibitorClickHandler(item, '1');
    }
  }, [exhibitorClickHandler, sectorsList]);

  const searchBarTextChangeHandler = useCallback(
    (event) => {
      setSearchBarValue(event.target.value);
      logHomeSearchValue(event.target.value);
    },
    [logHomeSearchValue],
  );

  const performSearchBarQuery = useCallback(
    () => {
      dispatch(setStringFilter(searchBarValue));
      history.push(`${history.location.pathname}/exhibitors/undefined`);
      dispatch(logAnalytic({ eventName: analyticsEvents.CLICK_FULL_EXHIBITOR_LIST_BUTTON }));
    },
    [dispatch, history, searchBarValue],
  );

  const showAllExhibitor = useCallback(
    () => {
      history.push(`${history.location.pathname}/exhibitors/undefined`);
    },
    [history],
  );

  const firstLevelCategory = useMemo(
    () => filter(sectorsList, (item) => item.level === 1),
    [sectorsList],
  );

  const closeKeyBoardHandler = useCallback(() => {
    setKeyboardOpen(false);
  }, []);

  const keyBoardInputChange = useCallback((value) => {
    let toSave = searchBarValue + value;
    if (value === 'enter') {
      toSave = toSave.substring(0, toSave.length - 5);
      setSearchBarValue(toSave);
      logHomeSearchValue(toSave);
      performSearchBarQuery();
      return;
    }
    if (value === '⇦') {
      toSave = searchBarValue.substring(0, searchBarValue.length - 1);
    }
    if (value === 'cancel') {
      toSave = '';
    }
    setSearchBarValue(toSave);
    logHomeSearchValue(toSave);
  }, [logHomeSearchValue, performSearchBarQuery, searchBarValue]);

  const openKeyBoardEvent = useCallback(() => {
    setKeyboardOpen(true);
  }, []);

  const categoryListToRender = useMemo(() => map(firstLevelCategory, (item, index) => (
    <ExhibitorCategoryButton
      key={`level_${item.id}`}
      image={item.logoUrl}
      title={item.name[i18n.language]}
      buttonClickHandler={categoryButtonClickHandler}
      activeItem={currentActiveCategory === index}
      itemIndex={index}
      propItem={item}
      subCategoriesTitle={item.name[i18n.language]}
      subCategories={getSubItems(sectorsList, item.product)}
      subCategoryClickHandler={exhibitorClickHandler}
    />
  )), [
    categoryButtonClickHandler,
    currentActiveCategory,
    sectorsList,
    firstLevelCategory,
    i18n.language,
    exhibitorClickHandler,
  ]);

  return (
    <CategoryExhibitorListContainer>
      <SearchBarContainer>
        <TotemSearchBarElement
          searchTitle={t('find_exhibitor')}
          onSearchInputChangeHandler={searchBarTextChangeHandler}
          onPerformSearchClickHandler={performSearchBarQuery}
          searchValue={searchBarValue}
          onInputFocusHandler={openKeyBoardEvent}
        />
      </SearchBarContainer>
      <ExhibitorCategoryListWrapper>
        <FakeTextButton onClick={showAllExhibitor}>{t('show_full_list')}</FakeTextButton>
        {firstLevelCategory.length > 0 && categoryListToRender}
      </ExhibitorCategoryListWrapper>
      <TotemKeyboard
        isKeyboardOpen={keyboardOpen}
        onKeyboardCloseHandler={closeKeyBoardHandler}
        onKeyboardButtonPressed={keyBoardInputChange}
        itemToIgnoreRef={searchBarRef.current}
      />
    </CategoryExhibitorListContainer>
  );
};

ExhibitorCategoryList.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
};

ExhibitorCategoryList.defaultProps = {};

export default ExhibitorCategoryList;
