import React, { useCallback } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import LandingPage from './landing-page';
import { Reset } from '../reset';
import theme from '../theme';
import { configurationColorStateSelector } from '../store/totem-configuration-slice';
import '@fontsource/mulish';
import '@fontsource/mulish/700.css';

const AppContainer = styled.div`
  font-size: ${(props) => props.theme.fontSize.bodyFont};
  font-family: ${(props) => props.theme.fontFamily};
`;

const App = () => {
  const newThemeColor = useSelector(configurationColorStateSelector);

  const updatedTheme = useCallback(() => {
    if (newThemeColor) {
      return {
        ...theme,
        primaryColor: newThemeColor.primaryColor,
        primaryBarColor: newThemeColor.primaryBarColor,
      };
    }

    return theme;
  }, [newThemeColor]);

  return (
    <ThemeProvider theme={updatedTheme}>
      <Reset />
      <AppContainer>
        <Switch>
          <Route
            path="/:totemId"
            render={(routerProps) => <LandingPage routerProps={routerProps} />}
          />
          <Route path="*">
            <h1>Page not found 404</h1>
          </Route>
        </Switch>
      </AppContainer>
    </ThemeProvider>
  );
};

export default App;
