import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Switch,
  Route,
  useRouteMatch,
  NavLink,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TotemIcon from './icon';
import theme from '../theme';
import routesConfiguration from '../common/routesConfiguration';
import PageContainer from './page-container';

const TotemActiveNavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const MenuContainer = styled.div`
  display: flex;
  width: 100%;
`;

const MenuItemText = styled.span`
  margin-top: 10px;
  text-transform: capitalize;
`;

const TotemNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: white;
  color: ${(props) => props.theme.primaryColor};
  text-decoration: none;
  padding: 40px 0 40px 0;

  &.selected {
    background-color: ${(props) => props.theme.primaryColor};
    color: white;
  }
`;

const MenuItem = ({
  path, title, icon, activeClassName, exact,
}) => (
  <TotemNavLink to={path} exact={exact} activeClassName={activeClassName}>
    <TotemIcon icon={icon} size={theme.fontSize.h2} />
    <MenuItemText>{title}</MenuItemText>
  </TotemNavLink>
);

const FakePage = styled.h3`
  height: 2614px;
  vertical-align: center;
  text-align: center;
  font-size: 104px;
  font-weight: bold;
`;

const TotemActiveNavigation = (props) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation('common');
  const { accessibilityActive } = props;

  const backButtonClickHandler = useCallback(() => history.goBack(), [history]);

  const routeItems = (routes, parentPath, discriminator) => routes.map((item) => {
    const newPath = item.path === '' ? `${parentPath}/` : parentPath + item.path;
    const outerKey = discriminator + newPath;

    const getComponent = (currItem, upperPath) => {
      if (currItem.children) {
        return (
          <Switch>
            {routeItems(currItem.children, upperPath, 'sro')}
            <Redirect from="*" to="" />
          </Switch>
        );
      }
      if (currItem.component) {
        return (
          <PageContainer
            accessibilityActive={accessibilityActive}
            labelKey={item.breadcrumbKey}
            backButton={item.showBackButton}
            backButtonClick={backButtonClickHandler}
          >
            <currItem.component accessibilityActive={accessibilityActive} />
          </PageContainer>
        );
      }
      return <FakePage>{t(currItem.title)}</FakePage>;
    };

    return (
      <Route
        key={outerKey}
        path={newPath}
        exact={item.exact}
        render={() => getComponent(item, newPath)}
      />
    );
  });

  const menuItems = (routes) => routes.map((item) => {
    const newPath = url + item.path;
    const itemKey = `mi${newPath}`;

    return (
      <MenuItem
        key={itemKey}
        path={newPath}
        title={t(item.title)}
        icon={item.icon}
        exact={!item.children}
        activeClassName="selected"
      />
    );
  });

  return (
    <TotemActiveNavigationContainer>
      <Switch>
        {routeItems(routesConfiguration, path, 'ro')}
        <Redirect from="*" to="" />
      </Switch>

      <MenuContainer>{menuItems(routesConfiguration)}</MenuContainer>
    </TotemActiveNavigationContainer>
  );
};

TotemActiveNavigation.propTypes = {};

TotemActiveNavigation.defaultProps = {};

export default TotemActiveNavigation;
