import { createAsyncThunk } from '@reduxjs/toolkit';
import generateUrl from '../../generate-url';
import getTotemConfigurationAction from './get-totem-configuration';

const fetchConfig = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'all',
    Authorization: process.env.REACT_APP_API_KEY ?? '',
  },
};

export default createAsyncThunk(
  'configuration/getTotemExhibitionData',
  async ({ totemId }: { totemId: string }, thunkAPI) => {
    try {
      // const appConfig = await fetch(generateUrl(`/totem/${totemId}`), fetchConfig);
      const appConfig = await thunkAPI.dispatch(getTotemConfigurationAction({ totemId }));
      const { editionCode } = appConfig.payload.appTotemConfig;
      const res = await Promise.all([
        fetch(generateUrl(`/app-configuration/${editionCode}?v=v1`), fetchConfig),

        fetch(generateUrl(`/hub/searchExhibitors/${editionCode}`), fetchConfig),
        fetch(generateUrl(`/hub/clusters/${editionCode}`), fetchConfig),
        fetch(generateUrl(`/hub/searchCommodities/${editionCode}`), fetchConfig),
        fetch(generateUrl(`/hub/searchExhibitors/${editionCode}/brands`), fetchConfig),

        fetch(generateUrl(`/hub/searchEvents/${editionCode}`), fetchConfig),
        fetch(generateUrl(`/hub/eventCategories/${editionCode}`), fetchConfig),

        fetch(generateUrl(`/maglie/pois/${editionCode}/0`), fetchConfig),
        fetch(generateUrl(`/maglie/pois/${editionCode}/1`), fetchConfig),

        fetch(generateUrl(`/maglie/pavilions/${editionCode}/0`), fetchConfig),
        fetch(generateUrl(`/maglie/pavilions/${editionCode}/1`), fetchConfig),

        fetch(generateUrl(`/maglie/stands/${editionCode}/0`), fetchConfig),
        fetch(generateUrl(`/maglie/stands/${editionCode}/1`), fetchConfig),
      ]);
      return await Promise.all(res.map((r) => {
        switch (r.status) {
          case 200:
            return r.json();
          case 500:
            throw new Error(r.statusText);
          default:
            throw new Error('Server Error');
        }
      }));
    } catch (err) {
      console.log(err);
      throw new Error('Server Error');
    }
  },
);
