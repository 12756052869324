import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TotemIcon from './icon';

const MapControllerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ZoomButton = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
  width: 85px;
  background-color: ${(props) => props.theme.secondaryMediumColor};
  margin-top: ${(props) => props.$margin && '4px'};

  cursor: pointer;
  color: white;
  -webkit-text-fill-color: white;
`;

const MapController = ({ zoomIn, zoomOut }) => (
  <MapControllerContainer>
    <ZoomButton onClick={zoomIn}>
      <TotemIcon icon="Aggiungi" size="50px" />
    </ZoomButton>
    <ZoomButton $margin onClick={zoomOut}>
      <TotemIcon icon="Rimuovi" size="50px" />
    </ZoomButton>
  </MapControllerContainer>
);

MapController.propTypes = {
  zoomIn: PropTypes.func.isRequired,
  zoomOut: PropTypes.func.isRequired,
};

MapController.defaultProps = {};

export default MapController;
