import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { darken } from '@material-ui/core/styles';
import theme from '../theme';

const TotemCheckbox = styled(Checkbox)`
  color: ${(props) => darken(props.theme.primaryColor, 0.2)};

  & .MuiSvgIcon-root {
    font-size: ${theme.h2.fontSize};
  }
  
  &.Mui-checked {
    color: ${(props) => props.theme.primaryColor};
  }
`;

export default TotemCheckbox;
