import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import {
  bbox, center, lineString, point, getCoord,
} from '@turf/turf';
import { useTranslation } from 'react-i18next';
import theme from '../theme';
import MapControllerWrapper from './map-controller-wrapper';
import MapController from './map-controller';
import MapFloorController from './map-floor-controller';
import MapBoxElement from './map-box-element';
import {
  configStateSelector,
  configurationPoisListSelector,
  configurationWayFindingEnabled,
  filteredServicesStateSelector,
  logAnalytic,
  servicesDrawExhibitorPathSelector,
  servicesPavilionsCentersSelector,
  servicesPavilionsSelector,
  servicesSelectedPoiSelector,
  servicesStandsSelector,
  StandLabelStyle,
  updateUtilsApiError,
} from '../store/totem-config-slice';
import TooltipContent from './totem-tooltip-content';
import getNewNavigationRoute from '../common/maps-api';
import TotemBackButton from './totem-back-button';
import Spinner from './spinner';
import TotemPositionMarker from './totem-position-marker';
import TotemGateMarker from './totem-gate-marker';
import TotemSwitchFloorMarker from './totem-switch-floor-maker';
import { analyticsEvents, eventsDescriptions } from '../common/analytics-constants';
import MapLegend from './map-legend';
import MapLegendWrapper from './map-legend-wrapper';
import { pavilionsConnection, pavilionsTicketOffices } from '../common/maps-polygon';

// const mapStyleUrl = 'mapbox://styles/mapbox/light-v10'; OLD URL
// const mapStyleUrl = 'mapbox://styles/crispybaconsrl/cky2uqxjelgdn14myr0ut1pej';
const mapStyleUrl = 'mapbox://styles/crispybaconsrl/cl06seibp000114nzkhm7k4nq';

const TotemMapContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const TotemMapLoaderOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: ${(props) => (props.$active ? '1' : '0')};
  visibility: ${(props) => (props.$active ? 'show' : 'hidden')}
`;

const TotemMapLoader = styled.div`
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  width: 128px;
  height: 128px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  visibility: ${(props) => (props.$active ? 'show' : 'hidden')}
`;

const NavigationStickerButtons = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 50px;
  z-index: 3;
  opacity: 0.9;
  top: 50%;
  -ms-transform: translateY(-62%);
  transform: translateY(-62%);
`;

const builtTooltipElement = (
  feature,
  activeTheme,
  funcHandler,
  selectedFloor,
  optionalIconTitleName,
  optionalTitleI18nKey = '',
  pad = null,
  showNavigationButton,
) => {
  const placeholder = document.createElement('div');

  if (feature && activeTheme && funcHandler) {
    const {
      geometry,
      id,
      properties,
    } = feature;

    ReactDOM.render(
      <TooltipContent
        description={properties.description ?? ''}
        floor={selectedFloor}
        iconTitle={optionalIconTitleName}
        title={properties.title ?? ''}
        name={properties.name ?? ''}
        color={activeTheme.primaryColor}
        feature={feature}
        calculatePathHandler={funcHandler}
        showNavigationButton={showNavigationButton}
      />,
      placeholder,
    );
  } else {
    ReactDOM.render(
      <TooltipContent
        description=""
        floor="0"
        title={optionalTitleI18nKey}
        name={`${pad} PAD`}
        color={activeTheme.primaryColor}
        feature={feature}
        calculatePathHandler={funcHandler}
        showNavigationButton={showNavigationButton}
      />,
      placeholder,
    );
  }

  const markerHeight = 82;
  const markerRadius = 54;
  const linearOffset = 25;
  const popupOffsets = {
    top: [0, 0],
    'top-left': [0, 0],
    'top-right': [0, 0],
    bottom: [0, -markerHeight],
    'bottom-left': [
      linearOffset,
      (markerHeight - markerRadius + linearOffset) * -1,
    ],
    'bottom-right': [
      -linearOffset,
      (markerHeight - markerRadius + linearOffset) * -1,
    ],
    left: [markerRadius, (markerHeight - markerRadius) * -1],
    right: [-markerRadius, (markerHeight - markerRadius) * -1],
  };

  const popup = new mapboxgl.Popup({
    offset: popupOffsets,
    maxWidth: '720px',
  }).setDOMContent(placeholder);

  return popup;
};

const findExhibitorPavilion = (exhibitorsPerFloor, exhibitor) => {
  let floorFound;
  exhibitorsPerFloor.forEach((floorExhibitors) => floorExhibitors
    .features.forEach(({ properties }) => {
      if (properties.eboothIds.includes(exhibitor)) {
        floorFound = properties.floor;
      }
    }));
  return floorFound ?? 0;
};

const getDefaultMapFloor = (totemFloor, totemStands, selectedExhibitor) => {
  if (selectedExhibitor && totemStands && totemFloor) {
    return findExhibitorPavilion(totemStands, selectedExhibitor) ?? Number(totemFloor);
  }
  if (totemFloor) {
    return Number(totemFloor);
  }
  return 0;
};

const rhoFieraMilanoGates = [
  {
    coordinates: [
      9.07727658748627,
      45.517938268837,
    ],
    title: {
      it: 'Porta Sud TIM',
      en: 'South Gate',
    },
  },
  {
    coordinates: [
      9.0719336271286,
      45.5227060693694,
    ],
    title: {
      it: 'Porta Ovest TIM',
      en: 'West Gate',
    },
  },
  {
    coordinates: [
      9.086052775383,
      45.51780671165609,
    ],
    title: {
      it: 'Porta Est',
      en: 'East Gate',
    },
  },
];

const TotemMaps = ({
  accessibilityActive,
  exhibitorDetail,
  isServicesPage,
  restoreButtonCLickHandler,
}) => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation('service-page');

  const totemServicesSelector = useCallback(filteredServicesStateSelector, []);
  const totemServices = useSelector(totemServicesSelector, shallowEqual);
  const totemSelectedService = useSelector(servicesSelectedPoiSelector, shallowEqual);
  const totemServicesPavilion = useSelector(servicesPavilionsSelector, shallowEqual);
  const totemServicesStands = useSelector(servicesStandsSelector, shallowEqual);
  const totemConfig = useSelector(configStateSelector, shallowEqual);
  const totemPavilionsCenters = useSelector(servicesPavilionsCentersSelector, shallowEqual);
  const drawExhibitorPath = useSelector(servicesDrawExhibitorPathSelector, shallowEqual);
  const poiSelector = useCallback(configurationPoisListSelector, []);
  const poiList = useSelector(poiSelector, shallowEqual);
  const showWayFinding = useSelector(configurationWayFindingEnabled, shallowEqual);

  const buildingsFloor = 2;
  const [activeFloor, setActiveFloor] = useState(0);
  const [destPoint, setDestPoint] = useState(null);
  const [pathToDest, setPathToDest] = useState(null);
  const exhibitorPosition = useRef(null);

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [mapReady, setMapReady] = useState(false);
  const [lng, setLng] = useState(9.078530490232168);
  const [lat, setLat] = useState(45.520190785355254);
  const [zoom, setZoom] = useState(16.1);
  const markersList = useRef([]);
  const pavilionMarkersName = useRef([]);
  const gatesMarkersName = useRef([]);
  const currentTotemPositionMarker = useRef(null);
  const floorSwitchMarker = useRef(null);
  const centeredCameraFollowTotemPosition = useRef(true);
  const defaultMapBounds = [
    [9.070046360170124, 45.514918413978364],
    [9.088600911884384, 45.52603324824966],
  ];
  const [isMapLoading, setIsMapLoading] = useState(false);

  const themeContext = useContext(ThemeContext);

  // ------------------------------ SET INITIAL FLOOR BASED ON SOME LOGIC
  useEffect(() => {
    const initialFloor = getDefaultMapFloor(
      String(totemConfig?.appTotemConfig?.totemPavillion?.floor),
      totemServicesStands,
      exhibitorDetail,
    );
    setActiveFloor(initialFloor);
  }, [exhibitorDetail, totemConfig.appTotemConfig, totemServicesStands]);

  const calcPathHandler = useCallback(
    (selectedPoi) => {
      setDestPoint(selectedPoi);
      console.log(selectedPoi);
      const [long, lati] = selectedPoi.geometry.coordinates;
      const poi = {
        longitude: long,
        latitude: lati,
        floor: selectedPoi.properties.floor ?? 0,
        id: selectedPoi.properties.poiid ?? selectedPoi.id,
      };
      const currPos = totemConfig.appTotemConfig.totemPavillion;

      const savePath = (dataToSave) => {
        setPathToDest(dataToSave);
        dispatch(updateUtilsApiError(null));
      };

      const manageError = (err) => {
        console.log(err);
        exhibitorPosition.current = null;
        dispatch(updateUtilsApiError('maps_path'));
      };

      setIsMapLoading(true);
      const editionCode = totemConfig.appTotemConfig?.editionCode;
      getNewNavigationRoute(
        {
          longitude: currPos.longitude,
          latitude: currPos.latitude,
          floor: currPos.floor,
        },
        poi,
        editionCode,
      )
        .then(savePath)
        .catch(manageError)
        .finally(() => setIsMapLoading(false));

      dispatch(logAnalytic({
        eventName: analyticsEvents.CLICK_CALCULATE_PATH_BUTTON,
      }));
    },
    [
      dispatch,
      totemConfig.appTotemConfig.totemPavillion,
      totemConfig.appTotemConfig.editionCode,
    ],
  );

  const drawTotemPositionMarker = useCallback(() => {
    const currPos = totemConfig.appTotemConfig.totemPavillion;
    if (!currPos) return;
    if (
      currentTotemPositionMarker.current
      && currPos.floor === activeFloor
    ) {
      return;
    }
    if (
      currentTotemPositionMarker.current
      && currPos.floor !== activeFloor
    ) {
      currentTotemPositionMarker.current.remove();
      currentTotemPositionMarker.current = null;
      return;
    }
    if (
      !currentTotemPositionMarker.current
      && currPos.floor !== activeFloor
    ) {
      return;
    }
    const lnglat = [currPos.longitude, currPos.latitude];
    // const popup = builtTooltipElement(
    //   null,
    //   themeContext,
    //   null,
    //   activeFloor,
    //   '',
    //   'your_position',
    //   currPos.pavillion,
    // );
    const el = document.createElement('div');
    ReactDOM.render(
      <TotemPositionMarker labelColor={themeContext.primaryColor} />,
      el,
    );
    const marker = new mapboxgl.Marker({
      element: el,
      scale: 3,
    })
      .setLngLat(lnglat)
      // .setPopup(popup)
      .addTo(map.current);

    currentTotemPositionMarker.current = marker;
  }, [activeFloor, themeContext.primaryColor, totemConfig.appTotemConfig.totemPavillion]);

  const drawGatesNamesMarkers = useCallback(() => {
    if (gatesMarkersName.current.length > 0) {
      gatesMarkersName.current.forEach((item) => item.remove());
      gatesMarkersName.current = [];
    }

    rhoFieraMilanoGates.forEach((gate) => {
      const innerText = gate.title[i18n.language];
      const el = document.createElement('div');
      ReactDOM.render(
        <TotemGateMarker name={innerText} />,
        el,
      );

      const marker = new mapboxgl.Marker({
        element: el,
      })
        .setLngLat(gate.coordinates)
        .addTo(map.current);

      gatesMarkersName.current.push(marker);
    });
  }, [i18n.language]);

  const drawPavilionNamesMarkers = useCallback((useless) => {
    if (totemServicesPavilion.length === 0) return;
    if (pavilionMarkersName.current.length > 0) {
      pavilionMarkersName.current.forEach((item) => item.remove());
      pavilionMarkersName.current = [];
    }

    totemServicesPavilion[activeFloor].features.forEach((feature) => {
      const el = document.createElement('span');
      // el.innerText = `${t('pavilion')} ${feature.properties.numero}`;
      el.innerText = feature.properties.numero;
      el.style.fontSize = theme.fontSize.customPavilionLabel;

      const marker = new mapboxgl.Marker({
        element: el,
        // offset: pavilionOffset[feature.properties.numero],
      })
        .setLngLat(getCoord(center(feature.geometry)));

      if (map.current.getZoom() < 18.5) {
        marker.addTo(map.current);
      }

      pavilionMarkersName.current.push(marker);
    });
  }, [activeFloor, totemServicesPavilion]);

  // ------------------------------ MAP INIT

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapStyleUrl,
      center: [lng, lat],
      zoom,
      // bearing: 180 / -63.45,
      maxBounds: [
        9.063379665309498,
        45.51092178021187,
        9.093597489747339,
        45.529009993038386,
      ],
    });

    // ------------------------- used to set map ready

    map.current.on('load', () => {
      setMapReady(true);
      dispatch(logAnalytic({ eventName: analyticsEvents.MAP_LOADED }));
    });

    // ------------------------- used to reset the landing page counter

    map.current.on('movestart', () => {
      mapContainer.current.click();
    });

    // ------------------------- used to hide the pavilion marker on zoom in/out

    map.current.on('zoom', () => {
      if (map.current.getZoom() >= 18.5 && pavilionMarkersName.current.length > 0) {
        pavilionMarkersName.current.forEach((item) => item.remove());
      }
      if (map.current.getZoom() < 18.5 && pavilionMarkersName.current.length > 0) {
        pavilionMarkersName.current.forEach((item) => item.addTo(map.current));
      }
    });

    // eslint-disable-next-line consistent-return
    return () => map.current.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng, zoom]);

  // ------------------------------ Manage map click on pavilion
  // ------------------------------ This function MUST be outside the map init

  const manageCLickOnPavilion = useCallback((e) => {
    if (e.features.length > 0 && map.current.getZoom() <= 18.6) {
      const clickedIdPavilion = e.features[0].properties.idPadiglione;
      const pavilion = totemServicesPavilion[activeFloor].features.find(
        (feature) => feature.properties.idPadiglione === clickedIdPavilion,
      );
      const bounds = bbox(pavilion);
      map.current.fitBounds(bounds, {
        padding: 120,
        zoom: 18.6,
        bearing: e.features[0].properties.numero % 2 === 1 ? 206.5 : 26.5,
      });
    }
  }, [activeFloor, totemServicesPavilion]);

  // ------------------------------ Register mapbox click function

  useEffect(() => {
    if (map.current && mapReady) {
      map.current.off('click', `pavilionsLayersFill${activeFloor}`, manageCLickOnPavilion);
      map.current.on('click', `pavilionsLayersFill${activeFloor}`, manageCLickOnPavilion);
    }
  }, [activeFloor, manageCLickOnPavilion, mapReady]);

  // ------------------------------ RESIZE MAP ON ACCESSIBILITY CHANGE

  useEffect(() => {
    if (!map.current) return;
    map.current.resize();
  }, [accessibilityActive]);

  // ------------------------------ DRAW PAVILION
  useEffect(() => {
    if (
      mapReady
      && totemServicesPavilion.length > 0
      && map.current
      && map.current.getLayer(`pavilionsLayersFill${activeFloor}`)
    ) {
      drawPavilionNamesMarkers(i18n.language);
    }
    if (
      !mapReady
      || !totemServicesPavilion
    ) {
      return;
    }
    if (map.current.getLayer(`pavilionsLayersFill${Number(!activeFloor)}`)) {
      map.current.removeLayer(`pavilionsLayersFill${Number(!activeFloor)}`);
      map.current.removeSource(`pavilions${Number(!activeFloor)}`);
    }
    if (map.current.getLayer(`pavilionsLayersFill${activeFloor}`)) return;
    map.current.addSource(`pavilions${activeFloor}`, {
      type: 'geojson',
      data: totemServicesPavilion[activeFloor],
    });
    map.current.addLayer({
      id: `pavilionsLayersFill${activeFloor}`,
      type: 'fill',
      source: `pavilions${activeFloor}`, // reference the data source
      layout: {},
      paint: {
        'fill-color': ['get', 'fill'],
        'fill-opacity': ['step', ['zoom'], 0.7, 18.5, 0.5],
      },
    });
    drawPavilionNamesMarkers(i18n.language);
  }, [activeFloor, drawPavilionNamesMarkers, i18n.language, mapReady, t, totemServicesPavilion]);

  // ------------------------------ DRAW PAVILION CONNECTION
  // To use when customer ask to draw connection between pavilion
  // the GeoJson data are handwritten in maps-polygon
  // useEffect(() => {
  //   if (
  //     !mapReady
  //     || !totemServicesPavilion
  //   ) return;
  //   if (map.current.getLayer('pavilionsConnectionLayerFill')) {
  //     map.current.removeLayer('pavilionsConnectionLayerFill');
  //     map.current.removeSource('pavilionsConnection');
  //   }
  //   if (map.current.getLayer('pavilionsConnectionLayerFill')) return;
  //   if (activeFloor === 0) return;
  //   map.current.addSource('pavilionsConnection', {
  //     type: 'geojson',
  //     data: pavilionsConnection,
  //   });
  //   map.current.addLayer({
  //     id: 'pavilionsConnectionLayerFill',
  //     type: 'fill',
  //     source: 'pavilionsConnection', // reference the data source
  //     layout: {},
  //     paint: {
  //       'fill-color': ['get', 'fill'],
  //       'fill-opacity': ['step', ['zoom'], 0.7, 18.5, 0.5],
  //     },
  //   });
  // }, [activeFloor, mapReady, totemServicesPavilion]);

  // ------------------------------ DRAW PAVILION TICKET OFFICE
  // To use when customer ask to draw ticket office
  // the GeoJson data are handwritten in maps-polygon
  // useEffect(() => {
  //   if (
  //     !mapReady
  //     || !totemServicesPavilion
  //   ) {
  //     return;
  //   }
  //   if (map.current.getLayer('pavilionsTicketOfficesLayerFill')) {
  //     map.current.removeLayer('pavilionsTicketOfficesLayerFill');
  //     map.current.removeSource('pavilionsTicketOffices');
  //   }
  //   if (map.current.getLayer('pavilionsTicketOfficesLayerFill')) return;
  //   if (activeFloor === 0) return;
  //   map.current.addSource('pavilionsTicketOffices', {
  //     type: 'geojson',
  //     data: pavilionsTicketOffices,
  //   });
  //   map.current.addLayer({
  //     id: 'pavilionsTicketOfficesLayerFill',
  //     type: 'fill',
  //     source: 'pavilionsTicketOffices', // reference the data source
  //     layout: {},
  //     paint: {
  //       'fill-color': ['get', 'fill'],
  //       'fill-opacity': ['step', ['zoom'], 0.7, 18.5, 0.5],
  //     },
  //   });
  // }, [activeFloor, mapReady, totemServicesPavilion]);

  // ------------------------------ DRAW STANDS
  useEffect(() => {
    if (
      !mapReady
      || !totemServicesStands
    ) {
      return;
    }
    if (
      map.current.getLayer(`standsLayersFill${Number(!activeFloor)}`)
      && map.current.getLayer(`standsLayersText${Number(!activeFloor)}`)
    ) {
      map.current.removeLayer(`standsLayersText${Number(!activeFloor)}`);
      map.current.removeLayer(`standsLayersFill${Number(!activeFloor)}`);
      map.current.removeSource(`stands${Number(!activeFloor)}`);
    }
    if (
      map.current.getLayer(`standsLayersFill${activeFloor}`)
      || map.current.getLayer(`standsLayersText${activeFloor}`)
    ) {
      return;
    }

    const getStandTextLayerConfig = () => {
      if (totemConfig.appTotemConfig.totemStandLabel === StandLabelStyle.ASSEGNATARIO) {
        return {
          minzoom: 21,
          layout: {
            'text-field': ['get', 'assegnatario'],
            'text-variable-anchor': ['center'],
            'text-justify': 'center',
            'text-size': Number.parseInt(theme.fontSize.h4, 10) + 5,
          },
        };
      }
      return {
        minzoom: 20,
        layout: {
          'text-field': ['get', 'coordinate'],
          'text-variable-anchor': ['center'],
          'text-justify': 'center',
          'text-size': Number.parseInt(theme.fontSize.h4, 10) + 5,
        },
      };
    };

    map.current.addSource(`stands${activeFloor}`, {
      type: 'geojson',
      data: totemServicesStands[activeFloor],
    });
    map.current.addLayer({
      id: `standsLayersFill${activeFloor}`,
      type: 'fill',
      source: `stands${activeFloor}`, // reference the data source
      minzoom: 18.5,
      layout: {},
      paint: {
        'fill-color': '#FFFFFF',
        'fill-opacity': 0.5,
      },
    });
    map.current.addLayer({
      id: `standsLayersText${activeFloor}`,
      type: 'symbol',
      source: `stands${activeFloor}`, // reference the data source
      ...getStandTextLayerConfig(),
    });
  }, [activeFloor, mapReady, totemConfig.appTotemConfig.totemStandLabel, totemServicesStands]);

  // ------------------------------ DRAW SERVICES MARKERS
  useEffect(() => {
    if (!isServicesPage || !mapReady || totemServices.length === 0) return;
    if (map.current.getLayer('route') && !destPoint && !pathToDest) {
      map.current.removeLayer('route');
      map.current.removeSource('route');
      if (floorSwitchMarker.current) {
        floorSwitchMarker.current.remove();
        floorSwitchMarker.current = null;
      }
    }
    if (markersList.current.length > 0) {
      markersList.current.forEach((item) => item.remove());
    }
    if (totemSelectedService > -1) {
      let selectedServiceIconName = '';
      if (poiList.length > 0) {
        const selectedServiceItem = poiList.find((item) => item.id === totemSelectedService);
        selectedServiceIconName = selectedServiceItem.iconName ?? '';
      }

      totemServices[activeFloor].features.forEach((feature) => {
        const {
          geometry,
          id,
        } = feature;

        const popup = builtTooltipElement(
          feature,
          themeContext,
          calcPathHandler,
          activeFloor,
          selectedServiceIconName,
          null,
          null,
          showWayFinding,
        );

        const marker = new mapboxgl.Marker({
          color: themeContext.primaryColor,
          scale: 2,
        })
          .setLngLat(geometry.coordinates)
          .setPopup(popup)
          .addTo(map.current);

        // const element = marker.getElement();
        // element.addEventListener('click', () => {
        //   console.log(id, properties);
        // });
        markersList.current.push(marker);
      });

      if (totemServices[activeFloor].features.length > 0) {
        const bounds = bbox(totemServices[activeFloor]);
        if (!pathToDest) {
          map.current.fitBounds(bounds, {
            padding: 120,
            maxZoom: 18.725,
            // bearing: 180 / -63.45,
          });
        }
      }
    } else {
      map.current.fitBounds(defaultMapBounds, {
        zoom: 16.1,
        // bearing: 180 / -63.45,
      });
    }
  }, [
    activeFloor,
    calcPathHandler,
    drawTotemPositionMarker,
    isServicesPage,
    mapReady,
    markersList,
    showWayFinding,
    t,
    themeContext,
    themeContext.primaryColor,
    totemConfig.appTotemConfig.totemPavillion,
    totemServices,
    pathToDest,
    destPoint,
    totemSelectedService,
    defaultMapBounds,
    poiList,
  ]);

  // ------------------------------ DRAW EXHIBITOR MARKER
  useEffect(() => {
    if (
      !exhibitorDetail
      || isServicesPage
      || !mapReady
      || totemServicesStands.length === 0
    ) {
      return;
    }
    if (map.current.getLayer('route') && !drawExhibitorPath) {
      map.current.removeLayer('route');
      map.current.removeSource('route');
      if (floorSwitchMarker.current) {
        floorSwitchMarker.current.remove();
        floorSwitchMarker.current = null;
      }
    }
    if (markersList.current.length > 0) {
      markersList.current.forEach((item) => item.remove());
    }

    let pavilionReference;

    totemServicesStands[activeFloor].features.forEach((feature) => {
      const {
        geometry,
        id,
        properties,
      } = feature;

      if (properties.eboothIds.includes(exhibitorDetail)) {
        // const standPolygon = polygon(geometry.coordinates);
        // const polygonCenter = center(standPolygon);
        const polygonCenter = center(geometry);
        // setDestPoint(polygonCenter);
        exhibitorPosition.current = {
          ...polygonCenter,
          properties: {
            ...properties,
            pavilionReference: properties.pad,
          },
        };

        pavilionReference = properties.pad;

        const marker = new mapboxgl.Marker({
          color: themeContext.primaryColor,
          scale: 2,
        })
          .setLngLat(polygonCenter.geometry.coordinates)
          .addTo(map.current);

        // const element = marker.getElement();
        // element.addEventListener('click', () => {
        //   console.log(id, properties);
        // });
        markersList.current.push(marker);
      }
    });

    if (markersList.current.length > 0) {
      const lineCoordinates = [];
      markersList.current.forEach((marker) => lineCoordinates.push(marker.getLngLat()
        .toArray()));
      if (lineCoordinates.length > 1) {
        const line = lineString(lineCoordinates);
        const bounds = bbox(line);
        map.current.fitBounds(bounds, {
          padding: 120,
          maxZoom: 18.725,
          bearing: pavilionReference && pavilionReference % 2 === 1 ? 206.5 : 26.5,
        });
      } else {
        const myPoint = point(lineCoordinates[0]);
        const bounds = bbox(myPoint);
        map.current.fitBounds(bounds, {
          padding: 120,
          maxZoom: 18.725,
          bearing: pavilionReference && pavilionReference % 2 === 1 ? 206.5 : 26.5,
        });
      }
    }
  }, [
    activeFloor,
    drawExhibitorPath,
    exhibitorDetail,
    isServicesPage,
    mapReady,
    themeContext.primaryColor,
    totemServices,
    totemServicesStands,
  ]);

  // ------------------------------ DRAW PATH TO DESTINATION p.1
  useEffect(() => {
    if (
      !mapReady
      || !destPoint
      || !pathToDest
      || Object.keys(totemConfig.appTotemConfig).length === 0
    ) {
      return;
    }
    const drawSwitchFloorMarker = () => {
      if (pathToDest.features.length > 1) {
        const totemFloor = Number(totemConfig.appTotemConfig.totemPavillion.floor);
        if (floorSwitchMarker.current) {
          floorSwitchMarker.current.remove();
          floorSwitchMarker.current = null;
        }
        let markerCoordinates = null;

        // - from here - Decide where to place the SwitchFloorMarker
        const getMarkerCoordinates = (floorTotem) => {
          const featureIndex = pathToDest
            .features
            .findIndex((feature) => feature.properties.floor === floorTotem);
          if (totemFloor === activeFloor) {
            const { coordinates } = pathToDest.features[featureIndex].geometry;
            return coordinates[coordinates.length - 1];
          }
          const { coordinates } = pathToDest.features[Number(!featureIndex)].geometry;
          return coordinates[0];
        };

        if (totemFloor === 0) {
          markerCoordinates = getMarkerCoordinates(0);
        }
        if (totemFloor === 1) {
          markerCoordinates = getMarkerCoordinates(1);
        }

        // - to here -

        const el = document.createElement('div');
        ReactDOM.render(
          <TotemSwitchFloorMarker
            label={`P${Number(!activeFloor)}`}
            position={Number(!activeFloor)}
            clickHandler={setActiveFloor}
          />,
          el,
        );
        const switchMarker = new mapboxgl.Marker({
          element: el,
        })
          .setLngLat(markerCoordinates)
          .addTo(map.current);

        floorSwitchMarker.current = switchMarker;
      }
    };
    if (map.current.getLayer('route') && map.current.getLayer('route')) {
      map.current.removeLayer('route');
      map.current.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': themeContext.primaryColor,
          'line-width': 32,
          'line-opacity': [
            'case',
            ['==', ['get', 'floor'], activeFloor],
            1,
            0,
          ],
        },
      });
      drawSwitchFloorMarker();
    }
    if (map.current.getLayer('route')) return;
    if (markersList.current.length > 0) {
      markersList.current.forEach((item) => item.remove());
    }
    const totemFloor = Number(totemConfig.appTotemConfig.totemPavillion.floor);
    if (activeFloor !== totemFloor) {
      setActiveFloor(totemFloor);
    }
    const marker = new mapboxgl.Marker({
      color: themeContext.primaryColor,
      scale: 3,
    })
      .setLngLat(destPoint.geometry.coordinates)
      .addTo(map.current);

    markersList.current.push(marker);

    map.current.addSource('route', {
      type: 'geojson',
      data: pathToDest,
    });
    map.current.addLayer({
      id: 'route',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': themeContext.primaryColor,
        'line-width': 32,
        'line-opacity': [
          'case',
          ['==', ['get', 'floor'], activeFloor],
          1,
          0,
        ],
      },
    });

    drawSwitchFloorMarker();
  }, [
    activeFloor,
    destPoint,
    mapReady,
    pathToDest,
    themeContext.primaryColor,
    totemConfig.appTotemConfig,
  ]);

  useEffect(() => {
    if (pathToDest && destPoint) {
      const totemFloor = Number(totemConfig.appTotemConfig.totemPavillion.floor);
      const { pavilionReference } = destPoint.properties;
      let featureToBbox;
      if (pathToDest?.features.length > 1) {
        const floorToUse = centeredCameraFollowTotemPosition.current ? totemFloor : activeFloor;
        featureToBbox = pathToDest
          .features
          .find((feature) => feature.properties.floor === floorToUse);
      } else {
        featureToBbox = pathToDest?.features[0];
      }

      const bounds = bbox(featureToBbox);
      map.current.fitBounds(bounds, {
        padding: 120,
        maxZoom: 18.725,
        bearing: pavilionReference && pavilionReference % 2 === 1 ? 206.5 : 26.5,
      });
      if (centeredCameraFollowTotemPosition.current) {
        centeredCameraFollowTotemPosition.current = false;
      }
    }
  }, [activeFloor, destPoint, pathToDest, totemConfig.appTotemConfig]);

  // ------------------------------ DRAW PATH TO DESTINATION p.2
  useEffect(() => {
    setDestPoint(null);
    setPathToDest(null);
    centeredCameraFollowTotemPosition.current = true;
  }, [totemServices]);

  // ------------------------------ DRAW PATH TO DESTINATION p.3
  useEffect(() => {
    if (
      !mapReady
      || map.current.getLayer('route')
    ) {
      return;
    }
    if (
      drawExhibitorPath
      && markersList.current.length > 0
      && destPoint !== exhibitorPosition.current
    ) {
      calcPathHandler(exhibitorPosition.current ?? destPoint);
    }
    // eslint-disable-next-line
  }, [drawExhibitorPath, destPoint, mapReady]);

  // ------------------------------ DRAW TOTEM PIN
  useEffect(() => {
    if (
      !mapReady
      || !map.current
      || !map.current.getLayer(`pavilionsLayersFill${activeFloor}`)
      || !map.current.getLayer(`standsLayersFill${activeFloor}`)
    ) {
      return;
    }
    drawTotemPositionMarker();
  }, [activeFloor, drawTotemPositionMarker, mapReady]);

  // ------------------------------ DRAW GATES
  useEffect(() => {
    if (
      !mapReady
      || !map.current
      || !map.current.getLayer(`pavilionsLayersFill${activeFloor}`)
      || !map.current.getLayer(`standsLayersFill${activeFloor}`)
    ) {
      return;
    }
    drawGatesNamesMarkers();
  }, [activeFloor, drawGatesNamesMarkers, mapReady]);

  const zoomInHandler = useCallback(() => {
    console.log('eseguo zoomIn');
    const currZoom = map.current.getZoom();
    map.current.zoomTo(currZoom + 0.5);
    dispatch(logAnalytic({
      eventName: analyticsEvents.CLICK_MAP_ZOOM_CHANGE_BUTTON,
      eventParams: {
        [eventsDescriptions.CLICK_MAP_ZOOM_CHANGE_BUTTON.zoomValueButton]: 'zoomIn',
      },
    }));
  }, [dispatch]);

  const zoomOutHandler = useCallback(() => {
    console.log('eseguo zoomOut');
    const currZoom = map.current.getZoom();
    map.current.zoomTo(currZoom - 0.5);
    dispatch(logAnalytic({
      eventName: analyticsEvents.CLICK_MAP_ZOOM_CHANGE_BUTTON,
      eventParams: {
        [eventsDescriptions.CLICK_MAP_ZOOM_CHANGE_BUTTON.zoomValueButton]: 'zoomOut',
      },
    }));
  }, [dispatch]);

  const backButtonClick = useCallback(() => {
    setDestPoint(null);
    setPathToDest(null);
  }, []);

  const manageFloorChange = useCallback((newFloor) => {
    setActiveFloor(newFloor);
    dispatch(logAnalytic({
      eventName: analyticsEvents.CLICK_MAP_FLOOR_CHANGE_BUTTON,
      eventParams: {
        [eventsDescriptions.CLICK_MAP_FLOOR_CHANGE_BUTTON.floorValue]: `${newFloor}`,
      },
    }));
  }, [dispatch]);

  return (
    <TotemMapContainer>
      <MapControllerWrapper $accessible={accessibilityActive}>
        <MapController zoomIn={zoomInHandler} zoomOut={zoomOutHandler} />
        <MapFloorController
          floorNumber={buildingsFloor}
          floorChange={manageFloorChange}
          activeFloor={activeFloor}
        />
      </MapControllerWrapper>
      {/* <MapLegendWrapper> */}
      {/*  <MapLegend /> */}
      {/* </MapLegendWrapper> */}
      {!exhibitorDetail && (
        <NavigationStickerButtons>
          {pathToDest && (
            <TotemBackButton type="maps" clickHandler={backButtonClick} />
          )}
          <TotemBackButton
            type="restore"
            clickHandler={restoreButtonCLickHandler}
          />
        </NavigationStickerButtons>
      )}
      <MapBoxElement $accessible={accessibilityActive} ref={mapContainer} />
      <TotemMapLoaderOverlay $active={isMapLoading} />
      <TotemMapLoader $active={isMapLoading}>
        <Spinner size="64" />
      </TotemMapLoader>
    </TotemMapContainer>
  );
};

TotemMaps.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
  exhibitorDetail: PropTypes.string,
  isServicesPage: PropTypes.bool.isRequired,
  restoreButtonCLickHandler: PropTypes.func,
};

TotemMaps.defaultProps = {
  exhibitorDetail: null,
  restoreButtonCLickHandler: () => {
    console.log('Restore button maps clicked');
  },
};

export default TotemMaps;
