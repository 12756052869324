import React, {
  useCallback, useState, useMemo, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, map } from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import { H4 } from '../components/headings';
import TotemLeftPanel from '../components/totem-left-panel';
import TotemRightPanel from '../components/totem-right-panel';
import FilterHeader from '../components/filter-header';
import TotemFilterCategoryButton from '../components/totem-filter-category-button';
import theme from '../theme';
import TotemCheckbox from '../components/totem-checkbox';
import TotemFormControlLabel from '../components/totem-form-control-label';
import ExhibitorCardButton from '../components/exhibitor-card-button';
import { changeIndexState, indexStateSelector } from '../store/scroll-restoration-slice';
import {
  configExhibitorsClusterListStateSelector,
  configExhibitorsCountryListStateSelector,
  configExhibitorsFiltersStateSelector,
  configExhibitorsPavilionListStateSelector,
  configExhibitorsStringFilterValueStateSelector,
  configFilteredExhibitorsStateSelector,
  configurationBrandsAsExhibitor,
  configurationHideBusinessCommunityExhibitorsFilter, configurationHideCountryExhibitorsFilter,
  configurationHidePavilionExhibitorsFilter,
  logAnalytic,
  resetExhibitorsFilter,
  setCategoryFilter,
  setCountryFilter,
  setPadsFilter,
  setStringFilter,
} from '../store/totem-config-slice';
import NoResultCard from '../components/no-result-card';
import TotemKeyboard from '../components/totem-keyboard';
import { analyticsEvents, eventsDescriptions } from '../common/analytics-constants';

const CategoryExhibitorContainer = styled.div`
  display: flex;
`;

const ExhibitorFiltersContainer = styled.div`
  padding: 36px;
`;

const ExhibitorFilterWrapper = styled.div`
  margin-top: 32px;
`;

const ExhibitorCategoryFilterContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow-y: scroll;
`;

const PadButtonsWrapper = styled(CategoryExhibitorContainer)`
  margin-top: 50px;
  flex-wrap: wrap;
  gap: 20px 20px;
`;

const PadButton = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
  padding: 20px;
  border: 2px solid ${theme.primaryBarColor};
  opacity: ${(props) => (props.$opacityActive ? '1' : '0.4')};
`;

const CountryListWrapper = styled(CategoryExhibitorContainer)`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding: 20px;
  max-height: 400px;
  overflow-y: scroll;
  background-color: white;
  border: 2px solid ${theme.secondaryLineColor};
`;

const Exhibitors = ({ accessibilityActive }) => {
  const { t, i18n } = useTranslation('exhibitor-pages');
  const [c] = useTranslation('country-codes');

  const history = useHistory();
  const dispatch = useDispatch();
  // const { exhibitorCategory } = useParams();
  const reduxExhibitorQuery = useSelector(configExhibitorsStringFilterValueStateSelector);
  const [exhibitorQuery, setExhibitorQuery] = useState(reduxExhibitorQuery);

  const clustersList = useSelector(configExhibitorsClusterListStateSelector);
  const pavilionList = useSelector(configExhibitorsPavilionListStateSelector);
  const countryList = useSelector(configExhibitorsCountryListStateSelector);
  const filteredExhibitors = useSelector(configFilteredExhibitorsStateSelector);
  const filters = useSelector(configExhibitorsFiltersStateSelector);
  const brandsAsExhibitor = useSelector(configurationBrandsAsExhibitor);

  const hideBCFilter = useSelector(configurationHideBusinessCommunityExhibitorsFilter);
  const hidePavilionFilter = useSelector(configurationHidePavilionExhibitorsFilter);
  const hideCountryFilter = useSelector(configurationHideCountryExhibitorsFilter);

  const scrollRestorationIndex = useSelector(indexStateSelector);

  const rightPanelRef = useRef(null);
  const [keyboardOpen, setKeyboardOpen] = useState(false);

  const logHomeSearchValue = useCallback(
    debounce(
      (value) => {
        dispatch(logAnalytic({
          eventName: analyticsEvents.SEARCH_EXHIBITOR,
          eventParams: {
            [eventsDescriptions.SEARCH_EXHIBITOR.searchValue]: value,
          },
        }));
      },
      1500,
    ),
    [],
  );

  const exhibitorClickHandler = useCallback((index, { id, exhibitorId }) => {
    const correctId = brandsAsExhibitor ? exhibitorId : id;
    history.push(`${history.location.pathname}/exhibitor/${encodeURIComponent(correctId)}`);
    dispatch(changeIndexState(index));
  }, [brandsAsExhibitor, dispatch, history]);

  const searchInputChange = useCallback((ev) => {
    setExhibitorQuery(ev.target.value);
    dispatch(setStringFilter(ev.target.value));
    logHomeSearchValue(ev.target.value);
  }, [dispatch, logHomeSearchValue]);

  const performStringButtonHandler = useCallback(() => {
    dispatch(setStringFilter(exhibitorQuery));
    logHomeSearchValue(exhibitorQuery);
  }, [dispatch, exhibitorQuery, logHomeSearchValue]);

  const handleResetFilter = useCallback(() => {
    setExhibitorQuery('');
    dispatch(resetExhibitorsFilter());
    dispatch(logAnalytic({ eventName: analyticsEvents.FILTER_EXHIBITOR_CLEAR }));
  }, [dispatch]);

  const handleCategoryFilterChange = useCallback(
    (categoryId, categoryName) => {
      dispatch(setCategoryFilter(categoryId));
      dispatch(logAnalytic({
        eventName: analyticsEvents.FILTER_EXHIBITOR,
        eventParams: {
          [eventsDescriptions.FILTER_EXHIBITOR.filterValue]: categoryName,
        },
      }));
    },
    [dispatch],
  );

  const renderClusterFilters = useMemo(() => map(clustersList, (item) => (
    <ExhibitorFilterWrapper key={`totem_${item.oldId}`}>
      <H4>{item.name[i18n.language]}</H4>
      <ExhibitorCategoryFilterContainer>
        {item.data.map((subItem) => (
          <TotemFilterCategoryButton
            key={`cls_${subItem.id}`}
            categoryImg={subItem.logoUrl}
            title={subItem.name[i18n.language]}
            itemIndex={subItem.id}
            itemName={subItem.name.it ?? subItem.name}
            isActive={
              filters.categoriesFilter.length === 0
              || filters.categoriesFilter.includes(subItem.id)
            }
            onClickCategoryHandler={handleCategoryFilterChange}
          />
        ))}
      </ExhibitorCategoryFilterContainer>
    </ExhibitorFilterWrapper>
  )), [
    clustersList,
    filters.categoriesFilter,
    handleCategoryFilterChange,
    i18n.language,
  ]);

  const countryButtonHandler = useCallback((event) => {
    dispatch(setCountryFilter(event.target.name));
    dispatch(logAnalytic({
      eventName: analyticsEvents.FILTER_EXHIBITOR,
      eventParams: {
        [eventsDescriptions.FILTER_EXHIBITOR.filterValue]: event.target.name,
      },
    }));
  }, [dispatch]);

  const renderCountryList = useMemo(() => map(countryList, (item) => (
    <TotemFormControlLabel
      key={`country_${item}`}
      name={item}
      checked={filters.countryExhibitorsFilter.includes(item)}
      control={<TotemCheckbox />}
      onChange={countryButtonHandler}
      label={c(item)}
    />
  )), [countryList, filters.countryExhibitorsFilter, countryButtonHandler, c]);

  const padsButtonHandler = useCallback((event) => {
    const res = Number.parseInt(event.currentTarget.dataset.padIndex, 10);
    dispatch(setPadsFilter(pavilionList[res]));
    dispatch(logAnalytic({
      eventName: analyticsEvents.FILTER_EXHIBITOR,
      eventParams: {
        [eventsDescriptions.FILTER_EXHIBITOR.filterValue]: pavilionList[res],
      },
    }));
  }, [dispatch, pavilionList]);

  const renderPavilionList = useMemo(() => map(pavilionList, (item, index) => (
    <PadButton
      key={`pad_${item}`}
      data-pad-index={index}
      onClick={padsButtonHandler}
      $opacityActive={
        filters.padsExhibitorsFilter.length === 0
        || filters.padsExhibitorsFilter.includes(item)
      }
    >
      {item}
    </PadButton>
  )), [pavilionList, padsButtonHandler, filters.padsExhibitorsFilter]);

  const virtuoso = React.useRef(null);

  useEffect(() => {
    if (scrollRestorationIndex.index) {
      setTimeout(() => {
        virtuoso.current.scrollToIndex({
          index: scrollRestorationIndex.index,
          // align:'start',
          behavior: 'smooth',
        });
      }, 100);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const virtuosoItemContent = useCallback((index, data) => (
    <ExhibitorCardButton
      key={data.id}
      padText={data.pads ? t('pavillion', { pads: data.pads[0].id ?? 'NaN' }) : null}
      standText={data.pads ? t('stand', { stands: data.pads[0].stand.join(' ') }) : null}
      exhibitorType={
        data.type
        && data.type === 'representedHouse'
          ? t('representedHouse') : null
      }
      name={data.companyName}
      logo={data.logoUrl}
      itemIndex={index}
      cardClickHandler={exhibitorClickHandler}
      exhibitorItem={data}
    />
  ), [exhibitorClickHandler, t]);

  const closeKeyBoardHandler = useCallback(() => {
    setKeyboardOpen(false);
  }, []);

  const keyBoardInputChange = useCallback((value) => {
    let toSave = exhibitorQuery + value;
    if (value === '⇦') {
      toSave = exhibitorQuery.substring(0, exhibitorQuery.length - 1);
    }
    if (value === 'cancel') {
      toSave = '';
    }
    if (value === 'enter') {
      toSave = toSave.substring(0, toSave.length - 5);
    }
    setExhibitorQuery(toSave);
    dispatch(setStringFilter(toSave));
    logHomeSearchValue(toSave);
  }, [dispatch, exhibitorQuery, logHomeSearchValue]);

  const openKeyBoardEvent = useCallback(() => {
    setKeyboardOpen(true);
  }, []);

  return (
    <CategoryExhibitorContainer>
      <TotemLeftPanel accessibilityActive={accessibilityActive}>
        {
          filteredExhibitors.length > 0 ? (
            <Virtuoso
              style={{
                padding: '0 50px',
                overflowX: 'hidden',
              }}
              ref={virtuoso}
              data={filteredExhibitors}
              overscan={5}
              totalCount={filteredExhibitors.length}
              itemContent={virtuosoItemContent}
            />
          ) : (
            <NoResultCard>
              {t('no_data_found')}
            </NoResultCard>
          )
        }
      </TotemLeftPanel>

      <TotemRightPanel
        accessibilityActive={accessibilityActive}
        showSearchBar
        searchTitle={t('searchLabel')}
        onSearchInputChangeHandler={searchInputChange}
        onPerformSearchClickHandler={performStringButtonHandler}
        searchValue={exhibitorQuery}
        onInputFocusHandler={openKeyBoardEvent}
      >
        <ExhibitorFiltersContainer>
          <FilterHeader removeFilterClickHandler={handleResetFilter} />
          {!hideBCFilter && renderClusterFilters}
          {!hidePavilionFilter && (
            <ExhibitorFilterWrapper>
              <H4>{t('pavilion_title')}</H4>
              <PadButtonsWrapper>
                {renderPavilionList}
              </PadButtonsWrapper>
            </ExhibitorFilterWrapper>
          )}
          {!hideCountryFilter && (
            <ExhibitorFilterWrapper>
              <H4>{t('country')}</H4>
              <CountryListWrapper>
                {renderCountryList}
              </CountryListWrapper>
            </ExhibitorFilterWrapper>
          )}
        </ExhibitorFiltersContainer>
        <TotemKeyboard
          isKeyboardOpen={keyboardOpen}
          onKeyboardCloseHandler={closeKeyBoardHandler}
          onKeyboardButtonPressed={keyBoardInputChange}
          itemToIgnoreRef={rightPanelRef.current}
        />
      </TotemRightPanel>
    </CategoryExhibitorContainer>
  );
};

Exhibitors.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
};

Exhibitors.defaultProps = {};

export default Exhibitors;
