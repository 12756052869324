import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../theme';

const Wrapper = styled.div`
  box-sizing: border-box;
  text-align: center;
  padding: 10px 20px;
  background-color: white;
  border: 2px solid ${theme.secondaryLineColor};
  
  max-width: 564px;
  width: ${(props) => props.$extended && '564px'};
`;
const BrandCard = ({ title, logo, extended }) => (
  <Wrapper $extended={extended}>
    {title}
  </Wrapper>
);

BrandCard.propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.string,
  extended: PropTypes.bool,
};

BrandCard.defaultProps = {
  logo: null,
  extended: false,
};

export default BrandCard;
