import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { configStateSelector, logAnalytic } from '../store/totem-config-slice';
import InfoCardButton from '../components/info-card-button';
import { analyticsEvents } from '../common/analytics-constants';

const InformationPageContainer = styled.div`
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
`;

const Information = () => {
  const totemConfig = useSelector(configStateSelector);
  const { t, i18n } = useTranslation('info-detail-page');
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logAnalytic({ eventName: analyticsEvents.PAGE_INFORMATION }));
  }, [dispatch]);

  const cardButtonHandler = useCallback((id) => {
    history.push(`${history.location.pathname}/detail/${id}`);
  }, [history]);

  const renderInformationCardButton = useMemo(
    () => map(totemConfig.editionTotemConfig.informations, (item, index) => (
      <InfoCardButton
        key={item.informationTitle[i18n.language]}
        title={item.informationTitle[i18n.language]}
        imageSrc={item.informationButtonImage}
        imageAlt={`${t('buttonImage')} ${index}`}
        cardClickHandler={cardButtonHandler}
        cardIndex={index}
        fullWidth={
            index === totemConfig.editionTotemConfig.informations.length - 1
            && index % 2 === 0
          }
      />
    )),
    [
      cardButtonHandler,
      i18n.language,
      t,
      totemConfig.editionTotemConfig.informations,
    ],
  );

  return (
    <InformationPageContainer>
      {
        totemConfig.editionTotemConfig?.informations?.length > 0
          && renderInformationCardButton
      }
    </InformationPageContainer>
  );
};

Information.propTypes = {};

Information.defaultProps = {};

export default Information;
