import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TotemSearchBar from './totem-search-bar';

const TotemRightPanelContainer = styled.div`
  display: flex;
  width: 600px;
  flex-direction: ${(props) => (props.$accessibilityOn ? 'column-reverse' : 'column')};
  background-color: ${(props) => props.theme.secondaryLightColor};

  height: ${(props) => (props.$accessibilityOn ? '1225px' : '2515px')};
`;

const ContentWrapper = styled.div`
  height: ${(props) => (props.$accessibilityOn ? '1008px' : '100%')};
  overflow: auto;
`;

// const TotemLeftPage = styled.div`
//   width: 1560px;
//   overflow: auto;
// `;

// const TotemRightPage = styled.div`
//   width: 600px;
//   overflow: auto;
//   background-color: ${(props) => props.theme.secondaryLightColor};
// `;

const TotemRightPanel = ({
  showSearchBar,
  searchTitle,
  searchValue,
  accessibilityActive,
  children,
  onPerformSearchClickHandler,
  onSearchInputChangeHandler,
  onInputFocusHandler,
}) => (
  <TotemRightPanelContainer
    $accessibilityOn={accessibilityActive}
  >
    {showSearchBar && (
    <TotemSearchBar
      searchTitle={searchTitle}
      onSearchInputChangeHandler={onSearchInputChangeHandler}
      onPerformSearchClickHandler={onPerformSearchClickHandler}
      searchValue={searchValue}
      onInputFocusHandler={onInputFocusHandler}
    />
    )}
    <ContentWrapper
      $accessibilityOn={showSearchBar && accessibilityActive}
    >
      {children}
    </ContentWrapper>
  </TotemRightPanelContainer>
);

TotemRightPanel.propTypes = {
  showSearchBar: PropTypes.bool.isRequired,
  accessibilityActive: PropTypes.bool.isRequired,
  searchTitle: PropTypes.string,
  onSearchInputChangeHandler: PropTypes.func,
  onPerformSearchClickHandler: PropTypes.func,
  onInputFocusHandler: PropTypes.func,
  searchValue: PropTypes.string,
};

TotemRightPanel.defaultProps = {
  searchTitle: '',
  onSearchInputChangeHandler: null,
  onPerformSearchClickHandler: null,
  onInputFocusHandler: null,
  searchValue: '',
};

export default TotemRightPanel;
