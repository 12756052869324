import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { H2 } from '../components/headings';
import TotemLeftPanel from '../components/totem-left-panel';
import TotemRightPanel from '../components/totem-right-panel';
import ServiceListButton from '../components/service-list-button';

const ServicesContainer = styled.div`
  display: flex;
`;

const ServicesListWrapper = styled.div`
  margin-top: 140px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const servicesItems = [
  {
    icon: 'info',
    description: 'Info Point',
  },
  {
    icon: 'reception',
    description: 'Reception',
  },
  {
    icon: 'poi_snack-bar',
    description: 'Snack Bar',
  },
  {
    icon: 'toilette',
    description: 'Toilet',
  },
  {
    icon: 'toilette-disabili',
    description: 'Accessible Toilet',
  },
  {
    icon: 'toilette-uomo',
    description: 'Man Toilet',
  },
  {
    icon: 'toilette-donna',
    description: 'Woman Toilet',
  },
  {
    icon: 'servizi-alla-clientela',
    description: 'Customer Services',
  },
  {
    icon: 'social-mobility',
    description: 'Mobility Point',
  },
  {
    icon: 'parcheggio-dipendenti',
    description: 'Employee Parking',
  },
  {
    icon: 'ferramenta',
    description: 'Ironmongery',
  },
  {
    icon: 'giornali-e-tabacchi',
    description: 'Newspapers and Tobacco',
  },
  {
    icon: 'parafarmacia',
    description: 'Parapharmacy',
  },
  {
    icon: 'parcheggio',
    description: 'Car Park',
  },
  {
    icon: 'parcheggio-multipiano',
    description: 'Multi-Level Car Park',
  },
  {
    icon: 'Metropolitana',
    description: 'Metro',
  },
  {
    icon: 'Treno',
    description: 'Train',
  },
  {
    icon: 'hotel4',
    description: 'Hotel',
  },
  {
    icon: 'deposito-bagagli',
    description: 'Luggage Storage',
  },
  {
    icon: 'porta',
    description: 'Door',
  },
  {
    icon: 'ascensore',
    description: 'Elevator',
  },
  {
    icon: 'Taxi',
    description: 'Taxi',
  },
  {
    icon: 'Pubblica-sicurezza',
    description: 'Public Security',
  },
  {
    icon: 'banca-bancomat',
    description: 'ATM',
  },
  {
    icon: 'oasi-del-silenzio',
    description: 'Silence Oasi',
  },
  {
    icon: 'corriere-espress',
    description: 'Express Delivery',
  },
  {
    icon: 'poi_dae',
    description: 'DAE',
  },
  {
    icon: 'Cargo',
    description: 'Cargo',
  },
  {
    icon: 'showroom',
    description: 'Showroom',
  },
  {
    icon: 'Exposition-area',
    description: 'Exposition Area',
  },
];

const Services = ({ accessibilityActive }) => {
  const { t } = useTranslation('service-page');
  const [selectedService, setSelectedService] = useState('');

  return (
    <ServicesContainer>
      <TotemLeftPanel accessibilityActive={accessibilityActive}>
        <H2 />
      </TotemLeftPanel>
      <TotemRightPanel
        accessibilityActive={accessibilityActive}
        showSearchBar={false}
      >
        <ServicesListWrapper>
          {servicesItems.map((item) => (
            <ServiceListButton
              key={item.icon}
              icon={item.icon}
              description={t(item.description)}
              isActive={item.icon === selectedService || selectedService === ''}
              buttonClickHandler={() => setSelectedService((prev) => (item.icon === prev ? '' : item.icon))}
            />
          ))}
        </ServicesListWrapper>
      </TotemRightPanel>
    </ServicesContainer>
  );
};

Services.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
};

Services.defaultProps = {};

export default Services;
