import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TotemLeftPanelContainer = styled.div`
  width: 1560px;

  height: ${(props) => (props.$accessibilityOn ? '1225px' : '2532px')};
  overflow: auto;
`;

const TotemLeftPanel = ({ children, accessibilityActive }) => (
  <TotemLeftPanelContainer $accessibilityOn={accessibilityActive}>
    {children}
  </TotemLeftPanelContainer>
);

TotemLeftPanel.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
};

TotemLeftPanel.defaultProps = {};

export default TotemLeftPanel;
