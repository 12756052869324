import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const spinner = styled(CircularProgress)`
  width: 128px;
  height: 128px;
  
  &.MuiCircularProgress-colorPrimary {
    color: ${(props) => props.theme.primaryColor};
  }
`;

export default spinner;
