/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { initializeApp } from 'firebase/app';
import styled, { ThemeProvider } from 'styled-components';
import { isEmpty } from 'lodash';
import mapboxgl from '!mapbox-gl';
import LandingPage from './landing-page';
import { Reset } from '../reset';
import theme from '../theme';
import firebaseConfig from '../firebaseConfig';
import { configurationColorStateSelector } from '../store/totem-config-slice';
import '@fontsource/mulish';
import '@fontsource/mulish/700.css';
import 'mapbox-gl/dist/mapbox-gl.css';

// eslint-disable-next-line @typescript-eslint/no-var-requires
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken = process.env.MAPBOX_TOKEN;

initializeApp(firebaseConfig);

const AppContainer = styled.div`
  user-select: none;
  width: 200%;
  font-size: ${(props) => props.theme.fontSize.bodyFont};
  font-family: ${(props) => props.theme.fontFamily};
  
  * > .mapboxgl-popup-content {
    border: 3px solid ${(props) => props.theme.primaryColor};
  }

  * > .mapboxgl-popup-close-button {
    font-size: ${(props) => props.theme.fontSize.bodyFont};
  }

  * > .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: ${(props) => props.theme.primaryColor};
  }
  * > .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: ${(props) => props.theme.primaryColor};
  }
  * > .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: ${(props) => props.theme.primaryColor};
  }
  * > .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: ${(props) => props.theme.primaryColor};
  }
  
  * > .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    border-top-color: ${(props) => props.theme.primaryColor};
  }
  * > .mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border-bottom-color: ${(props) => props.theme.primaryColor};
  }
  * > .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
    border-top-color: ${(props) => props.theme.primaryColor};
  }
  * > .mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
    border-bottom-color: ${(props) => props.theme.primaryColor};
  }
  * > .mapboxgl-control-container > .mapboxgl-ctrl-bottom-left > .mapboxgl-ctrl,
  * > .mapboxgl-control-container > .mapboxgl-ctrl-bottom-right > .mapboxgl-ctrl {
    pointer-events: none;
  }
  
`;
const App = () => {
  const newThemeColor = useSelector(configurationColorStateSelector);

  useEffect(() => {
    const preventZoom = (e) => {
      if (e.touches && e.touches.length > 1) {
        // console.log(e);
        e.preventDefault();
      }
    };
    document.addEventListener(
      'touchmove',
      preventZoom,
      { passive: false },
    );

    return () => {
      document.removeEventListener('touchmove', preventZoom);
    };
  }, []);

  const updatedTheme = useCallback(() => {
    if (newThemeColor && !isEmpty(newThemeColor)) {
      return {
        ...theme,
        primaryColor: newThemeColor.primaryColor,
        primaryBarColor: newThemeColor.primaryBarColor,
      };
    }

    return theme;
  }, [newThemeColor]);

  return (
    <ThemeProvider theme={updatedTheme}>
      <Reset />
      <AppContainer>
        <Switch>
          <Route path="/:totemId">
            <LandingPage />
          </Route>
          <Route path="*">
            <h1>Page not found 404</h1>
          </Route>
        </Switch>
      </AppContainer>
    </ThemeProvider>
  );
};

export default App;
