import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  configEventsStateSelector,
  configStateSelector,
} from '../store/totem-config-slice';
import TotemSlider from '../components/totem-slider';
import { H2 } from '../components/headings';
import theme from '../theme';

const EventDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeroImage = styled.div`
  width: 100%;
  height: 800px;
  background-position: 50%;
  background-size: cover;
  background-image: url(${(props) => props.$imageUrl});
`;

const EventDescriptionContainer = styled.div`
  display: flex;
  margin: 140px 250px 140px 300px;
`;

const EventDescriptionDetail = styled.div`
  display: flex;
  flex-direction: column;
  //width: 50%;
  width: 100%;
`;

const EventGalleryContainer = styled.div`
  display: flex;
  width: 50%;
  height: 1000px;
  margin-left: 50px;
  // background-color: ${theme.secondaryLightColor};
  justify-content: center;
  align-items: center;
`;

const TimeDetailData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.secondaryLightColor};
  margin-top: 70px;
  padding: 25px;
  font-size: ${theme.fontSize.overline};
`;

const SimpleParagraph = styled.p`
  margin-top: 30px;
  white-space: pre-line;
`;

const UpperOverlineText = styled.p`
  margin-top: 30px;
  text-transform: uppercase;
  font-size: ${theme.fontSize.overline};
`;

const BodyTextBold = styled.p`
  margin-top: 16px;
  font-weight: bold;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
`;

const TagItem = styled.span`
  display: inline-flex;
  font-size: ${theme.fontSize.overline};
  text-transform: uppercase;
  margin: 5px 20px 5px 0;
  padding: 8px 16px;
  color: white;
  background-color: ${theme.secondaryDarkColor};
  border-radius: 4px;
`;

const NoGalleryImage = styled.div`
  // color: ${theme.secondaryDarkColor};
  // background-color: ${theme.secondaryDarkColor};
  color: white;
  background-color: white;
`;

const getDate = (date) => {
  if (date) {
    const nd = new Date(date);
    return <span>{nd.toLocaleDateString('it')}</span>;
  }
  return <span> - </span>;
};

const ImageElment = styled.img`
  height: 100%;
  width: 100%;
`;

const ImageItem = ({ item }) => (
  <ImageElment src={item} alt={item} />
);

const EventDetail = ({ accessibilityActive }) => {
  const { t, i18n } = useTranslation('events-pages');
  const { eventId } = useParams();
  const totemEvents = useSelector(configEventsStateSelector);
  const totemConfig = useSelector(configStateSelector);
  const [eventItem, setEventItem] = useState(null);
  const [eventGallery, setEventGallery] = useState([]);

  useEffect(() => {
    const toSave = totemEvents.find((item) => item.id === eventId);
    setEventItem(toSave);
  }, [eventId, totemEvents, i18n.language]);

  useEffect(() => {
    if (eventItem) {
      const images = [];
      if (eventItem.galleryImage1) {
        images.push(eventItem.galleryImage1);
      }
      if (eventItem.galleryImage2) {
        images.push(eventItem.galleryImage2);
      }
      if (eventItem.galleryImage3) {
        images.push(eventItem.galleryImage3);
      }
      if (eventItem.galleryImage4) {
        images.push(eventItem.galleryImage4);
      }
      if (eventItem.galleryImage5) {
        images.push(eventItem.galleryImage5);
      }
      setEventGallery(images);
    }
  }, [eventItem]);

  const createTiming = useCallback((start, end) => {
    const timeFormatter = { timeStyle: 'short' };
    const b = new Date(start);
    if (!start && !end) {
      return <span> - </span>;
    }
    if (end) {
      const e = new Date(end);
      return (
        <span>
          {`${b.toLocaleTimeString(i18n.language, timeFormatter)} - ${e.toLocaleTimeString(i18n.language, timeFormatter)}`}
        </span>
      );
    }
    return <span>{b.toLocaleTimeString(i18n.language, timeFormatter)}</span>;
  }, [i18n.language]);

  const getPads = useCallback((pad) => {
    if (pad) {
      const res = Number.parseInt(pad, 10);
      return <span>{res ? t('pad') + res : pad}</span>;
    }
    return <span>NaN</span>;
  }, [t]);

  const getOwnerName = useCallback(() => {
    if (eventItem.type === 'Organizer') {
      return totemConfig?.appTotemConfig?.editionName[i18n.language];
    }
    return eventItem.exhibitor?.name ?? 'No Name';
  }, [eventItem, i18n.language, totemConfig.appTotemConfig.editionName]);

  return (
    <EventDetailContainer>
      {
        eventItem && (
          <>
            <HeroImage $imageUrl={eventItem.coverImage ?? ''} />
            <EventDescriptionContainer>
              <EventDescriptionDetail>
                <H2>{eventItem.title[i18n.language]}</H2>
                <SimpleParagraph>
                  {
                    eventItem.description[i18n.language]
                    ?? eventItem.descriptionShort[i18n.language]
                  }
                </SimpleParagraph>
                <TimeDetailData>
                  {getDate(eventItem.startDate ?? null)}
                  {createTiming(eventItem.startDate ?? null, eventItem.endDate ?? null)}
                  {getPads(eventItem.pad ?? eventItem.location)}
                </TimeDetailData>
                <UpperOverlineText>{t('eventOwner')}</UpperOverlineText>
                <BodyTextBold>{getOwnerName()}</BodyTextBold>
                <UpperOverlineText>{t('location')}</UpperOverlineText>
                <BodyTextBold>{eventItem.location}</BodyTextBold>
                {
                  eventItem.address && (
                    <>
                      <UpperOverlineText>{t('address')}</UpperOverlineText>
                      <BodyTextBold>{eventItem.address}</BodyTextBold>
                    </>
                  )
                }
                <TagContainer>
                  {
                    eventItem.categories && eventItem.categories.map((item) => (
                      <TagItem key={item.id}>{item.label[i18n.language]}</TagItem>
                    ))
                  }
                </TagContainer>
              </EventDescriptionDetail>
              {/* <EventGalleryContainer> */}
              {/*  {eventGallery.length === 0 && null} */}
              {/*  { */}
              {/*    eventGallery.length > 0 && ( */}
              {/*      <TotemSlider */}
              {/*        sliderContentHeight="1000px" */}
              {/*        slideComponent={ImageItem} */}
              {/*        slideItems={eventGallery} */}
              {/*        onSlideClick={() => null} */}
              {/*      /> */}
              {/*    ) */}
              {/*  } */}
              {/* </EventGalleryContainer> */}
            </EventDescriptionContainer>
          </>
        )
      }
    </EventDetailContainer>
  );
};

EventDetail.propTypes = {
  accessibilityActive: PropTypes.bool.isRequired,
};

EventDetail.defaultProps = {};

export default EventDetail;
