import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { configurationStateSelector } from '../store/totem-configuration-slice';
import useTotemCustomSelector from '../common/use-totem-selector';
import { H2 } from '../components/headings';

const InformationDetailContainer = styled.div``;

const InfoContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 140px 400px;
`;

const InfoContent = styled.span`
  margin-top: 70px;
`;

const InformationDetail = () => {
  const [loader, totemConfig] = useTotemCustomSelector(
    configurationStateSelector,
    'configurationLoading',
    'configuration',
  );
  const { infoId } = useParams();
  const { t, i18n } = useTranslation('info-detail-page');

  const InformationItem = ({ item }) => (
    <InformationDetailContainer>
      <img src={item.informationCoverImage} alt={t('coverALTdesc')} />
      <InfoContentContainer>
        <H2>{item.informationTitle[i18n.language]}</H2>
        <InfoContent dangerouslySetInnerHTML={{ __html: item.informationText[i18n.language] }} />
      </InfoContentContainer>
    </InformationDetailContainer>
  );

  return (
    <>
      {
        totemConfig.editionTotemConfig?.informations?.length > 0 && (
          <InformationItem item={totemConfig.editionTotemConfig.informations[infoId]} />
        )
      }
    </>
  );
};

export default InformationDetail;
