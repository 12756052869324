import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';
import { isObject } from 'lodash';
import theme from '../theme';
import TotemIcon from './icon';

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

const TooltipTitle = styled.span`
  display: flex;
  gap: 15px;
  align-items: center;
  color: ${(props) => props.theme.primaryColor};
  font-family: ${theme.fontFamily};
  font-size: ${theme.h4.fontSize};
  font-weight: ${theme.h4.fontWeight};
`;

const BoldText = styled.p`
  margin-top: 15px;
  font-family: ${theme.fontFamily};
  font-size: ${theme.bodyFont.fontSize};
  font-weight: ${theme.bodyBoldFont.fontWeight};
  line-height: 30px;
`;

const TooltipSection = styled.p`
  margin: 15px 0;
  text-transform: uppercase;
  font-size: ${theme.fontSize.overline};
`;

const TooltipCalcPathButton = styled.button`
  all: unset;
  
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  cursor: pointer;
  color: white;
  -webkit-text-fill-color: white;

  margin: 35px 0;
  padding: 18px 0;
  border-radius: 4px;
  font-family: ${theme.fontFamily};
  font-size: ${theme.bodyFont.fontSize};
`;

const TooltipDescriptionTextWrapper = styled.div`
  max-height: 180px;
  overflow-y: scroll;
`;

const TooltipContent = ({
  iconTitle,
  title,
  description,
  floor,
  name,
  color,
  calculatePathHandler,
  feature,
  showNavigationButton,
}) => {
  const [t, i18n] = useTranslation('service-page');

  const handlerButton = useCallback(() => calculatePathHandler(feature), [
    calculatePathHandler,
    feature,
  ]);

  const renderTitle = useMemo(() => {
    if (title[i18n.language]) return title[i18n.language];
    if (!isObject(title)) return t(title);
    return name;
  }, [i18n.language, name, t, title]);

  return (
    <TooltipContainer>
      <TooltipTitle
        style={{
          color,
        }}
      >
        {iconTitle.length > 0 && <TotemIcon icon={iconTitle} size="50px" />}
        {renderTitle}
      </TooltipTitle>

      <TooltipSection>{t('position')}</TooltipSection>
      <BoldText>{name}</BoldText>

      <TooltipSection>{t('floor')}</TooltipSection>
      <BoldText>{floor}</BoldText>

      {calculatePathHandler && showNavigationButton && (
        <TooltipCalcPathButton
          style={{ backgroundColor: color }}
          onClick={handlerButton}
        >
          {t('calculate_path')}
        </TooltipCalcPathButton>
      )}

      {
        description[i18n.language] && (
          <>
            <TooltipSection>{t('description') ?? ' - '}</TooltipSection>
            <TooltipDescriptionTextWrapper>
              <BoldText>{description[i18n.language]}</BoldText>
            </TooltipDescriptionTextWrapper>
          </>
        )
      }

    </TooltipContainer>
  );
};

TooltipContent.prototype = {
  iconTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  floor: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  calculatePathHandler: PropTypes.func,
  feature: PropTypes.object,
};

TooltipContent.defaultProps = {
  calculatePathHandler: null,
  feature: null,
  iconTitle: '',
};

export default TooltipContent;
