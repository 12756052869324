const getTotemWeather = async (dt) => {
  const currPath = `https://api.openweathermap.org/data/2.5/onecall?lat=45.464161&lon=9.19199&exclude=daily,minutely,current,alerts&units=metric&appid=${process.env.REACT_APP_OPEN_WEATHER_API}`;
  const backPath = `https://api.openweathermap.org/data/2.5/onecall/timemachine?lat=45.520474361247516&lon=9.07888385602075&appid=e0c8de40bfe94caebfe27883af804530&units=metric&dt=${dt}`;
  try {
    const res = await Promise.all([
      fetch(backPath),
      fetch(currPath),
    ]);
    return await Promise.all(res.map((r) => {
      switch (r.status) {
        case 200:
          return r.json();
        case 500:
          throw new Error(r.statusText);
        default:
          throw new Error('Meteo Server Error');
      }
    }));
  } catch (err) {
    console.log(err);
    throw new Error('Meteo Server Error');
  }
};

export default getTotemWeather;
